import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { NSTable } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmptyState from 'components/EmptyState/EmptyState';
import { ColSortConfig } from 'types/sort';
import { getColSortConfig, getSortIcon } from 'helpers/sort';
import { AttributeKey } from 'views/Reports/types';
import { AttributeKeysMap } from '../ConfigurationSidebar/constants';

interface IDealsTableProps {
    selectedColumns: AttributeKey[];
    colSortConfig: ColSortConfig<AttributeKey> | undefined;
    setColSortConfig: (config: ColSortConfig<AttributeKey> | undefined) => void;
    isEditMode: boolean;
    tableData: any[][];
}

const DealsTable = ({
    selectedColumns, tableData, colSortConfig, setColSortConfig, isEditMode,
}: IDealsTableProps) => {
    const configureSort = (key: AttributeKey) => {
        const newColSortConfig = getColSortConfig(key, colSortConfig);
        setColSortConfig(newColSortConfig);
    };

    return (
        <>
            {selectedColumns.length === 0 && (
                <EmptyState
                    title="No columns added yet!"
                    subtitle="Add attributes as columns from the left sidebar to populate this area."
                    className="Pipeline-report__table-empty-state bg-transparent"
                />
            )}
            {tableData.length > 0 ? (
                <OverlayScrollbarsComponent
                    defer
                    options={{
                        scrollbars: { autoHide: 'leave' },
                        overflow: { y: 'scroll' },
                    }}
                    className="OverlayScrollbarsComponent overflow-auto"
                >
                    <NSTable className="NSTable--no-footer">
                        <tr className="NSTable__tbody__subheader">
                            {selectedColumns.map(col => (
                                <th key={`header-cell-${col.split(' ').join('')}`}>
                                    <div className="d-flex justify-content-between align-items-center text-nowrap">
                                        <span>{AttributeKeysMap[col]}</span>
                                        {isEditMode && (
                                            <FontAwesomeIcon
                                                icon={getSortIcon(col, colSortConfig)}
                                                className="cursor--pointer ml-3"
                                                onClick={() => configureSort(col)}
                                            />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                        <tbody className="NSTable__tbody text-white">
                            {tableData.map(row => (
                                <tr key={`row-${row[0]}`} className="NSTable__tbody__tr--level-3">
                                    {row.map(cell => (
                                        <td key={`cell-${cell}`}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </NSTable>
                </OverlayScrollbarsComponent>
            ) : (
                <EmptyState
                    title="No results available for selected filter(s)."
                    subtitle="Modify your selected filter(s) to populate this screen with relevant information."
                    className="Pipeline-report__table-empty-state bg-transparent"
                />
            )}
        </>
    );
};

export default DealsTable;
