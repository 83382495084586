import { FEATURE_IDS, USER_CLASSES } from 'services/constants';

export const AccessLevels = {
    NO_ACCESS: 1,
    READ_ACCESS: 2,
    READ_WRITE_ACCESS: 3,
};

export type TAccessLevel = (typeof AccessLevels)[keyof typeof AccessLevels];

export type TFeatureId = (typeof FEATURE_IDS)[keyof typeof FEATURE_IDS];

export interface IFeatureAccess {
    id: TFeatureId;
    name: string;
    accessLevel: TAccessLevel;
}

export interface IFeatureAccessMap {
    [featureId: TFeatureId]: IFeatureAccess | undefined;
}

export interface RouteHandle {
    featureIds: TFeatureId[];
}

export type TUserClassId = (typeof USER_CLASSES)[keyof typeof USER_CLASSES];
