import React from 'react';
import { NSButton, NSCard } from 'bricks';
import { CardBody, CardHeader } from 'reactstrap';
import { EXECUTIVE_SUMMARY_DETAILS, EXECUTIVE_SUMMARY_STATS } from './constants';
import ExecutiveSummaryDetail from './ExecutiveSummaryDetail/ExecutiveSummaryDetail';
import ExecutiveSummaryQuickStats from './ExecutiveSummaryQuickStats/ExecutiveSummaryQuickStats';
import ExecutiveSummaryGenericTable from './ExecutiveSummaryGenericTable/ExecutiveSummaryGenericTable';
import { developmentSections, operationsSections, returnsSections } from './ExecutiveSummaryGenericTable/constants';

const ExcutiveSummary = () => {
    const handleClickExportButton = () => console.log('Export');

    return (
        <NSCard className="NSCard--level-2 shadow-none">
            <CardHeader>
                <div className="d-flex align-items-center justify-content-between text-white">
                    <div className="d-flex align-items-center">
                        <div>
                            <h4 className="mb-0">Executive summary</h4>
                            <h5 className="mb-1 mt-1 text-muted">{EXECUTIVE_SUMMARY_DETAILS.address}</h5>
                        </div>
                    </div>
                    <div>
                        <NSButton color="primary" className="mr-2" outline text="Export" callback={handleClickExportButton} />
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <ExecutiveSummaryDetail details={EXECUTIVE_SUMMARY_DETAILS} />
                <p>timeline</p>
                <ExecutiveSummaryQuickStats stats={EXECUTIVE_SUMMARY_STATS} />
                <p>returns</p>
                <p>operations</p>
                <p>development</p>
                <ExecutiveSummaryGenericTable title="RETURNS" sections={returnsSections} />
                <div className="mb-2" />
                <ExecutiveSummaryGenericTable title="OPERATIONS" sections={operationsSections} />
                <div className="mb-2" />
                <ExecutiveSummaryGenericTable title="DEVELOPMENT" sections={developmentSections} />
            </CardBody>
        </NSCard>
    );
};

export default ExcutiveSummary;
