/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { POPULATE_USES_METHOD, PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import { NSCard } from 'bricks';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import { IUnitTypeOption } from 'views/ProFormaTable/types';
import { formatUTCtoLocal } from 'ns_libs/formatter';
import { UNIT_OF_MEASURE_TYPES } from 'constants/unitTypes';
import { getBudgetLinesDateRange, getProFormaDevelopmentUnitTypeOptions } from 'views/ProFormaTable/helpers';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import { PRO_FORMA_STATUS_PROJECT_CREATED } from 'views/ProFormaHome/constants';
import UsesVsSourcesCard from './UsesVsSourcesCard/UsesVsSourcesCard';
import DevelopmentUsesTable from './DevelopmentUsesTable/DevelopmentUsesTable';
import DevelopmentSourcesTable from './DevelopmentSourcesTable/DevelopmentSourcesTable';
import ImportUsesModal from './ImportUsesModal/ImportUsesModal';
import { UsesTableProvider } from './DevelopmentUsesTable/context/UsesTableProvider';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';

export interface IProFormaDevelopmentProps {
    navigateToSection: (sideNavName: string, tabName?: string) => void;
}

const ProFormaDevelopment = ({ navigateToSection }: IProFormaDevelopmentProps) => {
    const {
        uses,
        sources,
        proForma,
        handleCreateDefaultBudgetClassifications,
        isDefaultBudgetClassificationsLoading,
        handleDeleteBudgetClassification,
        isBudgetClassificationDeletionLoading,
    } = useProFormaSocketContext();

    const [usesMethod, setUsesMethod] = useState<string | null>(null);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const usesLocalStorageData: UNIT_OF_MEASURE_TYPES = JSON.parse(localStorage.getItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY) ?? '{}');

    const unitTypeOptions: IUnitTypeOption[] = getProFormaDevelopmentUnitTypeOptions(proForma.proFormaModelId);
    const existingUnitType =
        !isEmpty(usesLocalStorageData) && unitTypeOptions.length
            ? unitTypeOptions.filter(option => option.value === usesLocalStorageData)[0]
            : unitTypeOptions?.[0];

    const showUsesOverSourcesWarning =
        uses?.totals?.amount !== null && sources?.totals?.amount !== null && Number(uses?.totals?.amount) > Number(sources?.totals?.amount);

    const [unitType, setUnitType] = useState<IUnitTypeOption | null>(null);

    const toggleImportModal = () => setIsImportModalOpen(!isImportModalOpen);

    const startUsesFromScratch = () => {
        setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title);
    };

    const handleStartFromScratch = () => {
        if (!uses?.uses?.length) {
            handleCreateDefaultBudgetClassifications(startUsesFromScratch);
        } else {
            setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title);
        }
    };

    const deleteSuccessCallback = () => {
        setUsesMethod(null);
        toggleImportModal();
    };

    const handleDeleteBudgetCategories = () => {
        handleDeleteBudgetClassification([], true, deleteSuccessCallback);
    };

    useEffect(() => {
        if (!unitType && existingUnitType) {
            setUnitType(existingUnitType);
        }
    }, [unitType, existingUnitType]);

    if (!proForma) {
        // show loading screen instead
        return null;
    }

    const { earliestBudgetLinesStartDate, latestBudgetLinesEndDate } = getBudgetLinesDateRange(uses.uses || []);

    return (
        <ReadOnlyWrapper isReadOnly={proForma.proFormaStatusId === PRO_FORMA_STATUS_PROJECT_CREATED}>
            <ExpandableSection
                number={1}
                title="Development Timeline"
                subtitle="Determine development timeline for this investment"
                className="NSCard--level-2"
                isExpanded
                animate
            >
                <div className="d-flex m-3">
                    <NSCard className="NSCard--level-4 w-100 py-2 px-3 mr-3">
                        <div>
                            <h5 className="my-0 text-muted font-14">Development start</h5>
                            <RenderIf isTrue={earliestBudgetLinesStartDate !== null}>
                                <small className="text-dark font-14">
                                    {earliestBudgetLinesStartDate && formatUTCtoLocal(earliestBudgetLinesStartDate, 'MMMM yyyy')}
                                </small>
                            </RenderIf>
                            <RenderIf isTrue={!earliestBudgetLinesStartDate}>
                                <small className="text-dark font-italic font-14">In progress</small>
                            </RenderIf>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-4 w-100 py-2 px-3">
                        <div>
                            <h5 className="my-0 text-muted font-14">Development end</h5>
                            <RenderIf isTrue={latestBudgetLinesEndDate !== null}>
                                <small className="text-dark font-14">
                                    {latestBudgetLinesEndDate && formatUTCtoLocal(latestBudgetLinesEndDate, 'MMMM yyyy')}
                                </small>
                            </RenderIf>
                            <RenderIf isTrue={!latestBudgetLinesEndDate}>
                                <small className="text-dark font-italic font-14">In progress</small>
                            </RenderIf>
                        </div>
                    </NSCard>
                </div>
            </ExpandableSection>

            <RenderIf isTrue={showUsesOverSourcesWarning}>
                <NSInfoBanner
                    title="Warning: Uses exceed sources"
                    subtitle="Ensure funding is sufficient to cover uses."
                    className="mt-3 NSCard--level-4 NSCard__left-border--warning"
                    warning
                    customInfo={<UsesVsSourcesCard usesTotal={uses?.totals?.amount || 0} sourcesTotal={sources?.totals?.amount || 0} />}
                />
            </RenderIf>

            <ExpandableSection
                number={2}
                title="Uses"
                subtitle={'Information about the investment\'s allocation of funds'}
                className="mt-3 NSCard--level-2"
                displayButton={Boolean(usesMethod || uses?.uses?.length)}
                displayButtonText="Import Uses via different method"
                displayButtonCallback={toggleImportModal}
                isExpanded
                animate
            >
                <RenderIf isTrue={!uses?.uses?.length}>
                    <Row className="m-3">
                        <Col m={12} xl={4} className="px-0">
                            <div className="h3 mt-0 text-dark mb-3">
                                Select a method to populate
                                {' '}
                                <b>Uses</b>
                                :
                            </div>
                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.subtitle}</div>
                            </NSCard>

                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.subtitle}</div>
                            </NSCard>

                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.subtitle}</div>
                            </NSCard>

                            <div role="presentation" onClick={handleStartFromScratch} className="d-flex mt-3 cursor--pointer">
                                <RenderIf isTrue={isDefaultBudgetClassificationsLoading}>
                                    <span className="spinner-border spinner-border-sm" style={{ height: '20px', width: '20px' }} />
                                </RenderIf>
                                <RenderIf isTrue={!isDefaultBudgetClassificationsLoading}>
                                    <div className="text-primary">{POPULATE_USES_METHOD.START_FROM_SCRATCH.title}</div>
                                    <FontAwesomeIcon icon={faArrowRight} className="ml-1 my-auto text-primary" />
                                </RenderIf>
                            </div>
                        </Col>
                    </Row>
                </RenderIf>

                <RenderIf isTrue={uses?.uses?.length}>
                    <div className="m-3">
                        {/* <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}>
                        </RenderIf>

                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}>
                        </RenderIf>

                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}>
                        </RenderIf> */}

                        <RenderIf isTrue={(usesMethod === POPULATE_USES_METHOD.START_FROM_SCRATCH.title || uses?.uses?.length) && unitType && proForma}>
                            <UsesTableProvider navigateToSection={navigateToSection}>
                                <DevelopmentUsesTable
                                    uses={uses || {}}
                                    unitType={unitType!}
                                    setUnitType={setUnitType}
                                    unitTypeOptions={unitTypeOptions}
                                    proForma={proForma}
                                />
                            </UsesTableProvider>
                        </RenderIf>
                    </div>
                </RenderIf>
            </ExpandableSection>
            <ExpandableSection
                number={3}
                title="Sources"
                subtitle="Information about the investment's sources of funds"
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <RenderIf isTrue={!isEmpty(sources) && unitType}>
                    <div className="d-flex m-3">
                        <DevelopmentSourcesTable sources={sources} unitType={unitType!} setUnitType={setUnitType} unitTypeOptions={unitTypeOptions} />
                    </div>
                </RenderIf>
            </ExpandableSection>

            <RenderIf isTrue={isImportModalOpen}>
                <ImportUsesModal
                    isOpen={isImportModalOpen}
                    toggle={toggleImportModal}
                    handleDeleteBudgetCategories={handleDeleteBudgetCategories}
                    isLoading={isBudgetClassificationDeletionLoading}
                />
            </RenderIf>
        </ReadOnlyWrapper>
    );
};

export default ProFormaDevelopment;
