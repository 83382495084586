import React, { Dispatch, SetStateAction, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NSTable, NSButton, NSDropdown } from 'bricks';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import { PRO_FORMA_SOURCE_METRIC_OPTIONS } from 'constants/sources';
import { DevelopmentUnitOfMeasureTypes, ISourcesSummary, IUnitTypeOption } from 'views/ProFormaTable/types';
import { PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import AddSourceModal from './AddSourceModal/AddSourceModal';
import Source from './Source/Source';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

export interface IDevelopmentSourcesTable {
    sources: ISourcesSummary;
    unitType: IUnitTypeOption;
    setUnitType: Dispatch<SetStateAction<IUnitTypeOption | null>>;
    unitTypeOptions: IUnitTypeOption[];
}
const DevelopmentSourcesTable = ({
    sources, unitType, setUnitType, unitTypeOptions,
}: IDevelopmentSourcesTable) => {
    const { handleCreateProFormaSource } = useProFormaSocketContext();

    const [openAddSourceModal, setOpenAddSourceModal] = useState(false);
    const [isCreateProFormaSourcePending, setIsCreateProFormaSourcePending] = useState(false);

    const toggleAddSourceModal = () => setOpenAddSourceModal(!openAddSourceModal);
    const [debtSourceMetricOption, setDebtSourceMetricOption] = useState(PRO_FORMA_SOURCE_METRIC_OPTIONS[0]);

    const createSourceCallback = (isSuccess: boolean = true) => {
        setIsCreateProFormaSourcePending(false);
        if (isSuccess) setOpenAddSourceModal(false);
    };

    const handleClickAddSource = (sourceTypeId: string, sourceName: string) => {
        setIsCreateProFormaSourcePending(true);

        const max = !sources.sources.length ? 0 : Math.max(...sources.sources.map(source => source.traunch || 0));

        handleCreateProFormaSource(
            {
                sourceTypeId,
                name: sourceName,
                traunch: max + 1,
                amount: '0',
            },
            createSourceCallback,
        );
    };

    const handleUpdateUnitType = (option: IUnitTypeOption) => {
        setUnitType(option);
        localStorage.setItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY, JSON.stringify(option.value));
    };

    const selectedUnitTotal = sources.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType.value as DevelopmentUnitOfMeasureTypes]];

    const unavailableUnitTypes: string[] = [];

    unitTypeOptions.map(option => {
        const costPerUnitTotal = sources.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[option.value as DevelopmentUnitOfMeasureTypes]];
        return costPerUnitTotal === null ? unavailableUnitTypes.push(option.value) : null;
    });

    const updatedUnitTypeOptions = unitTypeOptions.map(option => ({
        ...option,
        disabled: unavailableUnitTypes.includes(option.value),
    }));

    return (
        <>
            <NSTable>
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border align-middle" colSpan={4}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center ml-2">
                                    <span className="ml-0">Name</span>
                                </div>
                                <NSButton className="px-2 text-muted cursor--pointer" size="sm" iconOnly icon={faPlus} callback={toggleAddSourceModal} />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={2}>
                            <div className="d-flex justify-content-end">
                                <NSDropdown
                                    options={PRO_FORMA_SOURCE_METRIC_OPTIONS}
                                    selectedOption={debtSourceMetricOption}
                                    handleSelectOption={setDebtSourceMetricOption}
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={1}>
                            <div className="d-flex justify-content-end">Fund. traunch</div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={1}>
                            <div className="d-flex justify-content-end">% of sources</div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={2}>
                            <div className="text-right">
                                <span className="mr-1">Cost per</span>
                                <NSDropdown
                                    options={updatedUnitTypeOptions}
                                    selectedOption={{ label: UNIT_TYPE_NAMES[unitType.value].abbreviated, value: unitType.value }}
                                    handleSelectOption={option => handleUpdateUnitType(option)}
                                    containerClassName="ReadOnlyWrapper--enable-pointer-events"
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle text-right" colSpan={2}>
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody className="NSTable__tbody">
                    <RenderIf isTrue={sources.sources.length}>
                        {sources.sources.map(source => (
                            <Source
                                key={source.id}
                                source={source}
                                totalSourcesAmount={sources.sources.length}
                                unitType={unitType.value as DevelopmentUnitOfMeasureTypes}
                            />
                        ))}
                    </RenderIf>

                    <RenderIf isTrue={!sources.sources.length}>
                        <tr className="NSTable__tbody_tr NSTable__tbody__tr--level-1">
                            <td className="NSTable__tbody__tr__td align-middle" colSpan={12}>
                                <div className="text-center text-dark mt-4">Your added sources will appear here.</div>
                                <div className="text-center mb-4">
                                    Click the plus
                                    {' '}
                                    <span className="text-dark">[+]</span>
                                    {' '}
                                    symbol in the
                                    {' '}
                                    <span className="text-dark">Name</span>
                                    {' '}
                                    header to
                                    add your first source.
                                </div>
                            </td>
                        </tr>
                    </RenderIf>
                </tbody>
                <tfoot className="NSTable__tfoot">
                    <tr className="NSTable__tfoot__tr">
                        <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left" colSpan={4}>
                            <div className="ml-2">Total</div>
                        </td>

                        <td className="NSTable__tfoot__tr__td" colSpan={2} />
                        <td className="NSTable__tfoot__tr__td" colSpan={1} />
                        <td className="NSTable__tfoot__tr__td text-right" colSpan={1}>
                            {sources.sources.length ? '100.00%' : '—'}
                        </td>
                        <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                            {selectedUnitTotal !== null ? numeralFormatterCurrency(selectedUnitTotal) : '—'}
                        </td>
                        <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                            {sources.totals?.amount !== null ? numeralFormatterCurrency(sources.totals?.amount) : '—'}
                        </td>
                    </tr>
                </tfoot>
            </NSTable>

            <RenderIf isTrue={openAddSourceModal}>
                <AddSourceModal
                    toggle={toggleAddSourceModal}
                    isOpen={openAddSourceModal}
                    isLoading={isCreateProFormaSourcePending}
                    handleClickAddSource={handleClickAddSource}
                />
            </RenderIf>
        </>
    );
};

export default DevelopmentSourcesTable;
