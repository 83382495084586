import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Nav, NavItem } from 'reactstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { URLS } from 'services/urls';
import { PRO_FORMA_TABLE_NAV } from '../../constants';
import { NSButton, NSCard } from '../../../../bricks';
import EditProFormaModal from '../ProForma/EditProFormaModal/EditProFormaModal';
import ProFormaSideNavFooter from './ProFormaSideNavFooter/ProFormaSideNavFooter';
import './ProFormaSideNav.scss';
import { useProFormaSocketContext } from '../ProForma/socketContext/ProFormaSocketProvider';

export interface IProFormaSideNavProps {
    activeNav: string;
    navigateToSection: (sideNavName: string, tabName?: string) => void;
}

const ProFormaSideNav = ({ activeNav, navigateToSection }: IProFormaSideNavProps) => {
    const { proForma } = useProFormaSocketContext();
    const navigate = useNavigate();
    const { dealId } = useParams<{ dealId: string }>();

    const [showEditProFormaModal, setShowEditProFormaModal] = useState(false);
    const toggleEditProFormaModal = () => setShowEditProFormaModal(!showEditProFormaModal);

    const navItemClass = (tag: string) => classNames('d-flex align-items-center px-4 cursor--pointer', {
        'bg-primary-lighten text-primary': tag === activeNav,
        'text-muted': tag !== activeNav,
    });

    const navigateToProFormaList = () => {
        const path = generatePath(URLS.PRO_FORMA.HOME, { dealId });
        navigate(path);
    };

    return (
        <div className="ProFormaSideNav-wrapper">
            <NSCard className="NSCard--level-2 ProFormaSideNav">
                <div className="px-3 pt-3">
                    <NSButton callback={navigateToProFormaList} color="primary" className="font-13 px-2" size="sm" outline>
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" size="xs" />
                        Exit
                    </NSButton>
                    <h4 className="text-dark mb-0">{proForma?.name}</h4>
                    <h5 className="text-muted font-16 text-wrap">{proForma?.description}</h5>
                    <span role="presentation" onClick={toggleEditProFormaModal} className="mt-1 mb-0 font-13 text-primary cursor--pointer">
                        Edit name/description
                    </span>
                </div>
                <hr className="mb-0" />

                <div>
                    <Nav tabs className="flex-column w-100 border-0">
                        {Object.entries(PRO_FORMA_TABLE_NAV).map(([key, nav]) => (
                            <NavItem key={key} className={navItemClass(nav.name)}>
                                <FontAwesomeIcon icon={nav.icon} />
                                <div
                                    className="py-2 pr-2 pl-1 cursor--pointer"
                                    data-toggle="tab"
                                    onClick={() => navigateToSection(nav.name)}
                                    aria-hidden="true"
                                >
                                    <span>{nav.name}</span>
                                </div>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
                <ProFormaSideNavFooter />
            </NSCard>
            <EditProFormaModal showEditProFormaModal={showEditProFormaModal} toggleEditProFormaModal={toggleEditProFormaModal} />
        </div>
    );
};

export default ProFormaSideNav;
