import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import {
    NSCard, NSInput, NSLabel, NSSelect,
} from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import RenderIf from 'components/RenderIf/RenderIf';
import { STATES_OPTIONS } from 'constants/statesList';
import { FORM_LABEL_CLASS_NAME, FORM_ROW_CLASS_NAME, GENERAL_INFORMATION_EXPANDABLE_SECTIONS } from 'views/ProFormaTable/constants';
import { ProFormaModelIds } from 'constants/proForma';
import Map from 'components/Map/Map';
import useDebounce from 'views/Deals/hooks/useDebounce';
import useGetCoordinates from 'queries/Mapbox/useGetCoordinates';
import { handleDateChange, calculateGrossBuildableArea, calculateFloorAreaRatio } from 'views/ProFormaTable/helpers';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import { PRO_FORMA_STATUS_PROJECT_CREATED } from 'views/ProFormaHome/constants';
import { IProFormaValues, IProFormaGeneralInfo, IGrossBuildableAreaLotsData } from 'views/ProFormaTable/types';
import ApartmentSizeDetails from './ApartmentSizeDetails/ApartmentSizeDetails';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';

const ProFormaGeneralInformation = () => {
    const { proForma, handleUpdateProForma, buildableLots } = useProFormaSocketContext();

    const parseProFormaData = (updatedProForma: IProFormaGeneralInfo, updatedBuildableLots: IGrossBuildableAreaLotsData) => {
        const grossBuildableArea = calculateGrossBuildableArea(
            updatedBuildableLots,
            updatedProForma.grossBuildableArea,
            updatedProForma.floorAreaRatio,
            updatedProForma.landArea,
            updatedProForma.landAreaUnitTypeId,
        );

        const floorAreaRatio = calculateFloorAreaRatio(
            updatedProForma.floorAreaRatio,
            updatedProForma.landArea,
            updatedProForma.landAreaUnitTypeId,
            grossBuildableArea,
        );

        const initialValues: IProFormaValues = {
            id: updatedProForma.id,
            address: updatedProForma.address,
            city: updatedProForma.city,
            county: updatedProForma.county,
            state: updatedProForma.state,
            zipCode: updatedProForma.zipCode,
            startDate: updatedProForma.startDate ? updatedProForma.startDate.split('T')[0] : '',
            landArea: updatedProForma.landArea !== null ? Number(updatedProForma.landArea) : null,
            landAreaUnitTypeId: updatedProForma.landAreaUnitTypeId,
            saleDate: updatedProForma.saleDate ? updatedProForma.saleDate.split('T')[0] : '',
            floorAreaRatio,
            grossBuildableArea,
            buildings: Number(updatedProForma.buildings),
            averageStories: Number(updatedProForma.averageStories),
            builtGrossSquareFootage: updatedProForma.builtGrossSquareFootage ? Number(updatedProForma.builtGrossSquareFootage) : null,
            structuredParkingSpaces: updatedProForma.structuredParkingSpaces,
            surfaceParkingSpaces: updatedProForma.surfaceParkingSpaces,
        };
        return initialValues;
    };

    const [values, setValues] = useState<IProFormaValues>(parseProFormaData(proForma, buildableLots));

    useEffect(() => {
        if (proForma.id) {
            setValues(parseProFormaData(proForma, buildableLots));
        }
    }, [proForma]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const handleSelect = (name: string, option: string | IOptionAnyValue | IOptionAnyValue[]) => {
        setValues({ ...values, [name]: option });
    };

    const fullAddressAsString = `${values.address}, ${values.city}, ${values.state}, ${values.zipCode}`;
    const debouncedAddress = useDebounce(fullAddressAsString, 500);
    const { data: coordinates = {}, isLoading: isLoadingMapCoordinates } = useGetCoordinates(debouncedAddress);

    return (
        <ReadOnlyWrapper isReadOnly={proForma.proFormaStatusId === PRO_FORMA_STATUS_PROJECT_CREATED}>
            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <Row>
                        <Col>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Deal name</NSLabel>
                                <NSInput type="text" name="dealName" value={proForma.dealName} disabled />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Address</NSLabel>
                                <NSInput
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma([{ key: 'address', value: values.address }])}
                                    placeholder="Enter address"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>City</NSLabel>
                                <NSInput
                                    type="text"
                                    name="city"
                                    value={values.city}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma([{ key: 'city', value: values.city }])}
                                    placeholder="Enter city"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>County</NSLabel>
                                <NSInput
                                    type="text"
                                    name="county"
                                    value={values.county}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma([{ key: 'county', value: values.county }])}
                                    placeholder="Enter county"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>State</NSLabel>
                                <div className="col-4 pl-0">
                                    <NSSelect
                                        name="state"
                                        options={STATES_OPTIONS}
                                        value={{ label: values.state, value: values.state }}
                                        onChange={option => handleSelect('state', option.value)}
                                        onBlur={() => handleUpdateProForma([{ key: 'state', value: values.state }])}
                                        isClearable={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Zip</NSLabel>
                                <div className="col-4 pl-0">
                                    <NSInput
                                        type="number"
                                        name="zipCode"
                                        value={values.zipCode}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                        onBlur={() => handleUpdateProForma([{ key: 'zipCode', value: values.zipCode }])}
                                        placeholder="Enter zip code"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="NSCard--level-3 mx-2 p-2">
                            <RenderIf isTrue={coordinates && coordinates?.lat && coordinates?.lng} isLoading={isLoadingMapCoordinates}>
                                <Map lat={coordinates.lat} lng={coordinates.lng} />
                            </RenderIf>
                        </Col>
                    </Row>
                </NSCard>
            </ExpandableSection>

            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <div className="d-flex">
                        <div className="d-flex w-50">
                            <div className="d-flex w-100 my-auto">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Start month</NSLabel>
                                <NSInput
                                    type="date"
                                    name="startDate"
                                    value={values.startDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleDateChange('startDate', values.startDate, values, handleUpdateProForma)}
                                />
                            </div>
                        </div>
                        <div className="d-flex w-50 ml-3">
                            <div className="d-flex w-100 my-auto">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Sale month</NSLabel>
                                <NSInput
                                    type="date"
                                    name="saleDate"
                                    value={values.saleDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleDateChange('saleDate', values.saleDate, values, handleUpdateProForma)}
                                    min={proForma.startDate ? proForma.startDate.split('T')[0] : undefined}
                                />
                            </div>
                        </div>
                    </div>
                </NSCard>
            </ExpandableSection>

            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <RenderIf isTrue={proForma.proFormaModelId === ProFormaModelIds.APARTMENT}>
                        <ApartmentSizeDetails values={values} setValues={setValues} handleInputChange={handleInputChange} handleSelect={handleSelect} />
                    </RenderIf>

                    {/* Additional pro forma model size forms below */}
                    {/* <RenderIf isTrue={values.proFormaModelId === ProFormaModelIds.INDUSTRIAL}><IndustrialSizeDetails /></RenderIf>

                    <RenderIf isTrue={values.proFormaModelId === ProFormaModelIds.RETAIL}><RetailSizeDetails /></RenderIf>

                    <RenderIf isTrue={values.proFormaModelId === ProFormaModelIds.office}><OfficeSizeDetails /></RenderIf> */}
                </NSCard>
            </ExpandableSection>
        </ReadOnlyWrapper>
    );
};

export default ProFormaGeneralInformation;
