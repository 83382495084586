import React, { useCallback, useEffect } from 'react';
import { NSButton, NSDropdown } from 'bricks';
import './BackOfEnvelope.scss';
import { faCalculator, faPlus, faScaleBalanced } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, generatePath } from 'react-router-dom';
import { formatDate } from 'ns_libs/formatter';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { useSlideout } from 'contexts/BackOfEnvelopeContext';
import { URLS } from 'services/urls';
import { SimplifiedScenario } from 'components/DealScenarioDropdown/types';
import useCreateProForma from 'queries/ProForma/useCreateProForma';
import ScenarioInformation from './components/ScenarioInformation';
import ScenarioReturns from './components/ScenarioReturns';
import { useGetBackOfEnvelope } from './hooks/useGetBOE';

type IProps = {
    orgId: string;
    boeScenarios?: SimplifiedScenario[];
    dealId: number;
    handleScenarioChange: (scenarioId: number) => void;
    defaultScenarioId?: number | undefined;
};

const BackOfEnvelope = ({
    orgId, dealId, boeScenarios, handleScenarioChange, defaultScenarioId,
}: IProps) => {
    const navigate = useNavigate();
    const createProFormaMutation = useCreateProForma();

    const {
        toggleSlideout,
        selectedScenarioId,
        selectedScenario,
        setSelectedScenarioId,
        setSelectedScenario,
        setSelectedSlideoutScenarioId,
        getSlideoutValues,
    } = useSlideout();

    const { data: fetchedDeal } = useGetDealById({
        orgId,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const { data: overviewScenarioData } = useGetBackOfEnvelope({
        orgId,
        scenarioId: selectedScenarioId,
    });

    const { data: allScenarios } = useGetBackOfEnvelope({
        orgId,
        dealId: dealId.toString(),
    });

    const simplifiedOverviewScenarios = boeScenarios?.map(item => ({ value: item.id, label: item.name }));

    const handleViewComparison = useCallback(() => {
        navigate(`/deals/comparison/${fetchedDeal?.id}`, {
            state: {
                deal: fetchedDeal,
                scenarios: allScenarios,
                selectedScenario: overviewScenarioData,
            },
        });
    }, [navigate, fetchedDeal, allScenarios, overviewScenarioData]);

    const handleSelectScenario = useCallback((option: IOptionAnyValue) => {
        const scenarioId = option.id || option.value;

        setSelectedScenario(option);
        setSelectedScenarioId(scenarioId);
        setSelectedSlideoutScenarioId(undefined);
        handleScenarioChange(scenarioId);
    }, []);

    useEffect(() => {
        if (simplifiedOverviewScenarios?.length) {
            if (defaultScenarioId && !selectedScenario) {
                const defaultOption = simplifiedOverviewScenarios.find(option => option.value === defaultScenarioId);
                if (defaultOption) {
                    setSelectedScenario(defaultOption);
                    setSelectedScenarioId(defaultScenarioId);
                }
            } else if (!selectedScenario) {
                const firstScenario = simplifiedOverviewScenarios[0];
                setSelectedScenario({ label: firstScenario?.label || '', value: Number(firstScenario?.value) });
                setSelectedScenarioId(Number(firstScenario?.value));
            }
        }
    }, [simplifiedOverviewScenarios, defaultScenarioId, setSelectedScenarioId, setSelectedScenario]);

    const handleToggleSlideout = () => {
        getSlideoutValues();
        toggleSlideout();
    };

    const handleCreateProForma = async () => {
        try {
            const result = await createProFormaMutation.mutateAsync({ dealId });
            if (result) {
                navigate(
                    generatePath(URLS.PRO_FORMA.WIZARD, {
                        dealId: dealId.toString(),
                        proFormaId: result.id.toString(),
                    }),
                );
            }
        } catch (error) {
            console.error('Error creating pro forma:', error);
            // TODO add toast here?
        }
    };

    const EmptyState = (
        <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
            <h4 className="text-white mb-2">No scenarios yet.</h4>
            <p className="text-muted d-flex align-items-center justify-content-center">
                Create your first return scenario using the
                {' '}
                <NSButton className="text-primary p-0 mx-1" callback={toggleSlideout}>
                    <FontAwesomeIcon icon={faCalculator} className="text-primary px-1" aria-label="calculator" />
                    Back-Of-Envelope
                </NSButton>
                {' '}
                tool.
            </p>
        </div>
    );

    return (
        <div className="BackOfEnvelope d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center w-100 py-2 px-3">
                <div className="text-left">
                    <h3 className="text-white mb-1">Back-Of-Envelope</h3>
                    <p className="text-muted mb-0">Assess the financial feasibility of this deal.</p>
                </div>
                <div className="d-flex align-items-center">
                    <RenderIf isTrue={simplifiedOverviewScenarios && simplifiedOverviewScenarios.length > 1}>
                        {simplifiedOverviewScenarios && (
                            <NSDropdown
                                placeholder="Select scenario"
                                options={simplifiedOverviewScenarios}
                                selectedOption={simplifiedOverviewScenarios.find(
                                    option => option.value === (defaultScenarioId || selectedScenario?.value),
                                )}
                                handleSelectOption={handleSelectScenario}
                            />
                        )}
                    </RenderIf>
                    <NSButton text="Compare" color="secondary" icon={faScaleBalanced} className="mx-2" callback={handleViewComparison} />
                    <NSButton
                        data-testid="back-of-envelope-button"
                        text="Back-Of-Envelope"
                        color="secondary"
                        icon={faCalculator}
                        callback={handleToggleSlideout}
                    />
                </div>
            </div>

            {overviewScenarioData && simplifiedOverviewScenarios && simplifiedOverviewScenarios.length > 0 ? (
                <div className="BackOfEnvelope__ScenarioContainer NSCard--level-2 py-2 px-3 mx-3 my-1">
                    <div className="BackOfEnvelope__ScenarioContainer--header d-flex flex-row justify-content-between align-items-center pb-2 mb-2">
                        <div>
                            <h4 className="text-white font-weight-bold mb-0">{overviewScenarioData.name}</h4>
                            <h5 className="mt-0">{`Created on ${formatDate(overviewScenarioData.createdAt)}`}</h5>
                        </div>
                        <NSButton
                            callback={handleCreateProForma}
                            text="Create pro forma"
                            color="primary"
                            icon={faPlus}
                            isLoading={createProFormaMutation.isPending}
                        />
                    </div>
                    <ScenarioReturns boeScenario={overviewScenarioData} deal={fetchedDeal || undefined} />
                    <ScenarioInformation boeScenario={overviewScenarioData} />
                </div>
            ) : (
                EmptyState
            )}
        </div>
    );
};

export default BackOfEnvelope;
