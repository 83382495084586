import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    NSButton, NSCard, NSInput, NSLabel,
} from 'bricks';
import { faTrash, faDollar, faPercentage } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import './OtherIncomeCard.scss';
import { IOtherIncome } from '../../types';

interface OtherIncomeCardProps {
    values: IOtherIncome;
    onUpdate: (updatedValues: OtherIncomeCardProps['values']) => void;
    onDelete: () => void;
}

const OtherIncomeCard = ({ values, onUpdate, onDelete }: OtherIncomeCardProps) => {
    const [name, setName] = useState(values.name || '');
    const [annualGrowth, setAnnualGrowth] = useState<number | ''>(values.annualGrowth || '');
    const [unitPerMonth, setUnitPerMonth] = useState<number | ''>(values.unitPerMonth || '');
    const [amountPerYear, setAmountPerYear] = useState<number | ''>(values.amountPerYear || '');

    useEffect(() => {
        onUpdate({
            id: values.id,
            name,
            annualGrowth: Number(annualGrowth) || 0,
            unitPerMonth: Number(unitPerMonth) || 0,
            amountPerYear: Number(amountPerYear) || 0,
        });
    }, [name, annualGrowth, unitPerMonth, amountPerYear]);

    return (
        <NSCard className="NSCard--level-4 mb-2 p-2">
            <div className="d-flex justify-content-between OtherIncomeCardContainer">
                <div className="flex-grow-1">
                    <NSLabel>Name</NSLabel>
                    <NSInput
                        id="name"
                        dataTestId="name-input"
                        name="name"
                        placeholder="Enter name"
                        type="text"
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    />
                </div>
                <div className="OtherIncomeCardContainer__annualGrowthContainer">
                    <NSLabel>Annual growth</NSLabel>
                    <NSInput
                        id="annualGrowth"
                        dataTestId="annualGrowth-input"
                        name="annualGrowth"
                        placeholder="Enter value"
                        type="number"
                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                        value={annualGrowth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAnnualGrowth(e.target.value ? Number(e.target.value) : '')}
                    />
                </div>
                <div className="flex-grow-1">
                    <NSLabel>Unit/month</NSLabel>
                    <NSInput
                        id="unitPerMonth"
                        dataTestId="unitPerMonth-input"
                        name="unitPerMonth"
                        placeholder="Enter value"
                        type="number"
                        prependInputAddon={<FontAwesomeIcon icon={faDollarSign} />}
                        value={unitPerMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUnitPerMonth(e.target.value ? Number(e.target.value) : '')}
                    />
                </div>
                <div className="flex-grow-1">
                    <NSLabel>Amount/year</NSLabel>
                    <NSInput
                        id="amountPerYear"
                        dataTestId="amountPerYear-input"
                        name="amountPerYear"
                        placeholder="Enter value"
                        type="number"
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        value={amountPerYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmountPerYear(e.target.value ? Number(e.target.value) : '')}
                    />
                </div>
                <div className="d-flex align-items-end">
                    <NSButton color="danger" outline callback={onDelete}>
                        <FontAwesomeIcon icon={faTrash} />
                    </NSButton>
                </div>
            </div>
        </NSCard>
    );
};

export default OtherIncomeCard;
