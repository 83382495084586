import React from 'react';

interface IDetailTextProps {
    label: string;
    value: string | number;
}

const DetailText = ({ label, value }: IDetailTextProps) => (
    <div className="d-flex justify-content-between mt-1">
        <div className="text-muted">{label}</div>
        <div className="text-dark">{value}</div>
    </div>
);

export default DetailText;
