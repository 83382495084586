import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import CreateProFormaModal from 'views/CreateProFormaModal/CreateProFormaModal';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import EmptyState from 'components/EmptyState/EmptyState';
import { useGetProFormasByDeal } from 'queries/ProForma';
import PageTitle from '../../components/PageTitle/PageTitle';
import RenderIf from '../../components/RenderIf/RenderIf';
import { NSButton, NSNavTabs } from '../../bricks';
import { NORTHSPYRE_PRO_FORMAS, UPLOADED_PRO_FORMAS } from './constants';
import NorthspyreProFormasTable from './components/NorthspyreProFormasTable/NorthspyreProFormasTable';
import UploadededProFormasTable from './components/UploadedProFormasTable/UploadedProFormasTable';

export interface IProFormaHomeProps {
    dealId?: number;
}

const ProFormaHome = () => {
    const { selectedOrganizationId } = useAuthContext();
    const { dealId } = useParams<{ dealId: string }>();

    const [activeTabName, setActiveTabName] = useState(NORTHSPYRE_PRO_FORMAS);
    const [openCreateProFormaModal, setOpenCreateProFormaModal] = useState(false);

    const { data: fetchedDeal, isLoading } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const { data: northspyreProFormas, isLoading: isLoadingNorthspyreProFormas } = useGetProFormasByDeal({ dealId: Number(dealId) });
    const toggleOpenProformaModal = () => setOpenCreateProFormaModal(!openCreateProFormaModal);

    // TODO: replace once we have uploaded pro formas
    const hasUploadedProFormas = false;

    const proFormaNavTabs: string[] = [];
    if (northspyreProFormas?.length) proFormaNavTabs.push(NORTHSPYRE_PRO_FORMAS);
    if (hasUploadedProFormas) proFormaNavTabs.push(UPLOADED_PRO_FORMAS);

    if (!fetchedDeal && !isLoading && !isLoadingNorthspyreProFormas) {
        return <div>No deal selected</div>;
    }

    return (
        <>
            <PageTitle title="Pro Forma" displayBreadcrumb dealName={fetchedDeal?.dealName} pageMetaDataTitle="Pro Forma Home" containerClassName="mb-3">
                <NSButton color="secondary" outline={false} callback={() => {}} text="Upload" className="mr-2" />
                <NSButton color="primary" outline={false} callback={toggleOpenProformaModal} text="Create pro forma" />
            </PageTitle>

            <RenderIf isTrue={!isLoading && !isLoadingNorthspyreProFormas} isLoading={isLoading || isLoadingNorthspyreProFormas}>
                <RenderIf isTrue={!hasUploadedProFormas && !northspyreProFormas?.length}>
                    <EmptyState
                        title="No pro formas created yet!"
                        subtitle="Create your first pro forma from scratch or upload your own."
                        className="mt-3 vh-100"
                    />
                </RenderIf>

                <RenderIf isTrue={hasUploadedProFormas || northspyreProFormas?.length}>
                    <RenderIf isTrue={proFormaNavTabs.length > 1}>
                        <NSNavTabs
                            containerClassName="mb-0 text-dark d-flex justify-content-start"
                            navItems={proFormaNavTabs.map((navName, index) => ({
                                callback: () => setActiveTabName(navName),
                                name: navName,
                                isActive: activeTabName === navName,
                                id: `pro-forma-home-nav-tab-${index}`,
                                className: 'text-dark text-nowrap px-4',
                            }))}
                        />
                    </RenderIf>

                    <TabContent activeTab={activeTabName}>
                        <TabPane tabId={NORTHSPYRE_PRO_FORMAS}>
                            <RenderIf isTrue={activeTabName === NORTHSPYRE_PRO_FORMAS}>
                                <NorthspyreProFormasTable proFormas={northspyreProFormas!} />
                            </RenderIf>
                        </TabPane>
                        <TabPane tabId={UPLOADED_PRO_FORMAS}>
                            <RenderIf isTrue={activeTabName === UPLOADED_PRO_FORMAS}>
                                <UploadededProFormasTable />
                            </RenderIf>
                        </TabPane>
                    </TabContent>
                </RenderIf>
            </RenderIf>

            <RenderIf isTrue={openCreateProFormaModal}>
                <CreateProFormaModal toggle={toggleOpenProformaModal} isOpen={openCreateProFormaModal} />
            </RenderIf>
        </>
    );
};

export default ProFormaHome;
