import React, { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown, faCaretUp, faPlus, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { IProFormaUses, DevelopmentUnitOfMeasureTypes } from 'views/ProFormaTable/types';
import { NSCheckbox } from 'bricks';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import { DEFAULT_BUDGET_LINE_NAME } from 'views/ProFormaTable/constants';
import { useProFormaSocketContext } from 'views/ProFormaTable/components/ProForma/socketContext/ProFormaSocketProvider';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useUsesTableContext } from '../../context/UsesTableProvider';
import UsesTableBudgetLine from './UsesTableBudgetLine/UsesTableBudgetLine';

export interface IUsesTableSubcategory {
    subcategory: IProFormaUses;
    unitType: DevelopmentUnitOfMeasureTypes;
}

const UsesTableSubcategory = ({ subcategory, unitType }: IUsesTableSubcategory) => {
    const {
        selectedCategories, expandedCategories, updateSelectedCategories, updateExpandedCategories,
    } = useUsesTableContext();
    const {
        handleCreateBudgetClassification,
        isBudgetClassificationLoading,
        handleUpdateBudgetClassifications,
        handleDeleteBudgetClassification,
        isBudgetClassificationDeletionLoading,
    } = useProFormaSocketContext();

    const [isHovered, setIsHovered] = useState(false);
    const [subcategoryName, setSubcategoryName] = useState(subcategory.description || '');
    const [openDeleteSubcategoryModal, setOpenDeleteSubcategoryModal] = useState(false);

    const toggleDeleteSubcategoryModal = () => setOpenDeleteSubcategoryModal(!openDeleteSubcategoryModal);

    const editableBudgetClassificationIds = subcategory.children?.map(
        budgetClassification => (budgetClassification.isEditable ? budgetClassification.id : null),
    ).filter(id => id !== null) || [];

    const expandCategory = () => {
        if (!expandedCategories.subcategories.includes(subcategory.id)) {
            updateExpandedCategories(null, subcategory.id);
        }
    };

    const createBudgetLine = () => {
        const postBody = {
            budgetLineNickname: DEFAULT_BUDGET_LINE_NAME,
            parentId: subcategory.id,
        };
        handleCreateBudgetClassification(postBody, expandCategory);
    };

    const handleUpdateSubcategoryName = () => {
        if (subcategory.description !== subcategoryName) {
            handleUpdateBudgetClassifications([{ id: subcategory.id, description: subcategoryName }]);
        }
    };

    const handleBudgetLineUpdate = (event: React.KeyboardEvent<HTMLInputElement> | null) => {
        if (!event) handleUpdateSubcategoryName();
        else if (event.key === 'Enter') handleUpdateSubcategoryName();
        else if (event.key === 'Escape') setSubcategoryName(subcategory.description || '');
    };

    const deleteSubcategory = () => {
        handleDeleteBudgetClassification([subcategory.id], false, toggleDeleteSubcategoryModal);
    };

    const selectedUnitTotal = subcategory[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]];
    const hasNoSelectedCategories = Object.values(selectedCategories).every(categoryLevel => !categoryLevel.length);

    return (
        <>
            <tr
                className="NSTable__tbody__tr NSTable__tbody__tr--level-2"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <td
                    className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border NSTable__tbody__tr__td__input text-dark
                    d-flex justify-content-between align-items-center flex-nowrap NSTable__tbody__tr__td--width-400"
                >
                    <div className="d-flex align-items-center">
                        <NSCheckbox
                            id={`selectSubcategory-${subcategory.id}`}
                            checked={selectedCategories.subcategories.includes(subcategory.id)}
                            callback={() => updateSelectedCategories(null, [subcategory.id], editableBudgetClassificationIds as number[])}
                            containerClassName="custom-control-inline align-middle"
                        />
                        <div className="d-flex align-items-center">
                            <RenderIf isTrue={Boolean(subcategory.children?.length)}>
                                <FontAwesomeIcon
                                    className="icon ml-1 mr-2 ReadOnlyWrapper--enable-pointer-events"
                                    role="presentation"
                                    onClick={() => updateExpandedCategories(null, subcategory.id)}
                                    icon={expandedCategories.subcategories.includes(subcategory.id) ? faCaretUp : faCaretDown}
                                    fixedWidth
                                />
                            </RenderIf>
                            <NSCellInput
                                value={subcategoryName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubcategoryName(e.target.value)}
                                onBlur={handleUpdateSubcategoryName}
                                placeholder="Enter subcategory name"
                                customClassName={subcategory.children?.length ? '' : 'ml-4'}
                                onEnter={handleBudgetLineUpdate}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row flex-nowrap">
                        <RenderIf isTrue={hasNoSelectedCategories}>
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => isHovered && toggleDeleteSubcategoryModal()}
                                className={classNames('text-muted cursor--pointer', { invisible: !isHovered })}
                            />
                        </RenderIf>

                        <RenderIf isTrue={isHovered}>
                            <RenderIf isTrue={isBudgetClassificationLoading}>
                                <span className="spinner-border spinner-border-sm text-muted ml-1" style={{ height: '15px', width: '15px' }} />
                            </RenderIf>
                            <RenderIf isTrue={!isBudgetClassificationLoading}>
                                <FontAwesomeIcon icon={faPlus} onClick={createBudgetLine} className={classNames('pl-2 text-muted cursor--pointer')} />
                            </RenderIf>
                        </RenderIf>
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {selectedUnitTotal !== null ? numeralFormatterCurrency(selectedUnitTotal) : '—'}
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td--width-100">
                    <div className="d-flex justify-content-end text-dark">
                        {subcategory.amount !== null ? numeralFormatterCurrency(subcategory.amount) : '—'}
                    </div>
                </td>
            </tr>

            <RenderIf isTrue={expandedCategories.subcategories.includes(subcategory.id)}>
                {(subcategory.children || []).map(budgetClassification => (
                    <UsesTableBudgetLine key={budgetClassification.id} budgetClassification={budgetClassification} unitType={unitType} />
                ))}
            </RenderIf>

            <RenderIf isTrue={openDeleteSubcategoryModal}>
                <ConfirmationModal
                    toggle={toggleDeleteSubcategoryModal}
                    isOpen={openDeleteSubcategoryModal}
                    onConfirm={deleteSubcategory}
                    onCancel={toggleDeleteSubcategoryModal}
                    modalHeaderText={`Delete ${subcategory.description}`}
                    modalFooterButtonText="Yes, delete"
                    modalFooterButtonIsLoading={isBudgetClassificationDeletionLoading}
                    danger
                >
                    <div className="text-dark font-weight-bold mb-1">
                        Are you sure you want to delete
                        {subcategory.description}
                        ?
                    </div>
                    <div>
                        Your work will not be able to be recovered. Any child content within
                        {subcategory.description}
                        {' '}
                        will also be deleted.
                    </div>
                </ConfirmationModal>
            </RenderIf>
        </>
    );
};

export default UsesTableSubcategory;
