import React, { useEffect, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { useAuthContext } from 'contexts/AuthContext';
import {
    NSButton, NSDropdown, NSInput, NSLabel,
} from 'bricks';
import NSModal, { INSModalProps } from 'bricks/NSModal/NSModal';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { BOESlideoutFormValues } from '../SlideoutForm/types';
import { useGetDeals } from '../../../../Deals/hooks/useFetchDeals';

export interface IImportUsesModalProps extends INSModalProps {
    dealId?: number;
    onConfirm: () => void;
    onCancel: () => void;
    setFieldValue?: FormikHelpers<BOESlideoutFormValues>['setFieldValue'];
    selectedScenarioName?: string;
}

const SaveModal = ({
    onConfirm, onCancel, setFieldValue, children, dealId, selectedScenarioName = '', ...props
}: IImportUsesModalProps) => {
    const { selectedOrganizationId } = useAuthContext();

    const [scenarioName, setScenarioName] = useState<string>(selectedScenarioName);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(undefined);

    // TODO If dropdownDeals is more than 100, current dropdown select will no longer be useful.
    // TODO Better to swap with a Input field with dropdown suggestion filter
    const { data: dropdownDeals } = useGetDeals({ pageSize: 100, currentPage: 1, orgId: selectedOrganizationId! });
    const queryClient = useQueryClient();

    const mappedDeals = dropdownDeals?.items.map(item => ({
        value: item.id,
        label: item.dealName,
    }));

    const handleCancel = () => {
        onCancel();
        setSelectedScenario(undefined);
        setScenarioName('');
    };

    const handleSave = () => {
        const selectedDeal = mappedDeals?.find(deal => deal.value === selectedScenario?.value);
        if (setFieldValue) {
            setFieldValue('name', scenarioName || selectedScenarioName);
            setFieldValue('dealId', selectedDeal?.value);
        }

        onConfirm();
        setScenarioName('');
        queryClient.invalidateQueries({ queryKey: ['deals', selectedOrganizationId!] });
        onCancel();
    };

    useEffect(() => {
        if (!props.isOpen) {
            setScenarioName('');
        } else {
            setScenarioName(selectedScenarioName || '');
        }
    }, [props.isOpen, selectedScenarioName]);

    return (
        <NSModal
            {...props}
            modalHeaderText="Save scenario"
            modalHeaderClassName="bg-primary text-white"
            modalFooterButtonText="Save to deal"
            modalFooterCancelButtonText="Cancel"
            modalFooterButtonColor="primary"
            modalFooterCancelButtonFunction={handleCancel}
            modalFooterButtonFunction={handleSave}
            customFooter={
                selectedScenario ? undefined : (
                    <div>
                        <NSButton text="Cancel" callback={handleCancel} outline />
                        <NSButton className="mx-2" color="secondary" text="Save" callback={handleSave} />
                        <NSButton color="primary" text="Create new deal from scenario" callback={handleSave} />
                    </div>
                )
            }
        >
            <NSInput
                id="scenarioName"
                type="text"
                label="Scenario name"
                name="scenarioName"
                placeholder="Enter name"
                value={scenarioName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScenarioName(e.target.value)}
                inputClassName="mb-2"
                required
            />
            <NSLabel>Save to deal</NSLabel>
            <NSDropdown
                options={mappedDeals || []}
                selectedOption={mappedDeals?.find(deal => deal.value === dealId) || undefined}
                handleSelectOption={option => setSelectedScenario(option)}
                placeholder="Select deal"
                isFullWidth
            />
            <RenderIf isTrue={!selectedScenario}>
                <small>If no deal is selected, this scenario will be saved as Uncategorized.</small>
            </RenderIf>
        </NSModal>
    );
};

export default SaveModal;
