import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const BASE_VALUATION_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Value', format: 'none', isReadOnly: true,
    },
    {
        key: 'untrended', field: 'untrended', header: '', format: 'none', isReadOnly: true,
    },
    {
        key: 'trended', field: 'trended', header: '', format: 'none', isReadOnly: true,
    },
    {
        key: 'sale', field: 'sale', header: '', format: 'none', isReadOnly: true,
    },
];

export const BASE_STABILIZATION_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Stabilized value', format: 'none', isReadOnly: true,
    },
    {
        key: 'untrended', field: 'untrended', header: '', format: 'none', isReadOnly: true,
    },
    {
        key: 'trended', field: 'trended', header: '', format: 'none', isReadOnly: true,
    },
    {
        key: 'sale', field: 'sale', header: '', format: 'none', isReadOnly: true,
    },
];

export const VALUATION_ROWS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'noi',
        title: 'Net operating income',
        untrended: '4131231',
        trended: '4131231',
        sale: '4131231',
    },
    {
        id: 'capRate',
        title: 'Cap rate',
        untrended: '5',
        trended: '5',
        sale: '5',
    },
];
