import * as Yup from 'yup';

export const defaultValues = {
    dealName: '',
    address: '',
    city: '',
    county: '',
    state: '',
    zipCode: '',
    description: '',
    acquisitionPrice: '',
    assetTypeId: 'default',
    constructionTypeId: 'default',
    marketId: 'default',
    dealTypeId: -1,
    subType: 'default',
    has_subtype: false,
    latitude: null,
    longitude: null,
    coordinatesSwitch: false,
};

// TODO get from API
export const dealTypeOptions = [
    { label: 'Acquisition', value: 1 },
    { label: 'Development', value: 2 },
];

export const CreateDealSchema = Yup.object().shape({
    dealName: Yup.string().required('Deal name is required'),
    address: Yup.string().when('coordinatesSwitch', {
        is: true,
        then: () => Yup.string().notRequired(),
        otherwise: () => Yup.string().required('Address is required'),
    }),
    city: Yup.string().when('coordinatesSwitch', {
        is: true,
        then: () => Yup.string().notRequired(),
        otherwise: () => Yup.string().required('City is required'),
    }),
    county: Yup.string().when('coordinatesSwitch', {
        is: true,
        then: () => Yup.string().notRequired(),
        otherwise: () => Yup.string().required('County is required'),
    }),
    state: Yup.string().when('coordinatesSwitch', {
        is: true,
        then: () => Yup.string().notRequired(),
        otherwise: () => Yup.string().required('State is required'),
    }),
    zipCode: Yup.string().when('coordinatesSwitch', {
        is: true,
        then: () => Yup.string().notRequired(),
        otherwise: () => Yup.string()
            .required('Zip is required')
            .matches(/^[a-zA-Z0-9]+$/, 'Zip must contain only letters and numbers')
            .min(5, 'Zip must be at least 5 characters')
            .max(10, 'Zip must be at most 10 characters'),
    }),
    latitude: Yup.number().when('coordinatesSwitch', {
        is: true,
        then: schema => schema.required('Latitude is required'),
        otherwise: schema => schema.notRequired(),
    }),
    longitude: Yup.number().when('coordinatesSwitch', {
        is: true,
        then: schema => schema.required('Longitude is required'),
        otherwise: schema => schema.notRequired(),
    }),
    dealTypeId: Yup.number().notOneOf([-1], 'Please select one'),
    assetTypeId: Yup.string().notOneOf(['default', ''], 'Please select one'),
    constructionTypeId: Yup.string().notOneOf(['default', ''], 'Please select one'),
    subType: Yup.string().when('has_subtype', {
        is: true,
        then: schema => schema.notOneOf(['default', ''], 'Please select one'),
        otherwise: schema => schema.optional(),
    }),
    marketId: Yup.string().notOneOf(['default', ''], 'Please select one'),
    acquisitionPrice: Yup.number().required('Acquisition price is required').positive('Must be a positive value'),
    description: Yup.string().optional(),
    coordinatesSwitch: Yup.boolean(),
});
