import React, { useState } from 'react';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { BASE_VALUATION_COLUMNS, BASE_STABILIZATION_COLUMNS, VALUATION_ROWS_INITIAL_STATE } from './constants';
import './ProFormaValuation.scss';
import { getStabilizationColumnHeaderText, getValuationColumnHeaderText } from './helpers';

const ProFormaValuation = () => {
    const [valuationRows, setValuationRows] = useState<ProFormaGenericRow[]>(VALUATION_ROWS_INITIAL_STATE);

    const valuationColumns = [
        ...BASE_VALUATION_COLUMNS.map(col => {
            if (['untrended', 'trended', 'sale'].includes(col.key)) {
                return {
                    ...col,
                    header: (
                        <div className="ProFormaForecastTable__header">
                            <div>{getValuationColumnHeaderText(col.key).title}</div>
                            <small className="text-muted">{getValuationColumnHeaderText(col.key).subtitle}</small>
                        </div>
                    ),
                };
            }
            return col;
        }),
    ];

    const stabilizationColumns = [
        ...BASE_STABILIZATION_COLUMNS.map(col => {
            if (['untrended', 'trended', 'sale'].includes(col.key)) {
                return {
                    ...col,
                    header: (
                        <div className="ProFormaValuation__header">
                            <div>{getStabilizationColumnHeaderText(col.key).title}</div>
                            <small className="text-muted">{getStabilizationColumnHeaderText(col.key).subtitlePrefix}</small>
                            <small>{getStabilizationColumnHeaderText(col.key).subtitle}</small>
                        </div>
                    ),
                };
            }
            return col;
        }),
    ];

    return (
        <div className="d-flex flex-column p-3">
            <ProFormaGenericTable columns={valuationColumns} rows={valuationRows} setRows={setValuationRows} hideAddButton hideTotalFooter />
            <ProFormaGenericTable columns={stabilizationColumns} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
        </div>
    );
};

export default ProFormaValuation;
