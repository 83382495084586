import { Section } from './types';

export const DEFAULT_CELL_WIDTH = 120;

export const returnsSections: Section[] = [
    {
        id: 'property-returns-section',
        title: 'Property returns',
        columns: ['ROI', 'EMx', 'IRR'],
        rows: [
            {
                id: 'unlevered-row',
                label: 'Unlevered',
                values: [
                    { value: '67.9', format: 'percentage' },
                    { value: '1.67', format: 'none', label: 'X' },
                    { value: '19.29', format: 'percentage' },
                ],
            },
            {
                id: 'levered-row',
                label: 'Levered',
                values: [
                    { value: '119.20', format: 'percentage' },
                    { value: '2.19', format: 'none', label: 'X' },
                    { value: '27.60', format: 'percentage' },
                ],
            },
        ],
    },
    {
        id: 'partnership-returns-section',
        title: 'Partnership returns',
        columns: ['ROI', 'EMx', 'IRR'],
        rows: [
            {
                id: 'limited-partner-row',
                label: 'Limited Partner',
                values: [
                    { value: '89.00', format: 'percentage' },
                    { value: '1.89', format: 'none', label: 'X' },
                    { value: '21.86', format: 'percentage' },
                ],
            },
            {
                id: 'general-partner-row',
                label: 'General Partner',
                values: [
                    { value: '391.10', format: 'percentage' },
                    { value: '4.91', format: 'none', label: 'X' },
                    { value: '63.83', format: 'percentage' },
                ],
            },
        ],
    },
    {
        id: 'development-returns-section',
        title: 'Development returns',
        columns: ['Untrended', 'Trended', 'Sale'],
        rows: [
            {
                id: 'yield-on-cost-row',
                label: 'Yield-on-cost',
                values: [
                    { value: '8.06', format: 'percentage' },
                    { value: '8.19', format: 'percentage' },
                    { value: '8.43', format: 'percentage' },
                ],
            },
            {
                id: 'market-cap-rate-row',
                label: 'Market cap rate',
                values: [
                    { value: '5.25', format: 'percentage' },
                    { value: '5.62', format: 'percentage' },
                    { value: '5.75', format: 'percentage' },
                ],
            },
            {
                id: 'development-spread-row',
                label: 'Development spread',
                values: [
                    { value: '281.4', format: 'none', label: ' bps' },
                    { value: '285.4', format: 'none', label: ' bps' },
                    { value: '275.4', format: 'none', label: ' bps' },
                ],
            },
        ],
    },
];

export const operationsSections: Section[] = [
    {
        id: 'cash-flows-section',
        title: 'Cash flows',
        columns: ['Untrended', 'Trended', 'Sale'],
        rows: [
            {
                id: 'effective-gross-revenue',
                label: 'Effective gross revenue',
                values: [
                    { value: '6819016', format: 'currency' },
                    { value: '6974635', format: 'currency' },
                    { value: '7183352', format: 'currency' },
                ],
            },
            {
                id: 'operating-expenses',
                label: 'Operating expenses',
                values: [
                    { value: '2697047', format: 'currency', isNegative: true },
                    { value: '2697047', format: 'currency', isNegative: true },
                    { value: '2697047', format: 'currency', isNegative: true },
                ],
            },
        ],
    },
    {
        id: 'net-operating-income-section',
        rows: [
            {
                id: 'net-operating-income',
                label: 'Net operating income',
                values: [
                    { value: '4121968', format: 'currency' },
                    { value: '4185309', format: 'currency' },
                    { value: '4310361', format: 'currency' },
                ],
            },
            {
                id: 'capital-expenditures',
                label: 'Capital expenditures',
                values: [
                    { value: '25000', format: 'currency', isNegative: true },
                    { value: '25879', format: 'currency', isNegative: true },
                    { value: '26655', format: 'currency', isNegative: true },
                ],
            },
        ],
    },
    {
        id: 'cash-flow-from-operations-section',
        rows: [
            {
                id: 'cash-flow-operations',
                label: 'Cash flow from operations',
                values: [
                    { value: '4096968', format: 'currency' },
                    { value: '4159430', format: 'currency' },
                    { value: '4283706', format: 'currency' },
                ],
            },
            {
                id: 'cap-rate',
                label: 'Cap rate',
                values: [
                    { value: '5.25', format: 'percentage' },
                    { value: '5.62', format: 'percentage' },
                    { value: '5.75', format: 'percentage' },
                ],
            },
            {
                id: 'valuation',
                label: 'Valuation',
                values: [
                    { value: '78513684', format: 'currency' },
                    { value: '74515886', format: 'currency' },
                    { value: '74962801', format: 'currency' },
                ],
            },
            {
                id: 'total-project-cost',
                label: '% of total project cost',
                values: [
                    { value: '153.6', format: 'percentage' },
                    { value: '145.8', format: 'percentage' },
                    { value: '146.7', format: 'percentage' },
                ],
            },
            {
                id: 'valuation-unit',
                label: 'Valuation / unit',
                values: [
                    { value: '261712', format: 'currency' },
                    { value: '248386', format: 'currency' },
                    { value: '249876', format: 'currency' },
                ],
            },
        ],
    },
];

export const developmentSections: Section[] = [
    {
        id: 'uses',
        title: 'Uses',
        columns: ['%', '$ / Built SF', '$ / Unit', 'Amount'],
        rows: [
            {
                id: 'land-costs',
                label: 'Land costs',
                values: [
                    { value: '0', format: 'percentage' },
                    { value: '0', format: 'currency' },
                    { value: '-', format: 'currency' },
                    { value: '-', format: 'currency' },
                ],
            },
            {
                id: 'hard-costs',
                label: 'Hard costs',
                values: [
                    { value: '83.40', format: 'percentage' },
                    { value: '132.83', format: 'currency' },
                    { value: '142100', format: 'currency' },
                    { value: '42630000', format: 'currency' },
                ],
            },
            {
                id: 'soft-costs',
                label: 'Soft costs',
                values: [
                    { value: '9.50', format: 'percentage' },
                    { value: '15.17', format: 'currency' },
                    { value: '16230', format: 'currency' },
                    { value: '4868862', format: 'currency' },
                ],
            },
            {
                id: 'carry-costs',
                label: 'Carry costs',
                values: [
                    { value: '7.10', format: 'percentage' },
                    { value: '11.27', format: 'currency' },
                    { value: '12053', format: 'currency' },
                    { value: '3615906', format: 'currency' },
                ],
            },
        ],
    },
    {
        id: 'uses-total',
        isTotal: true,
        rows: [
            {
                id: 'total-uses',
                label: 'Total uses',
                values: [
                    { value: '100', format: 'percentage' },
                    { value: '159.27', format: 'currency' },
                    { value: '170383', format: 'currency' },
                    { value: '5114768', format: 'currency' },
                ],
            },
        ],
    },
    {
        id: 'sources',
        title: 'Sources',
        rows: [
            {
                id: 'contruction-loan',
                label: 'Construcion loan',
                values: [
                    { value: '60', format: 'percentage' },
                    { value: '102310', format: 'currency' },
                    { value: '102310', format: 'currency' },
                    { value: '30693015', format: 'currency' },
                ],
            },
            {
                id: 'mezzanine-loan',
                label: 'Mezzanine loan',
                values: [
                    { value: '0', format: 'percentage' },
                    { value: '-', format: 'currency' },
                    { value: '-', format: 'currency' },
                    { value: '-', format: 'currency' },
                ],
            },
            {
                id: 'limited-partner',
                label: 'Limited Partner',
                values: [
                    { value: '36', format: 'percentage' },
                    { value: '61265', format: 'currency' },
                    { value: '61265', format: 'currency' },
                    { value: '2042175', format: 'currency' },
                ],
            },
            {
                id: 'general-partner',
                label: 'General Partner',
                values: [
                    { value: '4', format: 'percentage' },
                    { value: '6807', format: 'currency' },
                    { value: '6807', format: 'currency' },
                    { value: '2042175', format: 'currency' },
                ],
            },
        ],
    },
    {
        id: 'sources-total',
        isTotal: true,
        rows: [
            {
                id: 'total-sources',
                label: 'Total sources',
                values: [
                    { value: '100', format: 'percentage' },
                    { value: '159.27', format: 'currency' },
                    { value: '170383', format: 'currency' },
                    { value: '51114768', format: 'currency' },
                ],
            },
        ],
    },
];
