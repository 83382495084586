import React, { useState } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSButton } from 'bricks';
import { StepWizardChildProps } from 'react-step-wizard';
import { CREATE_PRO_FORMA_STEP2_CARDS, CREATE_PRO_FORMA_STEPS } from 'views/CreateProFormaModal/constants';
import { generatePath, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import ProFormaStep2OptionCard from './ProFormaStep2OptionCard/ProFormaStep2OptionCard';
import { useCreateProForma } from '../../../../queries/ProForma';

export interface ICreateProFormaStep2Props extends Partial<StepWizardChildProps> {
    toggle: () => void;
    dealId: number;
}

const CreateProFormaStep2 = ({ goToNamedStep, toggle, dealId }: ICreateProFormaStep2Props) => {
    const navigate = useNavigate();
    const [stepSelection, setStepSelection] = useState<string>(CREATE_PRO_FORMA_STEP2_CARDS[0].name);
    const { mutate: createProForma } = useCreateProForma();

    const goBackToInitialStep = () => {
        if (goToNamedStep) {
            goToNamedStep(CREATE_PRO_FORMA_STEPS.STEP_1);
        }
    };

    const handleRedirect = () => {
        createProForma(
            { dealId },
            {
                onSuccess: data => {
                    const isWizardFlow = stepSelection === CREATE_PRO_FORMA_STEP2_CARDS[0].name;
                    const path = generatePath(isWizardFlow ? URLS.PRO_FORMA.WIZARD : URLS.PRO_FORMA.TABLE, {
                        dealId: dealId.toString(),
                        proFormaId: data.id.toString(),
                    });
                    navigate(path);
                },
                onError: error => {
                    console.error('Error creating pro forma:', error);
                },
            },
        );
    };

    return (
        <>
            <div className="d-flex text-primary cursor--pointer mb-2" role="presentation" onClick={goBackToInitialStep}>
                <FontAwesomeIcon icon={faChevronLeft} className="mr-1 my-auto cursor--pointer" />
                <span className="my-auto cursor--pointer">Back</span>
            </div>
            {CREATE_PRO_FORMA_STEP2_CARDS.map(card => (
                <ProFormaStep2OptionCard key={card.name} stepSelection={stepSelection} setStepSelection={setStepSelection} card={card} />
            ))}

            <hr />

            <div className="d-flex float-right my-0">
                <NSButton outline callback={toggle} text="Cancel" className="btn-secondary mr-2" disabled={false} />
                <NSButton outline={false} callback={handleRedirect} className="btn-primary btn-block" text="Create" />
            </div>
        </>
    );
};

export default CreateProFormaStep2;
