import React, {
    Dispatch, SetStateAction, useState, useEffect,
} from 'react';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';

import {
    NSButton, NSInput, NSLabel, NSSelect, NSCard, NSInputNumberStepper,
} from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IOptionAnyValue } from 'bricks/types';
import {
    FORM_ROW_CLASS_NAME,
    FORM_LABEL_CLASS_NAME,
    proFormaModelParkingOptions,
    IN_PROGRESS_TEXT,
    mockProFormaUnits,
    MockRentableSquareFootage,
    STRUCTURED_PARKING,
    SURFACE_PARKING,
    parkingOptions,
} from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { numeralFormatter } from 'ns_libs/formatter';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';
import { getProFormaApartmentDisplayCards } from 'views/ProFormaTable/helpers';
import { IProFormaValues } from 'views/ProFormaTable/types';
import CalculateSizeCard from '../CalculateSizeCard/CalculateSizeCard';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

export interface IApartmentSizeDetailsProps {
    values: IProFormaValues;
    setValues: Dispatch<SetStateAction<any>>;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (name: string, option: string | IOptionAnyValue) => void;
}

const ApartmentSizeDetails = ({
    values, setValues, handleInputChange, handleSelect,
}: IApartmentSizeDetailsProps) => {
    const {
        handleCreateBuildableLot,
        handleDeleteBuildableLot,
        handleUpdateBuildableLot,
        buildableLots,
        handleUpdateProForma,
        proForma,
    } = useProFormaSocketContext();

    const hasGrossBuildableLots = Boolean(buildableLots.grossBuildableAreaLots.length);

    const [selectedParkingOptions, setSelectedParkingOptions] = useState<IOptionAnyValue[]>([]);
    const [isFar, setIsFar] = useState(!hasGrossBuildableLots && proForma.grossBuildableArea === null);
    const [showLots, setShowLots] = useState(hasGrossBuildableLots);

    const toggleShowLots = () => setShowLots(!showLots);

    const displayCards = getProFormaApartmentDisplayCards(values, mockProFormaUnits, MockRentableSquareFootage, isFar);

    const updateProFormaParkingTypes = (parkingTypes: IOptionAnyValue[], event: any) => {
        const isStructuredParkingSelected = parkingTypes.find(type => type.value === STRUCTURED_PARKING);
        const isSurfaceParkingSelected = parkingTypes.find(type => type.value === SURFACE_PARKING);

        const dataToUpdate = [];

        // Removing parking types
        if (!isStructuredParkingSelected) dataToUpdate.push({ key: 'structuredParkingSpaces', value: null });
        if (!isSurfaceParkingSelected) dataToUpdate.push({ key: 'surfaceParkingSpaces', value: null });

        // Adding selected parking types
        if (isStructuredParkingSelected && !values.structuredParkingSpaces) dataToUpdate.push({ key: 'structuredParkingSpaces', value: 0 });
        if (isSurfaceParkingSelected && !values.surfaceParkingSpaces) dataToUpdate.push({ key: 'surfaceParkingSpaces', value: 0 });

        handleUpdateProForma(dataToUpdate);
    };

    const handleChangeFloorAreaRatio = (value: number) => {
        handleUpdateProForma([{ key: 'floorAreaRatio', value: String(value) }]);
        setShowLots(false);
    };

    useEffect(() => {
        const initialSelectedParkingOptions = [];
        if (values.structuredParkingSpaces != null) {
            initialSelectedParkingOptions.push(parkingOptions[0]);
        }
        if (values.surfaceParkingSpaces != null) {
            initialSelectedParkingOptions.push(parkingOptions[1]);
        }
        setSelectedParkingOptions(initialSelectedParkingOptions);
    }, [values.structuredParkingSpaces, values.surfaceParkingSpaces]);

    return (
        <Row>
            <Col md={12} lg={8}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Land area</NSLabel>
                    <NSInput
                        type="number"
                        name="landArea"
                        customClassName="NSInput__appended-child--with-select"
                        value={values.landArea}
                        onChange={handleInputChange}
                        onBlur={() => handleUpdateProForma([{ key: 'landArea', value: values.landArea }])}
                        placeholder="Enter land area"
                        inputGroupClassName="p-0"
                        appendInputAddon={(
                            <NSSelect
                                name="landAreaUnitTypeId"
                                options={PRO_FORMA_SIZE_UNIT_OPTIONS}
                                value={PRO_FORMA_SIZE_UNIT_OPTIONS.find(opt => opt.value === values.landAreaUnitTypeId)}
                                onChange={option => handleSelect('landAreaUnitTypeId', option.value)}
                                onBlur={() => handleUpdateProForma([{ key: 'landAreaUnitTypeId', value: values.landAreaUnitTypeId }])}
                                isClearable={false}
                                styles={{
                                    ...customStyles,
                                    control: styles => ({
                                        ...styles,
                                        border: 'none',
                                        backgroundColor: '#47515d',
                                        width: '80px',
                                    }),
                                }}
                            />
                        )}
                    />
                </div>
                <div className={FORM_ROW_CLASS_NAME}>
                    <div className={FORM_LABEL_CLASS_NAME}>
                        <NSButton
                            color="secondary"
                            outline={false}
                            callback={() => setIsFar(true)}
                            text="FAR"
                            className={classNames('px-3', { 'border border-primary bg-primary-lighten': isFar })}
                        />
                        <NSButton
                            color="secondary"
                            outline={false}
                            callback={() => setIsFar(false)}
                            text="Gross build. area"
                            className={classNames({ 'border border-primary bg-primary-lighten': !isFar })}
                        />
                    </div>
                    <RenderIf isTrue={isFar}>
                        <NSInputNumberStepper
                            name="floorAreaRatio"
                            value={values.floorAreaRatio!}
                            onChange={handleChangeFloorAreaRatio}
                            min={0.1}
                            inputGroupClassName="w-25"
                            step={0.1}
                        />
                    </RenderIf>

                    <RenderIf isTrue={!isFar}>
                        <NSInput
                            type="number"
                            name="grossBuildableArea"
                            value={values.grossBuildableArea}
                            onChange={handleInputChange}
                            onBlur={() => handleUpdateProForma([{ key: 'grossBuildableArea', value: values.grossBuildableArea }])}
                            placeholder="Enter gross buildable area"
                            inputGroupClassName="p-0"
                            disabled={hasGrossBuildableLots}
                            appendInputAddon={<div className="px-2">ft&sup2;</div>}
                        />
                    </RenderIf>
                </div>

                <RenderIf isTrue={!isFar}>
                    <CalculateSizeCard
                        onCreateLot={handleCreateBuildableLot!}
                        onDeleteLot={handleDeleteBuildableLot!}
                        onUpdateLot={handleUpdateBuildableLot!}
                        lots={buildableLots.grossBuildableAreaLots}
                        totalGrossBuildableArea={buildableLots.totalGrossBuildableArea}
                        showLots={showLots}
                        toggleShowLots={toggleShowLots}
                    />
                </RenderIf>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Buildings</NSLabel>
                    <NSInputNumberStepper
                        name="buildings"
                        value={values.buildings}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'buildings', value: String(count) }])}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>{values.buildings <= 1 ? 'Stories' : 'Avg. # of stories'}</NSLabel>
                    <NSInputNumberStepper
                        name="averageStories"
                        value={values.averageStories}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'averageStories', value: String(count) }])}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Built gross SF</NSLabel>
                    <NSInput
                        type="number"
                        name="builtGrossSquareFootage"
                        value={values.builtGrossSquareFootage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                        onBlur={() => handleUpdateProForma([{ key: 'builtGrossSquareFootage', value: values.builtGrossSquareFootage }])}
                        placeholder="Enter built gross SF"
                        inputGroupClassName="p-0"
                        appendInputAddon={<div className="px-2">ft&sup2;</div>}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Parking</NSLabel>
                    <div className="w-100">
                        <NSSelect
                            isMulti
                            name="parking"
                            options={proFormaModelParkingOptions[proForma.proFormaModelId]}
                            value={selectedParkingOptions}
                            onChange={options => setSelectedParkingOptions(options || [])}
                            onBlur={event => updateProFormaParkingTypes(selectedParkingOptions, event)}
                        />
                    </div>
                </div>

                <RenderIf isTrue={selectedParkingOptions.find(parkingOption => parkingOption.value === STRUCTURED_PARKING)}>
                    <div className={FORM_ROW_CLASS_NAME}>
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Structured parking spaces</NSLabel>
                        <NSInputNumberStepper
                            name="structuredParkingSpaces"
                            value={values.structuredParkingSpaces}
                            onChange={(count: number) => handleUpdateProForma([{ key: 'structuredParkingSpaces', value: count }])}
                            min={0.1}
                            inputGroupClassName="w-25"
                            step={1}
                        />
                    </div>
                </RenderIf>

                <RenderIf isTrue={selectedParkingOptions.find(parkingOption => parkingOption.value === SURFACE_PARKING)}>
                    <div className={FORM_ROW_CLASS_NAME}>
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Surface parking spaces</NSLabel>
                        <NSInputNumberStepper
                            name="surfaceParkingSpaces"
                            value={values.surfaceParkingSpaces}
                            onChange={(count: number) => handleUpdateProForma([{ key: 'surfaceParkingSpaces', value: count }])}
                            min={1}
                            inputGroupClassName="w-25"
                            step={1}
                        />
                    </div>
                </RenderIf>
            </Col>
            <Col md={12} lg={4}>
                <Row className="mx-n1">
                    {displayCards.map(({
                        label, value, format, valueSuffix,
                    }) => (
                        <Col className="px-1 mb-2" md={12} lg={6} key={label}>
                            <NSCard className="NSCard--level-3 p-2 h-100">
                                <div>{label}</div>
                                <RenderIf isTrue={!value}>
                                    <div className="text-dark font-italic">{IN_PROGRESS_TEXT}</div>
                                </RenderIf>
                                <RenderIf isTrue={!!value}>
                                    <div className="text-dark">
                                        {numeralFormatter(value, format)}
                                        {valueSuffix && (
                                            <span>
&nbsp;
                                                {valueSuffix}
                                            </span>
                                        )}
                                    </div>
                                </RenderIf>
                            </NSCard>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default ApartmentSizeDetails;
