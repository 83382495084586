import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const INTEREST_OPTIONS = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
];

export const DEBT_SERVICE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: '', format: 'none', isReadOnly: true,
    },
    {
        key: 'stabilizationValue', field: 'stabilizationValue', header: '% of stab. value', format: 'percentage', isReadOnly: true,
    },
    {
        key: 'costPercentage', field: 'costPercentage', header: '% of cost', format: 'percentage', isReadOnly: true,
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency',
    },
    {
        key: 'annualPayment', field: 'annualPayment', header: 'Annual payment', format: 'currency', isReadOnly: true,
    },
    // TODO: this section would be changed after connect with the socket
    {
        key: 'column1', field: 'column1', header: 'Column 1', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: 'Column 2', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: 'Column 3', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: 'Column 4', isFixed: false, isReadOnly: true,
    },
];

export const TOTAL_DEBTSERVICE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Cash flow after financing', format: 'none', isReadOnly: true,
    },
    {
        key: 'annualPayment', field: 'annualPayment', header: '$4,310,61', format: 'none', isReadOnly: true,
    },
    {
        key: 'column1', field: 'column1', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: '31,368', isFixed: false, isReadOnly: true,
    },
];

export const GENERIC_ROWS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'debtService',
        title: 'Debt service',
        stabilizationValue: '49.3',
        costPercentage: '65.7',
        amount: '383101.32',
        annualPayment: '34543.64',
        column1: '31,368',
    },
];

export const formatDateWithMonthAndYear = (date: Date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
