/* eslint-disable no-unused-vars */
export enum AggregateReportProperties {
    TOTAL_DEVELOPMENT_COST = 'Total development cost',
    TOTAL_INTEREST = 'Total interest',
    TOTAL_LOAN_DRAWS_DURING_DEVELOPMENT = 'Total loan draws during development',
    PERMANENT_LOAN_FUNDING = 'Permanent loan funding',
    CASH_FLOW_FROM_OPERATIONS = 'Cash flow from operations',
    PERMANENT_LOAN_DEBT_SERVICE = 'Permanent loan debt service',
    NET_SALE_PROCEEDS = 'Net sale proceeds',
    CONSTRUCTION_LOAN_PAYOFF = 'Construction loan payoff',
    PERMANENT_LOAN_PAYOFF = 'Permanent loan payoff',
    EQUITY_PROCEEDS_FROM_CAPITAL_EVENTS = 'Equity proceeds from capital event(s)',
    NET_UNLEVERED_CASH_FLOW = 'Net unlevered cash flow',
    NET_LEVERED_CASH_FLOW = 'Net levered cash flow',
}

export const PropertyKeyToLabelMap = {
    total_development_cost: AggregateReportProperties.TOTAL_DEVELOPMENT_COST,
    total_interest: AggregateReportProperties.TOTAL_INTEREST,
    total_loan_draws_during_development: AggregateReportProperties.TOTAL_LOAN_DRAWS_DURING_DEVELOPMENT,
    perm_loan_funding: AggregateReportProperties.PERMANENT_LOAN_FUNDING,
    cash_flow_from_operations: AggregateReportProperties.CASH_FLOW_FROM_OPERATIONS,
    perm_loan_debt_service: AggregateReportProperties.PERMANENT_LOAN_DEBT_SERVICE,
    net_sale_proceeds: AggregateReportProperties.NET_SALE_PROCEEDS,
    construction_loan_payoff: AggregateReportProperties.CONSTRUCTION_LOAN_PAYOFF,
    permanent_loan_payoff: AggregateReportProperties.PERMANENT_LOAN_PAYOFF,
    equity_proceeds_from_capital_events: AggregateReportProperties.EQUITY_PROCEEDS_FROM_CAPITAL_EVENTS,
    net_unlevered_cash_flow: AggregateReportProperties.NET_UNLEVERED_CASH_FLOW,
    net_levered_cash_flow: AggregateReportProperties.NET_LEVERED_CASH_FLOW,
};

export const PRO_FORMA_RETURN_TOOLTIPS: Partial<Record<AggregateReportProperties, string>> = {
    [AggregateReportProperties.TOTAL_DEVELOPMENT_COST]: 'Before interest reserve',
    [AggregateReportProperties.TOTAL_INTEREST]: 'For debt during the development period, before lease-up income',
};

export const CostMetricKeyToLabelMap = {
    yieldOnCost: 'Yield-on-cost',
    marketCapRate: 'Market cap rate',
    developmentSpread: 'Development spread',
};

export const spacerRows = [
    AggregateReportProperties.TOTAL_INTEREST,
    AggregateReportProperties.PERMANENT_LOAN_FUNDING,
    AggregateReportProperties.PERMANENT_LOAN_DEBT_SERVICE,
];

export enum Periods {
    MONTHLY = 'Monthly',
    QUARTERLY = 'Quarterly',
    ANNUALLY = 'Annually',
}

export const PERIOD_OPTIONS = [
    { label: Periods.MONTHLY, value: Periods.MONTHLY },
    { label: Periods.QUARTERLY, value: Periods.QUARTERLY },
    { label: Periods.ANNUALLY, value: Periods.ANNUALLY },
];
