import React, { useMemo } from 'react';
import {
    NSButton, NSCard, NSDropdown, NSInput, NSSwitch,
} from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faPercentage, faTrash } from '@fortawesome/pro-regular-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import { LOAN_CALCULATION_OPTIONS, LOAN_CALCULATION_TYPES } from 'views/DealDashboard/BOE/components/SlideoutForm/constants';
import { useGetSourceTypes } from 'queries/ProFormaSource';
import { ISourceProps } from './types';

const Source = ({
    source, traunchOptions, onChange, onRemove,
}: ISourceProps) => {
    const {
        id, name, amount, loanCalculationType, loanToCost, loanToValue, debtYield, calculate, traunch, sourceTypeId,
    } = source;
    const { data: sourceTypes } = useGetSourceTypes();

    const typeOptions = useMemo(
        () => sourceTypes?.map(type => ({
            label: type.name,
            value: type.correlationId,
        })) || [],
        [sourceTypes],
    );

    const selectedTraunch = useMemo(() => traunchOptions.find(item => item.value === traunch), [traunchOptions, traunch]);
    const selectedType = useMemo(() => typeOptions.find(item => item.value === sourceTypeId), [typeOptions, sourceTypeId]);
    const loanCalculationTypeOption = useMemo(() => {
        if (loanCalculationType) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === loanCalculationType);
        }

        if (loanToCost) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === LOAN_CALCULATION_TYPES.LTC);
        }
        if (loanToValue) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === LOAN_CALCULATION_TYPES.LTV);
        }
        if (debtYield) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === LOAN_CALCULATION_TYPES.DY);
        }

        return undefined;
    }, [loanCalculationType, loanToCost, loanToValue, debtYield]);

    const getCalculateState = useMemo(() => {
        if (typeof calculate !== 'undefined') return calculate;

        if (loanToCost || loanToValue || debtYield) return true;

        return false;
    }, [calculate, loanToCost, loanToValue, debtYield]);

    const showAmount = useMemo(() => {
        if (!getCalculateState) return true;

        if (!loanCalculationTypeOption?.value) return false;

        if (loanCalculationTypeOption.value === LOAN_CALCULATION_TYPES.LTC) return true;

        return false;
    }, [getCalculateState, loanCalculationTypeOption]);

    const handleInputChange = (id: string, name: string, value: number) => {
        const newValue = Math.min(Math.max(value, 0), 100);
        onChange(id, name, newValue);
    };

    return (
        <NSCard className="p-2 w-100 d-flex flex-row mb-1 align-items-end">
            <div className="flex-grow-0 mx-1">
                <span className="d-flex mr-2 mb-1 text-dark">Fund. traunch</span>
                <NSDropdown
                    options={traunchOptions}
                    selectedOption={selectedTraunch}
                    handleSelectOption={option => onChange(id, 'traunch', option.value)}
                    containerClassName="mr-2"
                    isFullWidth
                />
            </div>

            <div className="flex-grow-1 mx-1">
                <span className="d-flex mr-2 mb-1 text-dark">Name</span>
                <NSInput
                    id="name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(id, 'name', event.target.value)}
                />
            </div>

            <div className="flex-grow-1 mx-1">
                <div className="d-flex flex-direction-row justify-content-between">
                    <span className="mr-2 mb-1 text-dark">Type</span>
                    <RenderIf isTrue={selectedType?.label.includes('Debt')}>
                        <div>
                            <span className="mr-2 mb-1 text-dark">Calculate</span>
                            <NSSwitch
                                id={id}
                                name="calculateSwitch"
                                checked={getCalculateState}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(id, 'calculate', event.target.checked)}
                                containerClassName="text-white"
                            />
                        </div>
                    </RenderIf>
                </div>
                <NSDropdown
                    options={typeOptions}
                    selectedOption={selectedType}
                    placeholder="Select type"
                    handleSelectOption={option => onChange(id, 'sourceTypeId', option.value)}
                    containerClassName="mr-2"
                    isFullWidth
                />
            </div>

            <RenderIf isTrue={getCalculateState}>
                <div className="flex-grow-0 mx-1">
                    <NSDropdown
                        containerClassName="mb-1"
                        options={LOAN_CALCULATION_OPTIONS}
                        selectedOption={loanCalculationTypeOption}
                        handleSelectOption={option => onChange(id, 'loanCalculationType', option.value)}
                        customSelectedOptionText={loanCalculationTypeOption?.label}
                        badgeColor="dark-lighten"
                        isBadgeToggle
                    />
                    <RenderIf isTrue={loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.LTC}>
                        <NSInput
                            id="loanToCost"
                            type="number"
                            name="loanToCost"
                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                            value={loanToCost}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputChange(id, 'loanToCost', event.target.valueAsNumber);
                            }}
                        />
                    </RenderIf>
                    <RenderIf isTrue={loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.LTV}>
                        <NSInput
                            id="loanToValue"
                            type="number"
                            name="loanToValue"
                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                            value={loanToValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputChange(id, 'loanToValue', event.target.valueAsNumber);
                            }}
                        />
                    </RenderIf>
                    <RenderIf isTrue={loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.DY}>
                        <NSInput
                            id="debtYield"
                            type="number"
                            name="debtYield"
                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                            value={debtYield}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputChange(id, 'debtYield', event.target.valueAsNumber);
                            }}
                        />
                    </RenderIf>
                </div>
            </RenderIf>

            <RenderIf isTrue={showAmount}>
                <div className="flex-grow-1 mx-1">
                    <span className="d-flex mr-2 mb-1 text-dark">Amount</span>
                    <NSInput
                        disabled={getCalculateState && loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.LTC}
                        id="amount"
                        type="number"
                        name="amount"
                        placeholder="Enter amount"
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        value={amount}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(id, 'amount', event.target.value)}
                    />
                </div>
            </RenderIf>

            <NSButton icon={faTrash} color="danger" callback={() => onRemove(id)} className="ml-2" outline />
        </NSCard>
    );
};

export default Source;
