import React from 'react';
import './ReadOnlyWrapper.scss';

export type IReadOnlyWrapperProps = {
    isReadOnly?: boolean;
    children?: React.ReactNode;
};

const ReadOnlyWrapper = ({ children, isReadOnly }: IReadOnlyWrapperProps) => (
    <div className={`ReadOnlyWrapper ${isReadOnly ? 'ReadOnlyWrapper--disabled' : ''}`}>
        {children}
    </div>
);

export default ReadOnlyWrapper;
