import React, { useState } from 'react';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import './ProFormaForecastTable.scss';
import {
    INITIAL_REVENUE_RENTAL_INCOME_ROWS,
    INITIAL_REVENUE_POTENTIAL_INCOME_ROWS,
    INITIAL_EFFECTIVE_GROSS_REVENUE_ROWS,
    INITIAL_OPERATING_EXPENSES_ROWS,
    INITIAL_CAPITAL_EXPENDITURES_ROWS,
    BASE_FORECAST_COLUMNS,
    MILL_RATES,
    TOTAL_POTENTIAL_COLUMNS,
    CASH_FLOW_COLUMNS,
} from './constants';

const getColumnHeaderText = (key: string) => {
    switch (key) {
        case 'untrended':
            return { title: 'Untrended', subtitle: '(Today)' };
        case 'trended':
            return { title: 'Trended', subtitle: '(Period 33 - 44)' };
        case 'sale':
            return { title: 'Sale', subtitle: '(Period 45 - 56)' };
        default:
            return { title: '', subtitle: '' };
    }
};

const ProFormaForecastTable = () => {
    const [revenueRentalIncomeRows, setRevenueRentalIncomeRows] = useState<ProFormaGenericRow[]>(INITIAL_REVENUE_RENTAL_INCOME_ROWS);
    const [revenuePotentialIncomeRows, setRevenuePotentialIncomeRows] = useState<ProFormaGenericRow[]>(INITIAL_REVENUE_POTENTIAL_INCOME_ROWS);
    const [effectiveGrossRevenueRows, setEffectiveGrossRevenueRows] = useState<ProFormaGenericRow[]>(INITIAL_EFFECTIVE_GROSS_REVENUE_ROWS);
    const [operatingExpensesRows, setOperatingExpensesRows] = useState<ProFormaGenericRow[]>(INITIAL_OPERATING_EXPENSES_ROWS);
    const [capitalExpendituresRows, setCapitalExpendituresRows] = useState<ProFormaGenericRow[]>(INITIAL_CAPITAL_EXPENDITURES_ROWS);

    const forecastColumns: ProFormaGenericColumn[] = [
        {
            ...BASE_FORECAST_COLUMNS[0],
            customCell: {
                shouldShowCustomCell: row => row.id === 'property-taxes',
                renderCell: () => (
                    <div className="mill-rate-box ml-3">
                        <div className="d-flex align-items-center">
                            <div className="text-white mr-3">Mill rate</div>
                            <div className="mill-rate-values mr-2">
                                <small className="text-muted">Untrended</small>
                                <div className="text-white mill-rate-values">
                                    {MILL_RATES.untrended}
                                    %
                                </div>
                            </div>
                            <div className="mill-rate-values mr-2">
                                <small className="text-muted">Trended</small>
                                <div className="text-white mill-rate-values">
                                    {MILL_RATES.trended}
                                    %
                                </div>
                            </div>
                            <div className="mill-rate-values">
                                <small className="text-muted">Sale</small>
                                <div className="text-white">
                                    {MILL_RATES.sale}
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            },
        },
        ...BASE_FORECAST_COLUMNS.slice(1).map(col => {
            if (['untrended', 'trended', 'sale'].includes(col.key)) {
                return {
                    ...col,
                    header: (
                        <div className="ProFormaForecastTable__header">
                            <div>{getColumnHeaderText(col.key).title}</div>
                            <small className="text-muted">{getColumnHeaderText(col.key).subtitle}</small>
                        </div>
                    ),
                };
            }
            return col;
        }),
    ];

    return (
        <div className="p-3">
            <ProFormaGenericTable
                firstColumnWidth="460px"
                columns={forecastColumns}
                rows={revenueRentalIncomeRows}
                setRows={setRevenueRentalIncomeRows}
                hideAddButton
                totalText="Total rental income"
            />
            <ProFormaGenericTable
                firstColumnWidth="460px"
                columns={forecastColumns}
                rows={revenuePotentialIncomeRows}
                setRows={setRevenuePotentialIncomeRows}
                hideAddButton
                hideHeader
                totalText="Total potential income"
            />
            <ProFormaGenericTable
                firstColumnWidth="460px"
                columns={forecastColumns}
                rows={effectiveGrossRevenueRows}
                setRows={setEffectiveGrossRevenueRows}
                hideAddButton
                hideHeader
                totalText="Total effective gross revenue"
            />
            <div className="pb-1" />
            <ProFormaGenericTable
                firstColumnWidth="460px"
                columns={forecastColumns.map(col => (col.key === 'name' ? { ...col, header: 'Operating expenses' } : col))}
                rows={operatingExpensesRows}
                setRows={setOperatingExpensesRows}
                totalText="Total operating expenses"
                hideAddButton
            />
            <div className="pb-1" />
            <ProFormaGenericTable firstColumnWidth="460px" columns={TOTAL_POTENTIAL_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
            <div className="pb-1" />
            <ProFormaGenericTable
                firstColumnWidth="460px"
                columns={forecastColumns.map(col => (col.key === 'name' ? { ...col, header: 'Capital expenditures' } : col))}
                rows={capitalExpendituresRows}
                setRows={setCapitalExpendituresRows}
                hideAddButton
                totalText="Total capital expenditures"
            />
            <div className="ProFormaForecastTable__separator" />
            <ProFormaGenericTable firstColumnWidth="460px" columns={CASH_FLOW_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
        </div>
    );
};

export default ProFormaForecastTable;
