import React, { useState, useMemo } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import classNames from 'classnames';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import { useAuthContext } from 'contexts/AuthContext';
import { NSBadge, NSButton, NSDropdown } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { IOptionAnyValue } from 'bricks/types';
import { CREATE_PRO_FORMA_STEPS } from 'views/CreateProFormaModal/constants';
import NSRadioCard from 'components/NSRadioCard/NSRadioCard';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import ProFormaStep1StatCard from './ProFormaStep1StatCard/ProFormaStep1StatCard';

export interface ICreateProFormaStep1Props extends Partial<StepWizardChildProps> {
    toggle: () => void;
    dealId: number;
}

const CreateProFormaStep1 = ({ goToNamedStep, toggle, dealId }: ICreateProFormaStep1Props) => {
    const { selectedOrganizationId } = useAuthContext();

    const [shouldStartFromBackOfEnvelope, setShouldStartFromBackOfEnvelope] = useState(true);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(undefined);

    // Add BOE scenarios query
    const { data: scenariosData } = useGetBackOfEnvelopeScenarios({ orgId: selectedOrganizationId!, dealId });

    const dropdownOptions = useMemo(
        () => scenariosData?.items?.map(scenario => ({
            value: scenario.id,
            label: scenario.name,
            disabled: false,
            toggle: true,
        })) || [],
        [scenariosData],
    );

    const chooseStartingSelection = (startFromBoE: boolean) => {
        if (!startFromBoE) {
            setSelectedScenario(undefined);
        }
        setShouldStartFromBackOfEnvelope(startFromBoE);
    };

    const goToNextStep = () => {
        if (goToNamedStep) {
            goToNamedStep(CREATE_PRO_FORMA_STEPS.STEP_2);
        }
    };

    const shouldDisableConfirm = shouldStartFromBackOfEnvelope && !selectedScenario;

    const handleSelectScenario = (option: IOptionAnyValue) => {
        setSelectedScenario(option);
    };

    return (
        <>
            <NSRadioCard
                cardClassName={classNames('p-2 mb-2 NSCard--level-4', {
                    'border border-primary': shouldStartFromBackOfEnvelope,
                    'border border-secondary': !shouldStartFromBackOfEnvelope,
                })}
                onClick={() => chooseStartingSelection(true)}
            >
                <div className="d-flex justify-content-between">
                    <NSBadge color="primary-lighten">Recommended</NSBadge>

                    <RenderIf isTrue={shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faDotCircle} className="text-primary NSRadioCard__radio-btn--selected" />
                    </RenderIf>
                    <RenderIf isTrue={!shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faCircle} />
                    </RenderIf>
                </div>
                <div className="text-white mb-1 mt-1">Start your pro forma from an existing Back-Of-Envelope scenario</div>

                <NSDropdown
                    isFullWidth
                    options={dropdownOptions}
                    selectedOption={selectedScenario}
                    handleSelectOption={handleSelectScenario}
                    placeholder="Select scenario"
                    disableOnSingleOption={false}
                />

                <RenderIf isTrue={Boolean(selectedScenario)}>
                    <hr className="my-1" />

                    <div className="d-flex justify-content-between">
                        <ProFormaStep1StatCard cardClassName="mr-1" />
                        <ProFormaStep1StatCard cardClassName="mr-1" />
                        <ProFormaStep1StatCard />
                    </div>

                    <div className="d-flex text-primary cursor--pointer mt-1" role="presentation" onClick={() => {}}>
                        <small className="my-auto">View scenario in Back-Of-Envelope</small>
                    </div>
                </RenderIf>
            </NSRadioCard>

            <NSRadioCard
                cardClassName={classNames('p-2 mb-2 NSCard--level-4', {
                    'border border-primary': !shouldStartFromBackOfEnvelope,
                    'border border-secondary': shouldStartFromBackOfEnvelope,
                })}
                onClick={() => chooseStartingSelection(false)}
            >
                <div className="d-flex justify-content-between">
                    <div className="text-white mb-1 my-auto">Start from scratch</div>

                    <RenderIf isTrue={!shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faDotCircle} className="text-primary my-auto NSRadioCard__radio-btn--selected" />
                    </RenderIf>
                    <RenderIf isTrue={shouldStartFromBackOfEnvelope}>
                        <FontAwesomeIcon icon={faCircle} className="my-auto" />
                    </RenderIf>
                </div>

                <RenderIf isTrue={!shouldStartFromBackOfEnvelope}>
                    <div className="h6 mb-0">
                        <span className="text-warning">Note:</span>
                        {' '}
                        we recommend starting a new pro forma from a Back-Of-Envelope scenario to help reduce
                        data entry.
                    </div>
                </RenderIf>
            </NSRadioCard>

            <hr />

            <div className="d-flex float-right my-0">
                <NSButton outline callback={toggle} text="Cancel" className="btn-secondary mr-2" disabled={false} />
                <NSButton outline={false} callback={goToNextStep} className="btn-primary btn-block" text="Confirm" disabled={shouldDisableConfirm} />
            </div>
        </>
    );
};

export default CreateProFormaStep1;
