/* eslint-disable no-unused-vars */

export enum DEVELOPMENT_SOURCE_METRIC {
    DEBT_YIELD = 'debt_yield',
    LTC = 'loan_to_cost',
    LTV = 'loan_to_value',
}

export const PRO_FORMA_SOURCE_METRIC_OPTIONS = [
    {
        label: 'Debt yield',
        value: DEVELOPMENT_SOURCE_METRIC.DEBT_YIELD,
    },
    {
        label: 'LTC',
        value: DEVELOPMENT_SOURCE_METRIC.LTC,
    },
    {
        label: 'LTV',
        value: DEVELOPMENT_SOURCE_METRIC.LTV,
    },
];
