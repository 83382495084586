import { NSButton, NSCard } from 'bricks';
import React from 'react';
import { faFloppyDisk, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOptionAnyValue } from 'bricks/types';
import SelectSavedTemplateForm from './SelectSavedTemplateForm/SelectSavedTemplateForm';
import { ActiveSections, IReportBuilderReport } from '../constants';
import StartNewReportForm from './StartNewReportForm/StartNewReportForm';

export interface ICreateSectionProps {
    orgId: string;
    selectedTemplate: IOptionAnyValue | undefined;
    setSelectedTemplate: React.Dispatch<React.SetStateAction<IOptionAnyValue | undefined>>;
    newReports: Array<IReportBuilderReport>;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}

const CreateSection = ({
    orgId, selectedTemplate, setSelectedTemplate, newReports, setNewReports,
}: ICreateSectionProps) => {
    const [activeSection, setActiveSection] = React.useState<string>(ActiveSections.INITIAL);
    return (
        <NSCard className="NSCard--level-2 p-3" style={{ minHeight: '250px' }}>
            {activeSection === ActiveSections.INITIAL && (
                <>
                    <h4 className="text-white m-0 p-0">Create</h4>
                    <p className="m-0 mt-1 p-0">Choose one of your existing templates – or start from scratch!</p>
                    <hr />
                    <NSButton className="mb-2 w-100" color="primary" callback={() => setActiveSection(ActiveSections.START_NEW_REPORT)}>
                        <FontAwesomeIcon className="mr-1" icon={faPlus} />
                        Start new report
                    </NSButton>
                    <NSButton className="NSButton--secondary-lighten w-100" callback={() => setActiveSection(ActiveSections.SELECT_SAVED_TEMPLATE)}>
                        <FontAwesomeIcon className="mr-1" icon={faFloppyDisk} />
                        Select saved template
                    </NSButton>
                </>
            )}
            {activeSection === ActiveSections.SELECT_SAVED_TEMPLATE && (
                <SelectSavedTemplateForm
                    setActiveSection={setActiveSection}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    setNewReports={setNewReports}
                />
            )}
            {activeSection === ActiveSections.START_NEW_REPORT && (
                <StartNewReportForm orgId={orgId} setActiveSection={setActiveSection} newReports={newReports} setNewReports={setNewReports} />
            )}
        </NSCard>
    );
};

export default CreateSection;
