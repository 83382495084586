import React, { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from 'contexts/AuthContext';
import { ComparisonGrid } from 'components/ComparisonGrid';
import { AddToComparisonSimpleSelect } from 'components/ComparisonGrid/components';
import {
    useCreateReport, useGetComparisonReport, useUpdateReport, useDealSelectionManager,
} from 'views/Reports/hooks';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import useToast from 'hooks/useToast';
import { excelExport } from 'bricks/utils';
import { ReportHeader } from '../../ReportHeader/ReportHeader';
import {
    IComparisonReportData, ICreateReportPostData, ReportTypeId, IReportDetails, IDealSelection,
} from '../../types';
import {
    BasicMetric, CostMetrics, InvestmentMetrics, ProjectionMetrics,
} from './metrics';
import { ColumnHeader } from './ColumnHeader';
import { ComparisonReportPropertyGroup, ComparisonReportRow, MAX_DEALS_FOR_PDF_EXPORT } from './constants';
import { ExportOptions } from '../../constants';
import { formatExcelExportData } from './helpers';

export interface IProps {
    report?: IReportDetails;
    onClickBack: () => void;
    isEditing?: boolean;
}

export const ComparisonReport = ({ report, onClickBack, isEditing }: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { selectedOrganizationId } = useAuthContext();
    const { showSuccess, showError, ToastContainerElement } = useToast();

    const [isEditMode, setIsEditMode] = useState(Boolean(!report || isEditing));
    const [reportName, setReportName] = useState(report?.name || 'New Comparison Report');
    const [reportDescription, setReportDescription] = useState(report?.description || '');

    // once everything uses the deal selection manager, this can be replaced with just the provided data
    const defaultSelectedDeals = useMemo(() => {
        if (report?.deals) {
            return report.deals.map(deal => ({
                deal: {
                    id: deal.dealId,
                    dealName: deal.dealName,
                    dealType: deal.dealType,
                    defaultProFormaId: deal.proFormaId,
                    project: 'Placeholder Project',
                    projectType: 'Placeholder DealProjectType',
                },
                proFormaId: deal.proFormaId,
            }));
        }
        if (location.state?.selectedDeals) {
            const selections = location.state.selectedDeals as IDealSelection[];
            return selections.map(selection => ({
                deal: {
                    ...selection.deal,
                    defaultProFormaId: selection.proFormaId,
                },
                proFormaId: selection.proForma?.value || selection.proFormaId,
            }));
        }
        return [];
    }, [report, location.state]);

    const {
        dealOptions, selectedDeals, addSelectedDeal, removeSelectedDeal, updateSelectedProFormaId,
    } = useDealSelectionManager(
        selectedOrganizationId!,
        defaultSelectedDeals as IDealSelection[],
    );

    const { data: comparisonReportData } = useGetComparisonReport(
        {
            orgId: selectedOrganizationId!,
            proFormaIds: selectedDeals?.map(deal => deal.proFormaId),
        },
        { enabled: selectedDeals?.length > 0 },
    );

    const gridConfig = useMemo(() => {
        if (!comparisonReportData) return undefined;

        return {
            headerRowFormatter: (data: IComparisonReportData) => (
                <ColumnHeader
                    data={data}
                    removeSelectedDeal={removeSelectedDeal}
                    updateSelectedProFormaId={updateSelectedProFormaId}
                    isEditMode={isEditMode}
                />
            ),
            groups: [
                {
                    groupLabel: ComparisonReportPropertyGroup.PROPERTY_RETURNS,
                    rows: [
                        {
                            label: ComparisonReportRow.UNLEVERED,
                            formatter: (data: IComparisonReportData) => <InvestmentMetrics data={data.propertyReturns.unlevered} />,
                            isHighlighted: (data: IComparisonReportData) => data.propertyReturns.unlevered.isHighlighted || false,
                        },
                        {
                            label: ComparisonReportRow.LEVERED,
                            formatter: (data: IComparisonReportData) => <InvestmentMetrics data={data.propertyReturns.levered} />,
                            isHighlighted: (data: IComparisonReportData) => data.propertyReturns.levered.isHighlighted || false,
                        },
                    ],
                },
                {
                    groupLabel: ComparisonReportPropertyGroup.PARTNERSHIP_RETURNS,
                    rows: [
                        {
                            label: ComparisonReportRow.LIMITED_PARTNER,
                            formatter: (data: IComparisonReportData) => <InvestmentMetrics data={data.partnershipReturns.limitedPartner} />,
                            isHighlighted: (data: IComparisonReportData) => data.partnershipReturns.limitedPartner.isHighlighted || false,
                        },
                        {
                            label: ComparisonReportRow.GENERAL_PARTNER,
                            formatter: (data: IComparisonReportData) => <InvestmentMetrics data={data.partnershipReturns.generalPartner} />,
                            isHighlighted: (data: IComparisonReportData) => data.partnershipReturns.generalPartner.isHighlighted || false,
                        },
                    ],
                },
                {
                    groupLabel: ComparisonReportPropertyGroup.DEVELOPMENT_RETURNS,
                    rows: [
                        {
                            label: ComparisonReportRow.YIELD_ON_COST,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.developmentReturns.yieldOnCost} />,
                            isHighlighted: (data: IComparisonReportData) => data.developmentReturns.yieldOnCost.isHighlighted || false,
                        },
                        {
                            label: 'Market cap rate',
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.developmentReturns.marketCapRate} />,
                            isHighlighted: (data: IComparisonReportData) => data.developmentReturns.marketCapRate.isHighlighted || false,
                        },
                        {
                            label: ComparisonReportRow.DEVELOPMENT_SPREAD,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.developmentReturns.developmentSpread} />,
                            isHighlighted: (data: IComparisonReportData) => data.developmentReturns.developmentSpread.isHighlighted || false,
                        },
                    ],
                },
                {
                    groupLabel: ComparisonReportPropertyGroup.DETAILS,
                    rows: [
                        { label: ComparisonReportRow.UNITS, formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.units} /> },
                        {
                            label: ComparisonReportRow.NET_RENTABLE_SF,
                            formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.netRentableSf} />,
                        },
                        {
                            label: ComparisonReportRow.LAND_AREA,
                            formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.landArea} />,
                        },
                        {
                            label: ComparisonReportRow.BUILDINGS,
                            formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.buildings} />,
                        },
                        { label: ComparisonReportRow.HEIGHT, formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.height} /> },
                        {
                            label: ComparisonReportRow.PARKING_SPACES,
                            formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.parkingSpaces} />,
                        },
                        {
                            label: ComparisonReportRow.PARKING_RATIO,
                            formatter: (data: IComparisonReportData) => <BasicMetric value={data.details.parkingRatio} />,
                        },
                    ],
                },
                {
                    groupLabel: ComparisonReportPropertyGroup.USES,
                    rows: [
                        {
                            label: ComparisonReportRow.ACQUISITION_COSTS,
                            formatter: (data: IComparisonReportData) => <CostMetrics data={data.uses.acquisitionCosts} />,
                        },
                        { label: ComparisonReportRow.HARD_COSTS, formatter: (data: IComparisonReportData) => <CostMetrics data={data.uses.hardCosts} /> },
                        { label: ComparisonReportRow.SOFT_COSTS, formatter: (data: IComparisonReportData) => <CostMetrics data={data.uses.softCosts} /> },
                        {
                            label: ComparisonReportRow.CARRY_COSTS,
                            formatter: (data: IComparisonReportData) => <CostMetrics data={data.uses.carryCosts} />,
                        },
                        {
                            label: ComparisonReportRow.TOTAL_USES,
                            formatter: (data: IComparisonReportData) => <CostMetrics data={data.uses.totalUses} />,
                            isHighlighted: (data: IComparisonReportData) => data.uses.totalUses.isHighlighted || false,
                        },
                    ],
                },
                {
                    groupLabel: ComparisonReportPropertyGroup.INCOME,
                    rows: [
                        {
                            label: ComparisonReportRow.EFFECTIVE_GROSS_REVENUE,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.effectiveGrossRevenue} />,
                        },
                        {
                            label: ComparisonReportRow.OPERATING_EXPENSES,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.operatingExpenses} />,
                        },
                        {
                            label: ComparisonReportRow.NET_OPERATING_INCOME,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.netOperatingIncome} />,
                            isHighlighted: (data: IComparisonReportData) => data.income.netOperatingIncome.isHighlighted || false,
                        },
                        {
                            label: ComparisonReportRow.CAPITAL_EXPENDITURES,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.capitalExpenditures} />,
                        },
                        {
                            label: ComparisonReportRow.CASH_FLOW_FROM_OPERATIONS,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.cashFlowFromOperations} />,
                            isHighlighted: (data: IComparisonReportData) => data.income.cashFlowFromOperations.isHighlighted || false,
                        },
                        {
                            label: ComparisonReportRow.CAP_RATE,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.capRate} />,
                        },
                        {
                            label: ComparisonReportRow.VALUATION,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.valuation} />,
                        },
                        {
                            label: ComparisonReportRow.PERCENT_OF_TOTAL_PROJECT_COST,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.percentOfTotalProjectCost} />,
                        },
                        {
                            label: ComparisonReportRow.VALUATION_PER_UNIT,
                            formatter: (data: IComparisonReportData) => <ProjectionMetrics data={data.income.valuationPerUnit} />,
                        },
                    ],
                },
            ],
            data: comparisonReportData,
        };
    }, [comparisonReportData, isEditMode]);

    const { mutate: createReport } = useCreateReport();
    const { mutate: updateReport } = useUpdateReport();

    const handleSaveReport = () => {
        const postData: ICreateReportPostData = {
            name: reportName,
            description: reportDescription,
            type: ReportTypeId.Comparison,
            deals: selectedDeals?.map(selection => ({
                dealId: selection.deal.id,
                proFormaId: selection.proFormaId,
            })),
        };
        if (report) {
            updateReport(
                {
                    postData: {
                        report_id: report.id,
                        ...postData,
                    },
                    orgId: selectedOrganizationId!,
                },
                {
                    onSuccess: data => {
                        queryClient.invalidateQueries({ queryKey: ['reportDetails', data.id] });
                        showSuccess('Report updated successfully');
                        setIsEditMode(false);
                    },
                    onError: () => {
                        showError('Failed to update report. Please try again.');
                    },
                },
            );
        } else {
            createReport(
                {
                    postData,
                    orgId: selectedOrganizationId!,
                },
                {
                    onSuccess: data => {
                        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId: data.id });
                        showSuccess('Report created successfully');
                        navigate(path);
                    },
                    onError: () => {
                        showError('Failed to create report. Please try again.');
                    },
                },
            );
        }
    };

    const exportOptions = useMemo(() => [
        {
            label: ExportOptions.PDF,
            value: ExportOptions.PDF,
            disabled: selectedDeals?.length > MAX_DEALS_FOR_PDF_EXPORT,
            disabledTooltip: `A maximum of ${MAX_DEALS_FOR_PDF_EXPORT} deals can be exported`,
        },
        { label: ExportOptions.EXCEL, value: ExportOptions.EXCEL },
    ], [selectedDeals?.length]);

    const onClickExport = () => {
        if (report && comparisonReportData) {
            const formattedExcelExportData = formatExcelExportData(report.name, report.description, comparisonReportData!);
            excelExport(report.name, false, formattedExcelExportData, true, []);
        }
    };

    return (
        <div data-testid="comparison-report-container">
            <ReportHeader
                reportId={report?.id}
                reportName={reportName}
                reportDescription={reportDescription}
                setReportName={setReportName}
                setReportDescription={setReportDescription}
                onClickBack={onClickBack}
                onClickExport={onClickExport}
                onClickSave={handleSaveReport}
                onClickEdit={() => setIsEditMode(true)}
                isEditing={isEditMode}
                exportOptions={exportOptions}
            />
            <ComparisonGrid
                maxHeight="78vh"
                gridConfig={gridConfig}
                isEditMode={isEditMode}
                renderAddToComparison={() => (
                    <AddToComparisonSimpleSelect
                        label="Add to comparison"
                        placehoder="Select a deal"
                        options={dealOptions}
                        onSelectOption={addSelectedDeal}
                    />
                )}
            />
            {ToastContainerElement}
        </div>
    );
};

export default ComparisonReport;
