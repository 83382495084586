import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    NSButton, NSCard, NSInput, NSLabel,
} from 'bricks';
import {
    faClone, faTrash, faDollar, faPercentage,
} from '@fortawesome/free-solid-svg-icons';
import './TenantsOverviewCard.scss';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'react-datepicker';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { ITenant } from '../../types';

interface TenantsOverviewCardProps {
    values: ITenant;
    onDuplicate: () => void;
    onDelete: () => void;
    onUpdate: (updatedValues: TenantsOverviewCardProps['values']) => void;
    count?: number;
}

const TenantsOverviewCard = ({
    values, onDuplicate, onDelete, onUpdate, count,
}: TenantsOverviewCardProps) => {
    const [leaseStartDatePicker, setLeaseStartDatePicker] = useState(false);
    const [leaseStartDate, setLeaseStartDate] = useState<Date | undefined>(undefined);
    const [rentStartDatePicker, setRentStartDatePicker] = useState(false);
    const [rentStartDate, setRentStartDate] = useState<Date | undefined>(undefined);
    const [recoveryStartDatePicker, setRecoveryStartDatePicker] = useState(false);
    const [recoveryStartDate, setRecoveryStartDate] = useState<Date | undefined>(undefined);

    const [units, setUnits] = useState<number>(values.units || 0);
    const [averageSquareFootage, setAverageSquareFootage] = useState<number>(values.averageSquareFootage || 0);
    const [rentGrowth, setRentGrowth] = useState<number>(values.rentGrowth || 0);
    const [rentSfYear, setRentSfYear] = useState<number>(values.rentSfYear || 0);
    const [proRata, setProRata] = useState<number>(values.proRata || 0);
    const [recoveryIncome, setRecoveryIncome] = useState<number>(values.recoveryIncome || 0);
    const [editingName, setEditingName] = useState(false);
    const [editName, setEditName] = useState(values.name || `Tenant ${count}`);

    const formatDate = (date: Date) => date.toLocaleDateString();

    useEffect(() => {
        onUpdate({
            id: values.id,
            name: editName,
            units,
            averageSquareFootage,
            rentGrowth,
            rentSfYear,
            proRata,
            recoveryIncome,
            totalRent: averageSquareFootage * rentSfYear,
            leaseStartDate,
            rentStartDate,
            recoveryStartDate,
        });
    }, [editName, units, averageSquareFootage, rentGrowth, rentSfYear, proRata, recoveryIncome, leaseStartDate, rentStartDate, recoveryStartDate]);

    return (
        <NSCard className="NSCard--level-4 mb-2 p-2">
            <div className="d-flex justify-content-between">
                {editingName ? (
                    <input
                        type="text"
                        value={editName}
                        onChange={e => setEditName(e.target.value)}
                        onBlur={() => setEditingName(false)}
                        onKeyDown={e => e.key === 'Enter' && setEditingName(false)}
                        className="form-control p-1 w-50"
                    />
                ) : (
                    <div
                        className="TenantsOverviewCard__title cursor--pointer"
                        onClick={() => setEditingName(true)}
                        onKeyDown={e => e.key === 'Enter' && setEditingName(true)}
                        role="button"
                        tabIndex={0}
                    >
                        {editName || 'Default name'}
                    </div>
                )}
                <div>
                    <NSButton className="p-0 mr-2 TenantsOverviewCard__noOutline" callback={onDuplicate}>
                        <FontAwesomeIcon icon={faClone} className="NSNavbar__right__icon-list__icon cursor--pointer" />
                    </NSButton>
                    <NSButton className="p-0 TenantsOverviewCard__noOutline" callback={onDelete}>
                        <FontAwesomeIcon icon={faTrash} className="NSNavbar__right__icon-list__icon cursor--pointer" />
                    </NSButton>
                </div>
            </div>
            <div className="TenantsOverviewCard__separator" />
            <div className="d-flex justify-content-between TenantsOverviewCard__inputContainer">
                <div className="TenantsOverviewCard__inputContainer__input">
                    <NSLabel>Suite</NSLabel>
                    <NSInput
                        id="suite"
                        name="units"
                        placeholder="Enter units"
                        type="number"
                        value={units || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUnits(Number(e.target.value))}
                    />
                </div>
                <div className="TenantsOverviewCard__inputContainer__input">
                    <NSLabel>SF</NSLabel>
                    <NSInput
                        id="averageSquareFootage"
                        name="averageSquareFootage"
                        placeholder="Enter average square footage"
                        type="number"
                        value={averageSquareFootage || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAverageSquareFootage(Number(e.target.value))}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between TenantsOverviewCard__inputContainer pt-2">
                <div className="TenantsOverviewCard__inputContainer__input">
                    <NSLabel>Rent growth</NSLabel>
                    <NSInput
                        id="rentGrowth"
                        name="rentGrowth"
                        placeholder="Enter growth"
                        type="number"
                        value={rentGrowth || ''}
                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRentGrowth(Number(e.target.value))}
                    />
                </div>
                <div className="TenantsOverviewCard__inputContainer__input">
                    <NSLabel>Rent/SF/year</NSLabel>
                    <NSInput
                        id="rentSfYear"
                        name="rentSfYear"
                        placeholder="Enter..."
                        type="number"
                        value={rentSfYear || ''}
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRentSfYear(Number(e.target.value))}
                    />
                </div>
            </div>
            <div className="d-flex TenantsOverviewCard__inputContainer pt-2">
                <div className="flex-grow-1 position-relative">
                    <NSLabel>Lease start</NSLabel>
                    <NSInput
                        id="leaseStart"
                        name="leaseStart"
                        placeholder="Select date..."
                        appendInputAddon={(
                            <div className="cursor--pointer">
                                <FontAwesomeIcon
                                    icon={faCalendar}
                                    size="lg"
                                    onClick={() => setLeaseStartDatePicker(true)}
                                    data-testid="lease-start-icon"
                                />
                                {leaseStartDatePicker && (
                                    <div className="TenantsOverviewCard__datePickerContainer">
                                        <DatePicker
                                            selected={leaseStartDate}
                                            onChange={(date: Date | null) => {
                                                if (date) {
                                                    setLeaseStartDate(date);
                                                }
                                                setLeaseStartDatePicker(false);
                                            }}
                                            onClickOutside={() => setLeaseStartDatePicker(false)}
                                            inline
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        type="text"
                        value={leaseStartDate ? formatDate(leaseStartDate) : ''}
                        readOnly
                    />
                </div>
                <div className="flex-grow-1 position-relative">
                    <NSLabel>Rent start</NSLabel>
                    <NSInput
                        id="rentStartDate"
                        name="rentStartDate"
                        placeholder="Select date..."
                        appendInputAddon={(
                            <div className="cursor--pointer">
                                <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setRentStartDatePicker(true)} />
                                {rentStartDatePicker && (
                                    <div className="TenantsOverviewCard__datePickerContainer">
                                        <DatePicker
                                            selected={rentStartDate}
                                            onChange={(date: Date | null) => {
                                                if (date) {
                                                    setRentStartDate(date);
                                                }
                                                setRentStartDatePicker(false);
                                            }}
                                            onClickOutside={() => setRentStartDatePicker(false)}
                                            inline
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        type="text"
                        value={rentStartDate ? formatDate(rentStartDate) : ''}
                        readOnly
                    />
                </div>
            </div>
            <div className="TenantsOverviewCard__separator" />
            <div className="d-flex justify-content-between text-white font-weight-bold">
                Annual rent (year 1):
                <div>{formatUSDCurrencyTwoDigits(averageSquareFootage * rentSfYear)}</div>
            </div>
            <div className="TenantsOverviewCard__separator" />
            <div className="d-flex TenantsOverviewCard__inputContainer">
                <div className="TenantsOverviewCard__inputContainer__input">
                    <NSLabel>Recovery income %</NSLabel>
                    <NSInput
                        id="recoveryIncome"
                        name="recoveryIncome"
                        placeholder="Enter recovery income"
                        type="number"
                        value={recoveryIncome || ''}
                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecoveryIncome(Number(e.target.value))}
                    />
                </div>
                <div className="TenantsOverviewCard__inputContainer__input">
                    <NSLabel>Pro rata share</NSLabel>
                    <NSInput
                        id="proRata"
                        name="proRata"
                        placeholder="Enter growth"
                        type="number"
                        value={proRata || ''}
                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProRata(Number(e.target.value))}
                    />
                </div>
                <div className="TenantsOverviewCard__inputContainer__largeInput">
                    <NSLabel>Recovery start</NSLabel>
                    <NSInput
                        id="recoveryStartDate"
                        name="recoveryStartDate"
                        placeholder="Select date..."
                        appendInputAddon={(
                            <div className="cursor--pointer">
                                <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setRecoveryStartDatePicker(true)} />
                                {recoveryStartDatePicker && (
                                    <div className="TenantsOverviewCard__datePickerContainer">
                                        <DatePicker
                                            selected={recoveryStartDate}
                                            onChange={(date: Date | null) => {
                                                if (date) {
                                                    setRecoveryStartDate(date);
                                                }
                                                setRecoveryStartDatePicker(false);
                                            }}
                                            onClickOutside={() => setRecoveryStartDatePicker(false)}
                                            inline
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        type="text"
                        value={recoveryStartDate ? formatDate(recoveryStartDate) : ''}
                        readOnly
                    />
                </div>
            </div>
            <div className="TenantsOverviewCard__separator" />
            <div className="d-flex justify-content-between text-white font-weight-bold">
                <div>Rec. income amount/year</div>
                <div>{rentSfYear ? formatUSDCurrencyTwoDigits(averageSquareFootage * rentSfYear) : '-'}</div>
            </div>
        </NSCard>
    );
};

export default TenantsOverviewCard;
