import axiosInstance from 'ns_libs/nsAxios';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';

import { AxiosError } from 'axios';
import { ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY } from 'services/constants';
import { IFeatureAccess } from 'types/auth';

type TQueryKey = [typeof ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY, string];

const getFeatureAccess = async ({ queryKey }: QueryFunctionContext<TQueryKey>): Promise<IFeatureAccess[]> => {
    const response = await axiosInstance.get(API_ENDPOINTS.FEATURE_ACCESS(queryKey[1]));
    return response.data;
};

const useGetFeatureAccess = (
    organizationId: string,
    options?: Omit<UseQueryOptions<IFeatureAccess[], AxiosError, IFeatureAccess[], TQueryKey>, 'queryKey' | 'queryFn'>,
) => useQuery({
    queryKey: [ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY, organizationId],
    queryFn: getFeatureAccess,
    ...options,
});

export default useGetFeatureAccess;
