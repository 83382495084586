import { useState, useMemo, useRef } from 'react';

export interface IExpense {
    id: number;
    name: string;
    growthPercentage: number;
    amount: number;
}

export const useExpenses = (initialExpenses: IExpense[] = []) => {
    const [expenses, setExpenses] = useState<IExpense[]>(initialExpenses.length > 0 ? initialExpenses : []);
    const lastIdRef = useRef(initialExpenses.length > 0 ? Math.max(...initialExpenses.map(exp => exp.id)) : 0);

    // Create a new expense row
    const createExpense = () => {
        const newExpense: IExpense = {
            id: ++lastIdRef.current,
            name: '',
            growthPercentage: 3.0,
            amount: 0,
        };
        setExpenses([...expenses, newExpense]);
    };

    // Update an existing expense row
    const updateExpense = (id: number, field: keyof IExpense, value: string | number) => {
        const updatedExpenses = expenses.map(exp => {
            if (exp.id === id) {
                const newValue = field === 'growthPercentage' ? Math.min(100, Math.max(0, Number(value))) : value;
                return { ...exp, [field]: newValue };
            }
            return exp;
        });
        setExpenses(updatedExpenses);
    };

    // Delete an expense row
    const deleteExpense = (id: number) => {
        setExpenses(expenses.filter(exp => exp.id !== id));
    };

    const totalAmount = useMemo(() => expenses.reduce((total, exp) => total + exp.amount, 0), [expenses]);

    return {
        expenses,
        createExpense,
        updateExpense,
        deleteExpense,
        totalAmount,
    };
};
