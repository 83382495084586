/* eslint-disable operator-linebreak */
import { formatUTCtoLocal, numeralFormatter, numeralFormatterCurrency } from 'ns_libs/formatter';
import {
    PRO_FORMA_APARTMENT_UNIT_TYPES,
    PRO_FORMA_INDUSTRIAL_UNIT_TYPES,
    PRO_FORMA_RETAIL_AND_OFFICE_UNIT_TYPES,
    UNIT_TYPE_NAMES,
    SQUARE_FEET_PER_ACRE,
    UnitTypeIds,
} from 'constants/unitTypes';
import { ProFormaModelIds } from 'constants/proForma';
import { format } from 'date-fns';
import { PRO_FORMA_TABS, PRO_FORMA_TABLE_NAV, RETURNS_AND_WATERFALL_TABS } from './constants';
import { IProFormaUses, IUpdateProFormaKeyValues, IGrossBuildableAreaLotsData } from './types';
import { ProFormaGenericColumn } from './components/ProFormaGenericTable/types';

export const getProFormaUrl = (sideNavName: string, tabName?: string) => {
    const section = encodeURIComponent(sideNavName);
    let tab = '';

    if (!tabName) {
        if (sideNavName === PRO_FORMA_TABLE_NAV.PRO_FORMA.name) {
            tab = `&tab=${encodeURIComponent(PRO_FORMA_TABS.GENERAL_INFORMATION.name)}`;
        } else if (sideNavName === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name) {
            tab = `&tab=${encodeURIComponent(RETURNS_AND_WATERFALL_TABS.RETURNS)}`;
        }
    } else {
        tab = `&tab=${encodeURIComponent(tabName)}`;
    }

    const url = `${window.location.pathname}?section=${section}${tab}`;

    return url;
};

export const generateDateRange = (dateRange: string[], labelFormat: string = 'MMM yyyy') => {
    const dates: Date[] = [];

    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);

    const currentDate = new Date(startDate);

    while (currentDate.getMonth() <= endDate.getMonth()) {
        dates.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
    }
    const options = dates.map(date => ({
        value: formatUTCtoLocal(date, 'yyyy-MM-dd'), // 2024-01-01
        label: formatUTCtoLocal(date, labelFormat),
    }));
    return options;
};

export const getBudgetLinesDateRange = (uses: IProFormaUses[]) => {
    /**
     * Get the earliest start date and latest end date of all budget lines.
     * If any budget line has a null start date, the earliest start date will be null.
     * If any budget line has a null end date, the latest end date will be null.
     */

    const budgetLineStartDates: (string | null)[] = [];
    const budgetLineEndDates: (string | null)[] = [];
    uses.map(highLevel => {
        highLevel.children?.map(subcategory => {
            subcategory.children?.map(budgetLine => {
                if (budgetLine.isEditable) {
                    budgetLineStartDates.push(budgetLine.startDate || null);
                    budgetLineEndDates.push(budgetLine.endDate || null);
                }
                return null;
            });
            return null;
        });
        return null;
    });

    let earliestBudgetLinesStartDate = null;
    let latestBudgetLinesEndDate = null;

    if (budgetLineStartDates.includes(null)) {
        earliestBudgetLinesStartDate = null;
    } else {
        const validStartDates = budgetLineStartDates.filter(date => date !== null) as string[];

        earliestBudgetLinesStartDate = validStartDates.length ? validStartDates.reduce((earliest, date) => (date < earliest ? date : earliest)) : null;
    }

    if (budgetLineEndDates.includes(null)) {
        latestBudgetLinesEndDate = null;
    } else {
        const validEndDates = budgetLineEndDates.filter(date => date !== null) as string[];

        latestBudgetLinesEndDate = validEndDates.length ? validEndDates.reduce((latest, date) => (date > latest ? date : latest)) : null;
    }

    return { earliestBudgetLinesStartDate, latestBudgetLinesEndDate };
};

export const getProFormaApartmentDisplayCards = (values: any, units: number, rentableSquareFootage: number, isFar: boolean) => {
    const landArea = values.landArea || 0;
    const landAreaInAcres = landArea && values.landAreaUnitType === UnitTypeIds.ACRE ? landArea / SQUARE_FEET_PER_ACRE : landArea;
    const averageSqftPerUnit = values.grossBuildableArea && units ? values.grossBuildableArea / units : 0;

    const netRentableArea = units && averageSqftPerUnit ? units * averageSqftPerUnit : undefined;
    const unitsPerAcre = units && landAreaInAcres ? (units / landAreaInAcres).toFixed(2) : undefined;
    const totalParkingSpots = (values.structuredParkingSpaces || 0) + (values.surfaceParkingSpaces || 0);
    const parkingRatio = totalParkingSpots && units ? (totalParkingSpots / units).toFixed(2) : undefined;
    const efficiencyRatio = rentableSquareFootage && values.grossBuildableArea ? (rentableSquareFootage / values.grossBuildableArea).toFixed(2) : null;

    const farCardValues = {
        label: 'FAR',
        value: values.floorAreaRatio,
        format: '0,0.00',
    };
    const grossBuildableAreaCardValues = {
        label: 'Gross buildable area',
        value: values.grossBuildableArea,
        format: '0,0',
        valueSuffix: 'ft²',
    };

    return [
        { label: 'Units', value: units },
        { label: 'Net rentable area', value: netRentableArea, format: '0,0' },
        { label: 'Units/acre', value: unitsPerAcre, format: '0,0.00' },
        isFar ? grossBuildableAreaCardValues : farCardValues,
        { label: 'Total parking spots', value: totalParkingSpots, format: '0,0' },
        { label: 'Parking ratio', value: parkingRatio, format: '0,0.00' },
        {
            label: 'Efficiency ratio', value: efficiencyRatio, format: '0,0.00', valueSuffix: '%',
        },
    ] as { label: string; value: number | string | undefined; format?: string; valueSuffix?: string }[];
};

export const formatValue = (value: string, isNegative = false, forDisplay = false, format?: ProFormaGenericColumn['format'], isHidden = false) => {
    if (isHidden) return '';

    if (format === 'none') return value;

    const num = parseFloat(value);
    if (Number.isNaN(num)) return value;
    if (!forDisplay) return value;

    const absNum = Math.abs(num);
    let formatted: string;

    switch (format) {
        case 'percentage':
            formatted = `${numeralFormatter(absNum, '0.00')}%`;
            break;
        default:
            formatted = numeralFormatterCurrency(absNum);
    }

    return isNegative ? `(${formatted})` : formatted;
};

export const getMissingDateText = (startDate: string | null, saleDate: string | null) => {
    if (!startDate && !saleDate) {
        return {
            title: 'Start and Sale dates required',
            prependedSubtitle: 'Provide start and sale dates on',
            boldSubtitleText: 'Start and End',
        };
    }
    if (!startDate) {
        return {
            title: 'Start date required',
            prependedSubtitle: 'Provide a start date on',
            boldSubtitleText: 'Start',
        };
    }
    if (!saleDate) {
        return {
            title: 'Sale date required',
            prependedSubtitle: 'Provide a sale date on',
            boldSubtitleText: 'End',
        };
    }
    return {
        title: null,
        prependedSubtitle: null,
        boldSubtitleText: null,
    };
};

/**
 * Includes Date logic as follows:
 *  if a user selects a start date, the sale date should be the first day of the next month.
 *  if a user selects a start date, and the start date is after the existing sale date, the sale date should be the first day of the next month.
 *  if a user selects a sale date, and the sale date is before the start date, the start date should be cleared.
 */
export const handleDateChange = (
    name: string,
    newDate: string | null,
    values: { [key: string]: any },
    handleUpdateProForma: (keyValues: IUpdateProFormaKeyValues[]) => void,
) => {
    if (!newDate) {
        handleUpdateProForma([{ key: name, value: null }]);
        return;
    }

    const [year, month, day] = newDate.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);

    if (name === 'startDate') {
        const saleDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
        const newStartDate = format(selectedDate, 'yyyy-MM-dd');
        const newSaleDate = format(saleDate, 'yyyy-MM-dd');
        const formattedSaleDate = new Date(values.saleDate);

        const keyUpdates: IUpdateProFormaKeyValues[] = [{ key: 'startDate', value: `${newStartDate}T00:00:00` }];

        if (!values.saleDate || selectedDate > formattedSaleDate) {
            keyUpdates.push({ key: 'saleDate', value: `${newSaleDate}T00:00:00` });
        }

        handleUpdateProForma(keyUpdates);
    } else {
        const newSaleDate = format(selectedDate, 'yyyy-MM-dd');
        const formattedStartDate = new Date(values.startDate);

        const keyUpdates: IUpdateProFormaKeyValues[] = [{ key: 'saleDate', value: `${newSaleDate}T00:00:00` }];
        if (values.startDate && formattedStartDate > selectedDate) {
            keyUpdates.push({ key: 'startDate', value: null });
        }
        handleUpdateProForma(keyUpdates);
    }
};

export const getProFormaDevelopmentUnitTypeOptions = (proFormaModelId: number) => {
    if (proFormaModelId === ProFormaModelIds.APARTMENT) {
        return PRO_FORMA_APARTMENT_UNIT_TYPES.map(unitType => ({
            label: UNIT_TYPE_NAMES[unitType].dropdownItemName,
            value: unitType,
        }));
    }
    if (proFormaModelId === ProFormaModelIds.INDUSTRIAL) {
        return PRO_FORMA_INDUSTRIAL_UNIT_TYPES.map(unitType => ({
            label: UNIT_TYPE_NAMES[unitType].dropdownItemName,
            value: unitType,
        }));
    }
    if (proFormaModelId === ProFormaModelIds.RETAIL || proFormaModelId === ProFormaModelIds.OFFICE) {
        return PRO_FORMA_RETAIL_AND_OFFICE_UNIT_TYPES.map(unitType => ({
            label: UNIT_TYPE_NAMES[unitType].dropdownItemName,
            value: unitType,
        }));
    }
    return [];
};

export const calculateGrossBuildableArea = (
    buildableLots: IGrossBuildableAreaLotsData,
    grossBuildableArea: string | null,
    floorAreaRatio: string | null,
    landArea: string | null,
    landAreaUnitTypeId: number,
) => {
    const { totalGrossBuildableArea, grossBuildableAreaLots } = buildableLots;
    if (grossBuildableAreaLots.length) return Number(totalGrossBuildableArea);
    if (grossBuildableArea !== null) return Number(grossBuildableArea);
    if (floorAreaRatio === null || landArea === null) return null;

    let calculatedGrossBuildableArea = Number(floorAreaRatio) * Number(landArea);
    if (landAreaUnitTypeId === UnitTypeIds.ACRE) calculatedGrossBuildableArea *= SQUARE_FEET_PER_ACRE;
    return calculatedGrossBuildableArea;
};

export const calculateFloorAreaRatio = (
    floorAreaRatio: string | null,
    landArea: string | null,
    landAreaUnitType: number,
    grossBuildableArea: number | null,
) => {
    if (grossBuildableArea !== null && landArea !== null) {
        const landAreaSf = landAreaUnitType === UnitTypeIds.ACRE ? Number(landArea) * SQUARE_FEET_PER_ACRE : Number(landArea);
        return landAreaSf ? grossBuildableArea / landAreaSf : 0;
    }
    return floorAreaRatio !== null ? Number(floorAreaRatio) : null;
};
