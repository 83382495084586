import React, { ComponentType } from 'react';
import { PlaceholderProps, components, OptionProps } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import RenderIf from '../../components/RenderIf/RenderIf';
import NSBadge from '../NSBadge/NSBadge';
import NSSelect, { MemoizedDropdownIndicator, NSSelectProps } from '../NSSelect/NSSelect';
import NSCheckbox from '../NSCheckbox/NSCheckbox';

export interface OptionType {
    label: string;
    value: any;
    description?: string;
    badge?: string;
}

export interface NSSelectWithCheckboxesProps extends Omit<NSSelectProps, 'options'> {
    options: OptionType[];
    // This function provides the selected options
    onChange: (selected: OptionType[]) => void;
    // This prop is used to show/hide the "Select all" option
    withSelectAllOption?: boolean;
    // The displayed name for what the list of options are to show "Budget lines: 3 selected" for example
    optionsTypeName?: string;
    // For passing in already selected option values, when saved locally for example
    initiallySelectedValues?: number[];
    // For managing selected options outside of the component
    value: OptionType[];
    // is being used in NSMultiSelect component
    isNSMulti?: boolean;
    defaultAll?: boolean;
}

const NSSelectWithCheckboxes = ({
    options,
    onChange,
    withSelectAllOption,
    optionsTypeName,
    initiallySelectedValues,
    isNSMulti = false,
    defaultAll,
    ...otherProps
}: NSSelectWithCheckboxesProps) => {
    const selectedValues = new Set(otherProps.value ? otherProps.value.map((opt: OptionType) => opt.value) : []);
    const optionValuesSet = new Set(options.map(opt => opt.value));
    const enhancedOptions = withSelectAllOption ? [{ label: 'Select all', value: 0 }, ...options] : options;
    const noneSelectedPlaceholder = otherProps.placeholder || 'Select options';
    const areAllSelected = selectedValues.size === optionValuesSet.size;

    const handleOptionClick = (option: OptionType) => {
        const updatedSelectedOptions = new Set(selectedValues);

        if (option.value === 0 && withSelectAllOption) {
            if (areAllSelected) {
                updatedSelectedOptions.clear();
            } else {
                enhancedOptions.forEach(opt => {
                    if (opt.value !== 0) updatedSelectedOptions.add(opt.value);
                });
            }
        } else if (updatedSelectedOptions.has(option.value)) {
            updatedSelectedOptions.delete(option.value);
        } else {
            updatedSelectedOptions.add(option.value);
        }

        const selectedOptions = Array.from(updatedSelectedOptions)
            .map(val => enhancedOptions.find(opt => opt.value === val))
            .filter((opt): opt is OptionType => opt !== undefined);

        onChange(selectedOptions);
    };

    const handleChange = (option?: OptionType[]) => {
        if (option?.length === 0) {
            onChange([]);
        }
    };

    const CheckboxOption: ComponentType<OptionProps<OptionType, boolean, any>> = ({
        data: option, innerRef, innerProps, ...props
    }) => {
        const isChecked = option.value === 0 ? areAllSelected : selectedValues.has(option.value);
        return (
            <div ref={innerRef} {...innerProps} onClick={() => handleOptionClick(option)} role="presentation" className="cursor--pointer">
                <components.Option {...props} data={option} innerRef={innerRef} innerProps={innerProps}>
                    <div
                        className="select d-flex justify-content-between align-items-center cursor--pointer w-100"
                        data-testid={`select-option-${option.value}`}
                    >
                        <div className="d-flex flex-column">
                            <span className="text-wrap">{option.label}</span>
                            <RenderIf isTrue={option.description}>
                                <span className="text-wrap text-muted">{option.description}</span>
                            </RenderIf>
                        </div>
                        <div>
                            <RenderIf isTrue={option?.badge?.length}>
                                <NSBadge color="primary-lighten" className="ml-1 mr-2">
                                    <FontAwesomeIcon icon={faLightbulb} />
                                    <span className="pl-1">{option.badge}</span>
                                </NSBadge>
                            </RenderIf>
                            <NSCheckbox iconOnly checked={isChecked} callback={() => {}} id={`checkbox-${option.value}`} />
                        </div>
                    </div>
                </components.Option>
            </div>
        );
    };

    const selectedOptions = Array.from(selectedValues)
        .map(val => enhancedOptions.find(opt => opt.value === val))
        .filter((opt): opt is OptionType => opt !== undefined);

    const hasAllSelected = selectedOptions?.length === options?.length;

    let displayCountSelected = '';

    if (isNSMulti) {
        displayCountSelected = selectedOptions.length > 0 ? `${selectedOptions.length} selected` : noneSelectedPlaceholder;
    } else {
        displayCountSelected = 'None';
        if (selectedOptions.length && !hasAllSelected) {
            displayCountSelected = `${selectedOptions.length} selected`;
        } else if (hasAllSelected || defaultAll) {
            displayCountSelected = 'All';
        }
    }

    const CustomPlaceholder: React.FC<PlaceholderProps> = props => (
        <components.Placeholder {...props}>
            <div>
                {optionsTypeName && (
                    <span className="text-dark">
                        {optionsTypeName}
                        :&nbsp;
                    </span>
                )}
                <span className="text-muted">{displayCountSelected}</span>
            </div>
        </components.Placeholder>
    );

    return (
        <NSSelect
            className="select text-nowrap"
            formClassName="select"
            options={enhancedOptions}
            isMulti={!isNSMulti}
            hideSelectedOptions={false}
            onChange={handleChange}
            closeMenuOnSelect={false}
            placeholder={selectedOptions.length > 0 ? `${selectedOptions.length} selected options` : noneSelectedPlaceholder}
            components={{
                Placeholder: CustomPlaceholder,
                DropdownIndicator: MemoizedDropdownIndicator,
                Option: CheckboxOption,
            }}
            {...otherProps}
        />
    );
};

export default NSSelectWithCheckboxes;
