import classNames from 'classnames';
import RenderIf from 'components/RenderIf/RenderIf';
import React from 'react';
import { formatValue } from 'views/ProFormaTable/helpers';
import './ExecutiveSummaryGenericTable.scss';
import { Format, RowValue, Section } from './types';
import { DEFAULT_CELL_WIDTH } from './constants';

interface ExecutiveSummaryGenericTableProps {
    title?: string;
    sections: Section[];
}

const ExecutiveSummaryGenericTable = ({ title, sections }: ExecutiveSummaryGenericTableProps) => {
    const renderCell = (value?: RowValue, format?: Format, isNegative?: boolean) => {
        if (value?.value === undefined) return '-';
        return `${formatValue(value.value, isNegative, true, format)}${value.label || ''}`;
    };

    return (
        <div className="ExecutiveSummaryGenericTable NSCard--level-3 text-white p-3">
            {title && <div className="text-primary mb-1">{title}</div>}
            {sections.map(section => (
                <div key={section.id}>
                    <table className="w-100">
                        <thead>
                            {section.title && (
                                <tr>
                                    <th className="text-left text-white font-weight-bold pt-1">{section.title}</th>
                                    {section.columns?.map(col => (
                                        <th key={`${section.id}-${col}`} className="text-right text-white font-weight-bold pt-1">
                                            {col}
                                        </th>
                                    ))}
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {section.rows.map(row => (
                                <tr key={row.id}>
                                    <td className="text-muted" width={DEFAULT_CELL_WIDTH}>
                                        {row.label}
                                    </td>
                                    {row.values.map(value => (
                                        <td
                                            key={`${row.id}-${row.label}-${value.value}`}
                                            className={classNames('text-white text-right', { 'text-muted': value.isNegative })}
                                            width={DEFAULT_CELL_WIDTH}
                                        >
                                            {renderCell(value, value.format, value.isNegative)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <RenderIf isTrue={!section.isTotal && section !== sections[sections.length - 1]}>
                        <div className="ExecutiveSummaryGenericTable--separator w-100 pt-1 mb-1" />
                    </RenderIf>
                </div>
            ))}
        </div>
    );
};

export default ExecutiveSummaryGenericTable;
