import React, { useEffect, useState } from 'react';
import {
    NSCard, NSInput, NSLabel, NSSelect,
} from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'react-datepicker';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';

import './ProFormaPermanentDebt.scss';
import RenderIf from 'components/RenderIf/RenderIf';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import { InterestOption, IProFormaPermanentDebtLoan } from './types';
import {
    formatDateWithMonthAndYear, DEBT_SERVICE_COLUMNS, INTEREST_OPTIONS, TOTAL_DEBTSERVICE_COLUMNS, GENERIC_ROWS_INITIAL_STATE,
} from './constants';

const ProFormaPermanentDebt = () => {
    const [startDatePicker, setStartDatePicker] = useState(false);
    const [interest, setInterest] = useState(false);
    const [interestOption, setInterestOption] = useState<InterestOption>(INTEREST_OPTIONS[0]);
    const [loanDetails, setLoanDetails] = useState<IProFormaPermanentDebtLoan>({
        startDate: null,
        amortization: 0,
        apr: 0,
        feesClosingCost: 0,
        loanPayoff: null,
        debtServiceCoverageRatio: 0,
        cashOnReturn: 0,
        cashOnReturnMonth: 0,
    });
    const [debtServiceRows, setDebtServiceRows] = useState<ProFormaGenericRow[]>(GENERIC_ROWS_INITIAL_STATE);
    const { handleScroll, handleScrollableRef } = useScrollableSync();

    const handleLoanDetailChange = (key: string, value: any) => {
        setLoanDetails(prev => ({ ...prev, [key]: value }));
    };

    const selectOption = (option: InterestOption) => {
        setInterestOption(option);
        setInterest(option.value);
    };

    // TODO: this would be replaced with an API or websocket fetch
    useEffect(() => {
        handleLoanDetailChange('apr', 6.5);
        handleLoanDetailChange('feesClosingCost', 3.0);
        handleLoanDetailChange('loanPayoff', new Date());
        handleLoanDetailChange('debtServiceCoverageRatio', 1.75);
        handleLoanDetailChange('cashOnReturn', 4.86);
        handleLoanDetailChange('cashOnReturnMonth', 32);
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-end pb-3">
                <div className="ProFormaPermanentDebt__inputs-container w-50 pl-3">
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Loan funding</NSLabel>
                        <NSInput
                            id="loanFunding"
                            name="loanFunding"
                            placeholder="Select date..."
                            inputClassName="pt-2 ProFormaPermanentDebt__input-width"
                            appendInputAddon={(
                                <div>
                                    <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setStartDatePicker(true)} className="cursor-pointer" />
                                    {startDatePicker && (
                                        <div className="ProFormaWizardStepOne__datepicker">
                                            <DatePicker
                                                selected={loanDetails.startDate}
                                                onChange={(date: Date | null) => {
                                                    if (date) {
                                                        handleLoanDetailChange('startDate', date);
                                                    }
                                                    setStartDatePicker(false);
                                                }}
                                                onClickOutside={() => setStartDatePicker(false)}
                                                inline
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            type="text"
                            value={loanDetails.startDate ? formatDateWithMonthAndYear(loanDetails.startDate) : ''}
                            readOnly
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Interest only?</NSLabel>
                        <NSSelect
                            options={INTEREST_OPTIONS}
                            name="interest"
                            value={interestOption}
                            onChange={option => selectOption(option)}
                            isClearable={false}
                        />
                    </div>

                    <RenderIf isTrue={interest}>
                        <div className="d-flex align-items-center mt-2">
                            <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Amortization</NSLabel>
                            <NSInputNumberStepper
                                name="amortization"
                                value={loanDetails.amortization}
                                onChange={(value: number) => handleLoanDetailChange('amortization', value)}
                                min={0}
                                step={1}
                                customSuffix="years"
                                inputGroupClassName="ProFormaPermanentDebt__input-width"
                            />
                        </div>
                    </RenderIf>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">APR</NSLabel>
                        <NSInputNumberStepper
                            name="apr"
                            value={loanDetails.apr}
                            onChange={(value: number) => handleLoanDetailChange('apr', value)}
                            min={0}
                            step={0.1}
                            percentage
                            inputGroupClassName="ProFormaPermanentDebt__input-width"
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Fees & closing costs</NSLabel>
                        <NSInputNumberStepper
                            name="feesClosingCost"
                            value={loanDetails.feesClosingCost}
                            onChange={(value: number) => handleLoanDetailChange('feesClosingCost', value)}
                            min={0}
                            step={0.1}
                            percentage
                            inputGroupClassName="ProFormaPermanentDebt__input-width"
                        />
                    </div>
                </div>
                <div className="w-50 pr-3">
                    <NSCard className="NSCard--level-4 mt-2">
                        <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                            <div className="text-muted">Loan payoff</div>
                            <div className="text-white">{loanDetails.loanPayoff ? formatDateWithMonthAndYear(loanDetails.loanPayoff) : null}</div>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-4 mt-2">
                        <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                            <div className="text-muted">Debt service coverage ratio</div>
                            <div className="text-white">{loanDetails.debtServiceCoverageRatio ? `${loanDetails.debtServiceCoverageRatio}X` : null}</div>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-4 mt-2">
                        <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                            <div className="text-muted">Cash-on-cash on return</div>
                            <div className="text-white">
                                {loanDetails.cashOnReturn ? `${loanDetails.cashOnReturn}% (Month ${loanDetails.cashOnReturnMonth})` : null}
                            </div>
                        </div>
                    </NSCard>
                </div>
            </div>
            <div className="ProFormaPermanentDebt__scrollable-section p-3">
                <ProFormaGenericTable
                    columns={DEBT_SERVICE_COLUMNS}
                    rows={debtServiceRows}
                    setRows={setDebtServiceRows}
                    hideAddButton
                    hideTotalFooter
                    onScrollableRef={handleScrollableRef(0)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={TOTAL_DEBTSERVICE_COLUMNS}
                    rows={[]}
                    setRows={() => {}}
                    hideTotalFooter
                    hideAddButton
                    onScrollableRef={handleScrollableRef(1)}
                    onScroll={handleScroll}
                />
            </div>
        </div>
    );
};

export default ProFormaPermanentDebt;
