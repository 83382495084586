import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';
import { IOptionAnyValue } from 'bricks/types';
import KeyStatsCard from 'components/KeyStatsCard/KeyStatsCard';
import { NSDropdown, NSLoadingScreen } from 'bricks';
import { PERIOD_OPTIONS } from 'components/ProFormaReturnsTable/constant';
import {
    ICreateReportPostData, IDeal, IDealSelection, IReportDetails, ReportTypeId,
} from 'views/Reports/types';
import {
    useCreateReport, useGetAggregateReportData, useGetOrgDealSummaries, useUpdateReport,
} from 'views/Reports/hooks';
import { URLS } from 'services/urls';
import useToast from 'hooks/useToast';
import { excelExport } from 'bricks/utils';
import ReportHeader from 'views/Reports/ReportHeader/ReportHeader';
import DealSelection from 'views/Reports/components/DealSelection/DealSelection';
import { formatExcelExportData } from './helpers';
import AggregateProFormaReturnsTable from './AggregateProFormaReturnsTable/AggregateProFormaReturnsTable';

export interface IAggregateReportProps {
    report?: IReportDetails;
    onClickBack: () => void;
    isEditing?: boolean;
}

const AggregateReport = ({ report, onClickBack, isEditing = false }: IAggregateReportProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { showSuccess, showError, ToastContainerElement } = useToast();
    const { selectedOrganizationId } = useAuthContext();

    const [selectedPeriod, setSelectedPeriod] = React.useState<IOptionAnyValue>(PERIOD_OPTIONS[0]);
    const [isEditMode, setIsEditMode] = useState(!report || isEditing);
    const [reportName, setReportName] = useState(report?.name || 'New Aggregate Report');
    const [reportDescription, setReportDescription] = useState(report?.description || '');

    const { data: dealSummaries } = useGetOrgDealSummaries(selectedOrganizationId!, { enabled: true });
    // mapping to add dummy project and projectType, update when all values are available in db is ready
    const deals: IDeal[] = useMemo(() => (
        dealSummaries?.map(deal => ({
            id: deal.id,
            dealName: deal.dealName,
            dealType: deal.dealType,
            project: 'deal.project',
            projectType: 'deal.projectType',
            defaultProFormaId: deal.defaultProFormaId,
        })) || []
    ), [dealSummaries]);

    const reportDeals = report?.deals.map(deal => ({
        deal: {
            id: deal.dealId,
            dealName: deal.dealName,
            dealType: deal.dealType,
            project: 'Project',
            projectType: 'Project Type',
        },
        proForma: { value: deal.proFormaId, label: deal.proFormaName },
    }));

    const [selectedDeals, setSelectedDeals] = useState<IDealSelection[]>(reportDeals || location.state?.selectedDeals || []);

    const handlePeriodSelection = (option: IOptionAnyValue) => {
        setSelectedPeriod(option);
    };

    const { data: aggReportData, isLoading } = useGetAggregateReportData({
        orgId: selectedOrganizationId!,
        proFormaIds: selectedDeals.map(selection => selection.proForma?.value),
        cadence: selectedPeriod.value,
    });

    const { mutate: createReport } = useCreateReport();
    const { mutate: updateReport } = useUpdateReport();

    const onClickExport = () => {
        if (report && aggReportData) {
            const formattedExcelExportData = formatExcelExportData(report.name, report.description, aggReportData);
            excelExport(report.name, false, formattedExcelExportData, true, []);
        }
    };

    const handleSaveReport = () => {
        const postData: ICreateReportPostData = {
            name: reportName,
            description: reportDescription,
            type: ReportTypeId.Aggregate,
            deals: selectedDeals.map(selection => ({
                dealId: selection.deal.id,
                proFormaId: selection.proForma?.value,
            })),
        };
        if (report) {
            updateReport(
                {
                    postData: {
                        report_id: report.id,
                        ...postData,
                    },
                    orgId: selectedOrganizationId!,
                },
                {
                    onSuccess: data => {
                        queryClient.invalidateQueries({ queryKey: ['reportDetails', data.id] });
                        showSuccess('Report updated successfully');
                        setIsEditMode(false);
                    },
                    onError: () => {
                        showError('Failed to update report. Please try again.');
                    },
                },
            );
        } else {
            createReport(
                {
                    postData,
                    orgId: selectedOrganizationId!,
                },
                {
                    onSuccess: data => {
                        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId: data.id });
                        showSuccess('Report created successfully');
                        navigate(path);
                    },
                    onError: () => {
                        showError('Failed to create report. Please try again.');
                    },
                },
            );
        }
    };

    return (
        <div data-testid="aggregate-report-container">
            <ReportHeader
                reportId={report?.id}
                reportName={reportName}
                setReportName={setReportName}
                reportDescription={reportDescription}
                setReportDescription={setReportDescription}
                onClickBack={onClickBack}
                onClickExport={onClickExport}
                onClickSave={handleSaveReport}
                onClickEdit={() => setIsEditMode(true)}
                isEditing={isEditMode}
            />
            {isEditMode && (
                <>
                    <DealSelection deals={deals} titleSuffix="DEALS SELECTED" selectedDeals={selectedDeals} setSelectedDeals={setSelectedDeals} />
                    <hr />
                </>
            )}
            {isLoading && <NSLoadingScreen />}
            {aggReportData && (
                <>
                    <Row className="d-flex mt-1 justify-content-between">
                        <Col xs="4" className="pr-0">
                            <KeyStatsCard title="LEVERED EMx" value={aggReportData.keyStats.leveredEmx} isPercentage />
                        </Col>
                        <Col xs="4" className="pr-0">
                            <KeyStatsCard title="LEVERED IRR" value={aggReportData.keyStats.leveredIrr} isPercentage />
                        </Col>
                        <Col xs="4">
                            <KeyStatsCard title="LEVERED PROFIT RETURN" value={aggReportData.keyStats.leveredProfitReturn} />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-end">
                        <NSDropdown
                            containerClassName="mr-2"
                            options={PERIOD_OPTIONS}
                            selectedOption={selectedPeriod}
                            handleSelectOption={handlePeriodSelection}
                            placeholder="Select saved report"
                        />
                    </Row>
                    <AggregateProFormaReturnsTable data={aggReportData} selectedPeriod={selectedPeriod} />
                </>
            )}
            {ToastContainerElement}
        </div>
    );
};

export default AggregateReport;
