/* eslint-disable operator-linebreak */
import React, { useMemo, useState } from 'react';
import { NSTable } from 'bricks';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import { IOptionAnyValue } from 'bricks/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import { CashFlowPeriodData, ProFormaReturnsPeriodData, CostMetrics } from 'views/Reports/types';
import {
    AggregateReportProperties, Periods, PropertyKeyToLabelMap, spacerRows, CostMetricKeyToLabelMap, PRO_FORMA_RETURN_TOOLTIPS,
} from './constant';

export interface IProps {
    proFormaReturns: Record<string, ProFormaReturnsPeriodData>;
    cashFlowData: Record<string, CashFlowPeriodData>;
    selectedPeriod: IOptionAnyValue;
    costMetrics: CostMetrics;
}

export const ProFormaReturnsTable = ({
    selectedPeriod, proFormaReturns, cashFlowData, costMetrics,
}: IProps) => {
    const dateKeys = Object.keys(proFormaReturns.total_development_cost.periods);
    const [openTooltips, setOpenTooltips] = useState<Record<string, boolean>>({});
    const toggleTooltip = (tooltipId: string) => {
        setOpenTooltips(prev => ({ ...prev, [tooltipId]: !prev[tooltipId] }));
    };

    const periodLabel = useMemo(() => {
        if (selectedPeriod.value === Periods.MONTHLY) {
            return 'MONTH';
        }
        if (selectedPeriod.value === Periods.QUARTERLY) {
            return 'QUARTER';
        }
        if (selectedPeriod.value === Periods.ANNUALLY) {
            return 'YEAR';
        }
        return '';
    }, [selectedPeriod]);

    return (
        <div className="NSTable--sticky-headers__table-container">
            <NSTable className="NSTable NSTable--sticky-headers--two-sticky-columns mb-1">
                <thead>
                    <tr>
                        <th className="align-middle text-white NSTable__thead__tr__th">Property</th>
                        <th className="align-middle text-white">Amount</th>
                        {dateKeys.map((date, index) => (
                            <th key={`header-${date}`} className="NSTable__thead__tr text-nowrap text-white">
                                <span className="text-muted font-weight-light h6">{`${periodLabel} ${index + 1}`}</span>
                                <br />
                                {date}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(proFormaReturns).map(prop => {
                        const propLabel = PropertyKeyToLabelMap[prop as keyof typeof PropertyKeyToLabelMap];
                        const tooltip = PRO_FORMA_RETURN_TOOLTIPS[propLabel as AggregateReportProperties];

                        return (
                            <>
                                <tr key={prop} className="NSTable__tbody__tr">
                                    <td className="text-white">
                                        {propLabel}
                                        {tooltip && (
                                            <>
                                                <FontAwesomeIcon className="px-1 text-white" id={`${prop}-tooltip`} icon={faCircleInfo} />
                                                <Tooltip
                                                    target={`${prop}-tooltip`}
                                                    isOpen={openTooltips[`${prop}-tooltip`] || false}
                                                    toggle={() => toggleTooltip(`${prop}-tooltip`)}
                                                    placement="top"
                                                    delay={{ show: 0, hide: 300 }}
                                                    style={{
                                                        textAlign: 'left',
                                                        minWidth: '250px',
                                                    }}
                                                >
                                                    <span className="font-weight-bold">{propLabel}</span>
                                                    <div>{tooltip}</div>
                                                </Tooltip>
                                            </>
                                        )}
                                    </td>
                                    <td className="text-white">{numeralFormatterCurrency(proFormaReturns[prop].amount)}</td>
                                    {Object.values(proFormaReturns[prop].periods).map((value: any) => (
                                        <td key={`pro-forma-returns-${prop}-${value}`} className="text-white">
                                            {numeralFormatterCurrency(value)}
                                        </td>
                                    ))}
                                </tr>
                                {spacerRows.includes(propLabel) && (
                                    <tr className="NSTable__spacer-row">
                                        <td colSpan={dateKeys.length + 4} className="pt-0 pb-1" />
                                    </tr>
                                )}
                            </>
                        );
                    })}
                </tbody>
            </NSTable>
            <NSTable className="NSTable NSTable--sticky-headers--four-sticky-columns mb-1">
                <thead>
                    <tr>
                        <th className="align-middle"> </th>
                        <th className="align-middle text-white">Equity multiple</th>
                        <th className="align-middle text-white">IRR</th>
                        <th className="align-middle text-white">Net profit</th>
                        {dateKeys.map(date => (
                            <th key={`header-${date}`} className="NSTable__thead__tr text-nowrap">
                                {' '}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(cashFlowData).map(prop => {
                        const propLabel = PropertyKeyToLabelMap[prop as keyof typeof PropertyKeyToLabelMap];
                        return (
                            <tr key={`cash-flow-${prop}`} className="NSTable__tbody__tr text-end">
                                <td className="text-white ">{propLabel}</td>
                                <td className="text-white">{percentFormatter(cashFlowData[prop].equityMultiple)}</td>
                                <td className="text-white">{numeralFormatterCurrency(cashFlowData[prop].irr)}</td>
                                <td className="text-white">{numeralFormatterCurrency(cashFlowData[prop].netProfit)}</td>
                                {Object.keys(cashFlowData[prop].periods).map(period => (
                                    <td key={`cash-flow-${period}-${cashFlowData[prop].periods[period]}`} className="text-white">
                                        {numeralFormatterCurrency(cashFlowData[prop].periods[period])}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </NSTable>
            <NSTable className="NSTable NSTable--sticky-headers--four-sticky-columns">
                <thead>
                    <tr>
                        <th className="align-middle" />
                        <th className="align-middle text-white">Untrended</th>
                        <th className="align-middle text-white">Trended</th>
                        <th className="align-middle text-white">Sale</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(costMetrics).map(([metricName, metric]) => (
                        <tr key={`cost-metric-${metricName}`} className="NSTable__tbody__tr text-end">
                            <td className="align-middle">{CostMetricKeyToLabelMap[metricName as keyof typeof CostMetricKeyToLabelMap]}</td>
                            <td className="align-middle">{percentFormatter(metric.untrended)}</td>
                            <td className="align-middle">{percentFormatter(metric.trended)}</td>
                            <td className="align-middle">{percentFormatter(metric.trended)}</td>
                        </tr>
                    ))}
                </tbody>
            </NSTable>
        </div>
    );
};

export default ProFormaReturnsTable;
