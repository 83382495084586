import { IOptionAnyValue } from 'bricks/types';
import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const CAPITAL_RESERVES_OPTIONS = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
];

export const CALCULATION_OPTIONS: IOptionAnyValue[] = [
    { value: 'operating_expenses', label: 'Operating expenses' },
    { value: 'total_potential_income', label: 'Total potential income' },
    { value: 'effective_gross_revenue', label: 'Effective gross revenue' },
    { value: 'net_rental_income', label: 'Net rental income' },
    { value: 'net_operating_income', label: 'Net operating income' },
];

export const OPERATING_EXPENSES_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'annualGrowth', field: 'annualGrowth', header: 'Annual growth', format: 'percentage',
    },
    {
        key: 'effectiveGrossRevenue', field: 'effectiveGrossRevenue', header: '% of EGR', format: 'percentage',
    },
    {
        key: 'amountUnitYear', field: 'amountUnitYear', header: '$/Unit/year', format: 'currency',
    },
    {
        key: 'amountByYear', field: 'amountByYear', header: 'Amount/year', format: 'currency',
    },
    // TODO: this section would be changed after connect with the socket
    {
        key: 'column1', field: 'column1', header: 'Column 1', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: 'Column 2', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: 'Column 3', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: 'Column 4', isFixed: false, isReadOnly: true,
    },
];

export const NET_OPERATION_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Cash flow after financing', format: 'none', isReadOnly: true,
    },
    {
        key: 'annualPayment', field: 'annualPayment', header: '$4,310,61', format: 'none', isReadOnly: true,
    },
    {
        key: 'column1', field: 'column1', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: '31,368', isFixed: false, isReadOnly: true,
    },
];

export const CAPITAL_EXPENDITURES_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Capital expenditures', format: 'none',
    },
    {
        key: 'annualGrowth', field: 'annualGrowth', header: 'Annual growth', format: 'percentage',
    },
    {
        key: 'effectiveGrossRevenue', field: 'effectiveGrossRevenue', header: '% of EGR', format: 'percentage',
    },
    {
        key: 'amountUnitYear', field: 'amountUnitYear', header: '$/Unit/year', format: 'currency',
    },
    {
        key: 'amountByYear', field: 'amountByYear', header: 'Amount/year', format: 'currency',
    },
    // here start
    {
        key: 'column1', field: 'column1', header: 'Column 1', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: 'Column 2', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: 'Column 3', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: 'Column 4', isFixed: false, isReadOnly: true,
    },
];

export const TOTAL_CAPITAL_EXPENDITURES_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Total capital expenditures', format: 'none', isReadOnly: true,
    },
    {
        key: 'annualGrowth', field: 'annualGrowth', header: '', format: 'none', isReadOnly: true,
    },
    {
        key: 'effectiveGrossRevenue', field: 'effectiveGrossRevenue', header: '13.43', format: 'percentage', isReadOnly: true,
    },
    {
        key: 'amountUnitYear', field: 'amountUnitYear', header: '$83', format: 'currency', isReadOnly: true,
    },
    {
        key: 'amountByYear', field: 'amountByYear', header: '$25,000', format: 'currency', isReadOnly: true,
    },
    {
        key: 'column1', field: 'column1', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: '31,368', isFixed: false, isReadOnly: true,
    },
];

export const CASH_FLOW_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Cash flow from operations', format: 'none', isReadOnly: true,
    },
    {
        key: 'amountByYear', field: 'amountByYear', header: '$4,310,61', format: 'none', isReadOnly: true,
    },
    {
        key: 'column1', field: 'column1', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column2', field: 'column2', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column3', field: 'column3', header: '31,368', isFixed: false, isReadOnly: true,
    },
    {
        key: 'column4', field: 'column4', header: '31,368', isFixed: false, isReadOnly: true,
    },
];

export const OPERATING_EXPENSES_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'capitalReserves',
        title: 'Capital reserves',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '0.00',
        amountUnitYear: '776',
        amountByYear: '60,000',
        selectedCalculation: { value: 'operating_expenses', label: 'Operating expenses' },
    },
    {
        id: 'contractServices',
        title: 'Contract services',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '3.37',
        amountUnitYear: '800',
        amountByYear: '315,000',
    },
    {
        id: 'generalAdmin',
        title: 'General & administrative',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '4.00',
        amountUnitYear: '1,300',
        amountByYear: '395,100',
    },
    {
        id: 'insurance',
        title: 'Insurance',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '6.06',
        amountUnitYear: '900',
        amountByYear: '240,000',
    },
    {
        id: 'leasingCommissions',
        title: 'Leasing commissions',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '0.00',
        amountUnitYear: '833',
        amountByYear: '130,000',
    },
    {
        id: 'makeReadyCost',
        title: 'Make ready cost',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '0.00',
        amountUnitYear: '973',
        amountByYear: '280,000',
    },
    {
        id: 'managementFee',
        title: 'Management fee',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '0.00',
        amountUnitYear: '611',
        amountByYear: '105,000',
    },
    {
        id: 'marketing',
        title: 'Marketing',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '3.00',
        amountUnitYear: '633',
        amountByYear: '189,952',
    },
    {
        id: 'payroll',
        title: 'Payroll',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '3.00',
        amountUnitYear: '633',
        amountByYear: '189,952',
    },
    {
        id: 'propertyTaxes',
        title: 'Property taxes',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '3.00',
        amountUnitYear: '633',
        amountByYear: '189,952',
    },
    {
        id: 'repairsMaintenance',
        title: 'Repairs and maintenance',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '1.66',
        amountUnitYear: '350',
        amountByYear: '105,000',
    },
    {
        id: 'utilities',
        title: 'Utilities',
        annualGrowth: '3.0',
        effectiveGrossRevenue: '13.62',
        amountUnitYear: '2,875',
        amountByYear: '862,377',
    },
];

export const CAPITAL_EXPENDITURES_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'capitalExpenditures',
        title: 'Misc. reserves',
        annualGrowth: '49.3',
        effectiveGrossRevenue: '65.7',
        amountUnitYear: '383101.32',
        amountByYear: '34543.64',
        column1: '31,368',
    },
    {
        id: 'capitalExpenditures',
        title: 'Other capital expenditures',
        annualGrowth: '49.3',
        effectiveGrossRevenue: '65.7',
        amountUnitYear: '383101.32',
        amountByYear: '34543.64',
        column1: '31,368',
    },
];

export const formatDateWithMonthAndYear = (date: Date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
