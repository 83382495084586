import {
    NSCard, NSInput, NSInputNumberStepper, NSLabel, NSSelect,
} from 'bricks';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { formatUSDCurrency } from 'ns_libs/formatter';
import { FEE_TYPE_OPTIONS, INCLUDE_DEV_FEE_OPTIONS } from './constants';
import { ITopLevelAnalysis, ITopLevelAnalysisProps } from './types';
import './TopLevelAnalysis.scss';

const TopLevelAnalysis = ({ onChange }: ITopLevelAnalysisProps) => {
    const [includeDevFee, setIncludeDevFee] = useState(false);
    const [includeDevFeeOption, setIncludeDevFeeOption] = useState<IOptionAnyValue>(INCLUDE_DEV_FEE_OPTIONS[0]);

    const [waterfallDetails, setWaterfallDetails] = useState<ITopLevelAnalysis>({
        feeStart: null,
        aumFee: 0,
        aumFeePerMonth: 0,
        aumFeeType: FEE_TYPE_OPTIONS[0],
        developerFee: 0,
        showMultipleLP: false,
        showMultipleGP: false,
    });

    const handleWaterfallDetailChange = (key: string, value: any) => {
        setWaterfallDetails(prev => ({ ...prev, [key]: value }));
        if (key === 'showMultipleLP' || key === 'showMultipleGP') {
            onChange(key, value);
        }
    };

    const selectOption = (option: IOptionAnyValue) => {
        setIncludeDevFeeOption(option);
        setIncludeDevFee(option.value);
    };

    const labelClassName = 'pl-0 col-4 font-weight-normal text-dark align-self-center';
    const labelClassNameNoWrap = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';

    return (
        <div className="d-flex justify-content-end pb-3">
            <div className="TopLevelAnalysis__inputs-container w-50 pl-3">
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Include dev. fee</NSLabel>
                    <NSSelect
                        options={INCLUDE_DEV_FEE_OPTIONS}
                        name="includeDevFee"
                        value={includeDevFeeOption}
                        onChange={option => selectOption(option)}
                        isClearable={false}
                    />
                </div>

                <div className="d-flex align-items-center mt-2">
                    <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">AUM fee types</NSLabel>
                    <NSSelect
                        options={FEE_TYPE_OPTIONS}
                        name="aumFeeType"
                        value={waterfallDetails.aumFeeType}
                        onChange={option => handleWaterfallDetailChange('aumFeeType', option)}
                        isClearable={false}
                    />
                </div>

                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>AUM fee</NSLabel>
                    <NSInputNumberStepper
                        percentage
                        name="aumFee"
                        value={waterfallDetails.aumFee}
                        onChange={(value: number) => handleWaterfallDetailChange('aumFee', value)}
                        min={0}
                        step={1}
                        inputGroupClassName="TopLevelAnalysis__input-width"
                    />
                </div>

                {/* Fee Start */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>AUM fee start</NSLabel>
                    <NSInput
                        type="date"
                        name="feeStart"
                        value={waterfallDetails.feeStart?.toString()}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleWaterfallDetailChange('feeStart', event.target.value)}
                        onBlur={() => handleWaterfallDetailChange('feeStart', waterfallDetails.feeStart)}
                    />
                </div>

                {/* GP */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassName}>GP structure</NSLabel>
                    <div className="d-flex TopLevelAnalysis__toggle-buttons">
                        <Button
                            data-testid="no-retail-button"
                            className="NSButton TopLevelAnalysis__toggle-buttons--left-button"
                            onClick={() => handleWaterfallDetailChange('showMultipleGP', false)}
                            color={!waterfallDetails.showMultipleGP ? 'primary' : 'secondary'}
                        >
                            Basic
                        </Button>
                        <Button
                            data-testid="retail-button"
                            className="NSButton TopLevelAnalysis__toggle-buttons--right-button"
                            onClick={() => handleWaterfallDetailChange('showMultipleGP', true)}
                            color={waterfallDetails.showMultipleGP ? 'primary' : 'secondary'}
                        >
                            Multiple GPs
                        </Button>
                    </div>
                </div>

                {/* LP */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassName}>LP structure</NSLabel>
                    <div className="d-flex TopLevelAnalysis__toggle-buttons">
                        <Button
                            data-testid="no-retail-button"
                            className="NSButton TopLevelAnalysis__toggle-buttons--left-button"
                            onClick={() => handleWaterfallDetailChange('showMultipleLP', false)}
                            color={!waterfallDetails.showMultipleLP ? 'primary' : 'secondary'}
                        >
                            Basic
                        </Button>
                        <Button
                            data-testid="retail-button"
                            className="NSButton TopLevelAnalysis__toggle-buttons--right-button"
                            onClick={() => handleWaterfallDetailChange('showMultipleLP', true)}
                            color={waterfallDetails.showMultipleLP ? 'primary' : 'secondary'}
                        >
                            Multiple LPs
                        </Button>
                    </div>
                </div>
            </div>

            <div className="w-50">
                <RenderIf isTrue={includeDevFee}>
                    <NSCard className="NSCard--level-4 mr-3 mt-2">
                        <div className="TopLevelAnalysis_rounded flex-row justify-content-between align-items-center p-2">
                            <div className="text-muted">Developer fee</div>
                            <div className="text-white">{formatUSDCurrency(waterfallDetails.developerFee)}</div>
                        </div>
                    </NSCard>
                </RenderIf>
                <NSCard className="NSCard--level-4 mr-3 mt-2">
                    <div className="TopLevelAnalysis_rounded flex-row justify-content-between align-items-center p-2">
                        <div className="text-muted">AUM fee</div>
                        <div className="text-white">
                            {formatUSDCurrency(waterfallDetails.aumFeePerMonth)}
                            {' '}
                            per month
                        </div>
                    </div>
                </NSCard>
            </div>
        </div>
    );
};

export default TopLevelAnalysis;
