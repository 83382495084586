import React, { useCallback, useEffect, useMemo } from 'react';
import { NSButton, NSDropdown } from 'bricks';
import PageTitle from 'components/PageTitle/PageTitle';
import { ComparisonGrid, IGridConfig } from 'components/ComparisonGrid';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Deal } from 'views/Deals/types';
import { IOptionAnyValue } from 'bricks/types';
import { BOEScenario } from 'views/DealDashboard/BOE/types';
import { gridConfig, MAX_SCENARIOS_FOR_PDF } from './constants';
import './ScenarioComparison.scss';
import { useScenarioContext } from './context/ScenarioComparisonProvider';

const ScenarioComparisonManager = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const deal = location.state?.deal as Deal;
    const boeScenarios = location.state?.scenarios as BOEScenario[];
    const selectedScenario = location.state?.selectedScenario as BOEScenario;

    const {
        scenarios, selectedScenarios, addScenario, initializeData, getAvailableOptions, getSelectedScenarios, handlePdfExport,
    } = useScenarioContext();

    useEffect(() => {
        initializeData(
            boeScenarios?.map(({ id, ...boe }) => ({ ...boe, id: id?.toString() })),
            [{ label: selectedScenario.name, value: selectedScenario?.id?.toString() }],
        );
    }, []);

    const handleSelection = useCallback(
        (option: IOptionAnyValue) => {
            const selected = scenarios.find(sc => sc.id === option.value);
            if (selected) {
                addScenario(selected);
            }
        },
        [addScenario],
    );
    const gridData: IGridConfig = useMemo(() => ({ ...gridConfig, data: getSelectedScenarios() }), [selectedScenarios]);

    const handleGoBack = useCallback(() => {
        navigate(`/deal/${deal.id}`, { replace: true });
    }, [deal, navigate]);

    return (
        <div className="ScenarioComparison">
            <PageTitle title="Scenario comparison" subtitle={`Analyzing: ${deal.dealName}`}>
                <NSButton text="Exit" color="secondary" outline={false} callback={handleGoBack} className="mr-2" />
                <NSButton
                    id="export-comparison-pdf"
                    text="Export comparison"
                    icon={faDownload}
                    color="primary"
                    outline={false}
                    callback={() => handlePdfExport(deal)}
                    className="mx-2"
                    disabled={selectedScenarios.length > MAX_SCENARIOS_FOR_PDF}
                    disabledTooltip={`A maximum of ${MAX_SCENARIOS_FOR_PDF} scenarios can be exported`}
                />
            </PageTitle>
            <ComparisonGrid
                gridConfig={gridData}
                renderAddToComparison={() => (
                    <NSDropdown
                        disableOnSingleOption={false}
                        isFullWidth
                        options={getAvailableOptions()}
                        handleSelectOption={handleSelection}
                        placeholder="Select scenario"
                    />
                )}
                isEditMode
            />
        </div>
    );
};

export default ScenarioComparisonManager;
