import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import {
    NSButton, NSDropdown, NSInput, NSLabel, NSSelect,
} from 'bricks';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import { ProFormaModelIds } from 'constants/proForma';
import { proFormaModelParkingOptions, FORM_LABEL_CLASS_NAME } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { useGrossBuildableArea } from 'views/ProFormaWizard/BasicInformation/hooks/useGrossBuildableArea';
import CalculateSizeCard from 'views/ProFormaTable/components/ProForma/ProFormaGeneralInformation/CalculateSizeCard/CalculateSizeCard';
import classNames from 'classnames';
import DealDetails from 'components/DealDetails/DealDetails';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { IOptionAnyValue } from 'bricks/types';
import { IGrossBuildableAreaLot } from 'views/ProFormaTable/types';
import { numeralFormatter } from 'ns_libs/formatter';
import { stepOneValidationSchema } from 'views/ProFormaWizard/constants';
import { useGetProFormaWizardStep } from '../../hooks/useGetProFormaWizardStep';
import { useUpdateProFormaWizardStep } from '../../hooks/useUpdateProFormaWizardStep';
import './ProFormaWizardStepOne.scss';

export interface StepOneValues {
    landArea: number;
    buildings: number;
    buildableGrossSquareFootage: number;
    stories: number;
    sellingCosts: number;
    projectedCapRateAtSale: number;
    currentMarketCapRate: number;
    parking: IOptionAnyValue[];
    structuredParkingSpaces: number;
    surfaceParkingSpaces: number;
    trailerParkingSpaces: number;
    isFAR: boolean;
    floorAreaRatio: number;
    grossBuildableArea: number;
    startDate: Date | null;
    saleMonth: Date | null;
    landAreaUnit: string;
    grossBuildableUnit: string;
    calculateArea: boolean;
}

const LAND_AREA_UNITS = [
    { value: 'acres', label: 'acres' },
    { value: 'sqft', label: 'ft\u00B2' },
];

const ProFormaWizardStepOne = () => {
    const { registerStepHandler, setStepValidationStatus, isCurrentStepValid } = useProFormaWizard();
    const { dealId, proFormaId } = useParams<{ dealId: string; proFormaId: string }>();

    const { selectedOrganizationId } = useAuthContext();

    const { data: deal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const { data: stepData, isLoading } = useGetProFormaWizardStep({
        orgId: selectedOrganizationId!,
        proFormaId: proFormaId || '',
        stepNumber: 1,
    });

    const updateStep = useUpdateProFormaWizardStep();

    const {
        lots, createLot, updateLot, deleteLot, totalGrossBuildableArea, clearLots,
    } = useGrossBuildableArea([]);
    const hasGrossBuildableLots = Boolean(lots.length);

    // Asset type is hardcoded here – TODO: retrieve from API if needed.
    const [proFormaModelId] = useState<ProFormaModelIds>(ProFormaModelIds.APARTMENT);

    // UI state for controlling datepicker visibility and CalculateSizeCard toggle
    const [calculatorToggle, setCalculatorToggle] = useState(Boolean(stepData?.calculateArea));

    useEffect(() => {
        if (lots.length === stepData?.grossBuildableAreaLots.length) return;
        clearLots();
        if (stepData?.calculateArea && stepData?.grossBuildableAreaLots?.length) {
            stepData?.grossBuildableAreaLots.forEach((lot: IGrossBuildableAreaLot) => {
                const existingLot = lots.find(l => l.id === lot.id);
                if (!existingLot) {
                    createLot(lot.landArea, lot.floorAreaRatio, lot.id);
                }
            });
        }
    }, [stepData?.grossBuildableAreaLots]);

    const initialValues: StepOneValues = useMemo(() => {
        const initialParking = [];
        if (stepData?.structuredParkingSpaces !== null && stepData?.structuredParkingSpaces > 0) {
            initialParking.push({ value: 'structured', label: 'Structured parking' });
        }
        if (stepData?.surfaceParkingSpaces !== null && stepData?.surfaceParkingSpaces > 0) {
            initialParking.push({ value: 'surface', label: 'Surface parking' });
        }
        if (stepData?.trailerParkingSpaces !== null && stepData?.trailerParkingSpaces > 0) {
            initialParking.push({ value: 'trailer', label: 'Trailer parking' });
        }

        return {
            startDate: stepData?.startDate ? new Date(stepData.startDate) : null,
            saleMonth: stepData?.assumptions?.[0]?.saleMonth ? new Date(stepData.assumptions[0].saleMonth) : null,
            landArea: stepData?.landArea ?? 0,
            buildings: stepData?.buildings ?? 0,
            buildableGrossSquareFootage: stepData?.buildableGrossSquareFootage ?? 0,
            stories: stepData?.stories ?? 1,
            projectedCapRateAtSale: stepData?.assumptions?.[0]?.projectedCapRateAtSale ?? 0,
            currentMarketCapRate: stepData?.assumptions?.[0]?.currentMarketCapRate ?? 0,
            parking: initialParking,
            structuredParkingSpaces: stepData?.structuredParkingSpaces ?? 0,
            surfaceParkingSpaces: stepData?.surfaceParkingSpaces ?? 0,
            trailerParkingSpaces: stepData?.trailerParkingSpaces ?? 0,
            isFAR: stepData?.grossBuildableAreaLots?.length ? false : (stepData?.calculateArea ?? true),
            floorAreaRatio: stepData?.floorAreaRatio ?? 0,
            grossBuildableArea: stepData?.grossBuildableArea ?? 0,
            landAreaUnit: stepData?.landAreaUnit || 'acres',
            grossBuildableUnit: stepData?.grossBuildableUnit || 'acres',
            sellingCosts: stepData?.assumptions?.[0]?.sellingCosts ?? 6,
            calculateArea: stepData?.calculateArea ?? false,
        };
    }, [stepData]);

    // A custom validate function to include startDate and saleDate validations in addition to the Yup schema
    const validate = async (values: StepOneValues) => {
        const errors: Partial<Record<keyof StepOneValues, string>> = {};
        if (!values.startDate) {
            errors.startDate = 'Start date is required';
        }
        if (!values.saleMonth) {
            errors.saleMonth = 'Sale month is required';
        }
        try {
            await stepOneValidationSchema.validate(values, { abortEarly: false });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) {
                        errors[error.path as keyof StepOneValues] = error.message;
                    }
                });
            }
        }
        // Inform the wizard context of the validation status
        const isValid = Object.keys(errors).length === 0;
        if (isValid !== isCurrentStepValid) {
            setStepValidationStatus(isValid);
        }
        return errors;
    };

    const renderParkingSpaces = (
        type: string,
        label: string,
        value: number,
        setFieldValue: (field: string, value: number) => void,
        error: string | undefined,
        touched: boolean | undefined,
        min: number,
        values: StepOneValues,
        handleBlur: (e: React.FocusEvent<any>) => void,
    ) => (
        <RenderIf isTrue={values.parking?.find((park: IOptionAnyValue) => park.value === type)}>
            <div className="d-flex my-2 w-75 flex-column">
                <div className="d-flex">
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>{label}</NSLabel>
                    <div className="d-flex flex-column">
                        <NSInputNumberStepper
                            id={`${type}ParkingSpaces`}
                            name={`${type}ParkingSpaces`}
                            value={value}
                            onChange={(count: number) => setFieldValue(
                                `${type === 'structured' || type === 'car' ? 'structuredParkingSpaces' : 'surfaceParkingSpaces'}`,
                                count,
                            )}
                            min={min}
                            inputGroupClassName="ProFormaWizardStepOne__input-width"
                            step={1}
                            showErrorMsg={touched && !!error}
                            errorMsg={error || ''}
                            onBlur={() => handleBlur({ target: { name: `${type}ParkingSpaces` } } as React.FocusEvent<HTMLInputElement>)}
                        />
                    </div>
                </div>
            </div>
        </RenderIf>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!deal) {
        return <div>No deal selected</div>;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={stepOneValidationSchema}
            validate={validate}
            onSubmit={(values: StepOneValues, actions: FormikHelpers<StepOneValues>) => {
                // No explicit submission – the wizard will collect data via getData.
                actions.setSubmitting(false);
            }}
            validateOnChange
            validateOnBlur
            enableReinitialize
        >
            {formik => {
                const {
                    values: formikValues, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, validateForm,
                } = formik;

                const toggleShowLots = () => {
                    setCalculatorToggle(prev => {
                        const newToggleValue = !prev;
                        if (!prev && !hasGrossBuildableLots) {
                            createLot();
                        }
                        setFieldValue('calculateArea', newToggleValue);
                        return newToggleValue;
                    });
                };

                // When the totalGrossBuildableArea changes (from the calculator), update the Formik value.
                useEffect(() => {
                    if (hasGrossBuildableLots && totalGrossBuildableArea !== undefined && formikValues.grossBuildableArea !== totalGrossBuildableArea) {
                        setFieldValue('grossBuildableArea', totalGrossBuildableArea).then(() => {
                            setFieldTouched('grossBuildableArea', true);
                        });
                    }
                }, [totalGrossBuildableArea, formikValues.grossBuildableArea, setFieldValue, setFieldTouched, hasGrossBuildableLots]);

                const getData = useCallback(() => {
                    const data = {
                        ...formikValues,
                        assumptions: [
                            {
                                saleMonth: formikValues.saleMonth,
                                currentMarketCapRate: formikValues.currentMarketCapRate,
                                projectedCapRateAtSale: formikValues.projectedCapRateAtSale,
                                sellingCosts: formikValues.sellingCosts,
                            },
                        ],
                        // Include the lots data only if using calculator and not using FAR
                        grossBuildableAreaLots: formikValues.calculateArea ? lots : undefined,
                        calculateArea: formikValues.calculateArea,
                    };

                    updateStep.mutate({
                        orgId: selectedOrganizationId!,
                        proFormaId: proFormaId || '',
                        stepNumber: 1,
                        data,
                    });
                    return data;
                }, [formikValues, proFormaId, updateStep, lots, formikValues.isFAR, calculatorToggle]);

                // Register the step with the wizard context.
                useEffect(() => {
                    registerStepHandler(1, {
                        validate: async () => {
                            const errs = await validateForm();
                            return Object.keys(errs).length === 0;
                        },
                        getData,
                    });
                }, [registerStepHandler, validateForm, getData]);

                return (
                    <Form>
                        <div className="d-flex">
                            <div className="DealDeatails__wrapper mr-4">
                                <DealDetails
                                    isCompact
                                    deal={{
                                        ...deal,
                                        description: '',
                                    }}
                                    detailsArray={[
                                        { id: 1, label: 'Deal name', value: deal.dealName },
                                        { id: 2, label: 'Address', value: deal.address },
                                        { id: 3, label: 'City', value: deal.city },
                                        { id: 4, label: 'County', value: deal.county },
                                        { id: 5, label: 'State', value: deal.state },
                                        { id: 6, label: 'Zip', value: deal.zipCode },
                                    ]}
                                />
                            </div>
                            <div className="w-100">
                                {/* Start Date Section */}
                                <div className="w-50">
                                    <small>1 of 3</small>
                                    <div className="ProFormaWizardStepOne__title">Start Date</div>
                                    <div className="ProFormaWizardStepOne__subtitle">
                                        Determine start date for this analysis. Typically this is your acquisition closing. This date will be used as
                                        period 1 for cash flows.
                                    </div>
                                    <NSInput
                                        type="date"
                                        name="startDate"
                                        value={formikValues.startDate ? formikValues.startDate.toISOString().split('T')[0] : ''}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const date = event.target.value ? new Date(event.target.value) : null;
                                            setFieldValue('startDate', date);
                                        }}
                                        onBlur={handleBlur}
                                        inputClassName="ProFormaWizardStepOne__input-width mt-2"
                                        showErrorMsg={touched.startDate && !!errors.startDate}
                                        errorMsg={touched.startDate ? errors.startDate : ''}
                                    />
                                </div>
                                <div className="ProFormaWizardStepOne__separator" />
                                {/* Size Section */}
                                <div>
                                    <small>2 of 3</small>
                                    <div className="ProFormaWizardStepOne__title">Size</div>
                                    <div className="ProFormaWizardStepOne__subtitle">Details about this investment's property size and building</div>
                                    <div className="d-flex mt-2 w-75">
                                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Land area</NSLabel>
                                        <div className="d-flex flex-column w-50">
                                            <NSInput
                                                id="landArea"
                                                type="number"
                                                name="landArea"
                                                placeholder="Enter land area"
                                                value={formikValues.landArea || ''}
                                                inputGroupClassName="p-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                showErrorMsg={touched.landArea && !!errors.landArea}
                                                errorMsg={touched.landArea ? errors.landArea : ''}
                                                appendInputAddon={(
                                                    <NSDropdown
                                                        options={LAND_AREA_UNITS}
                                                        selectedOption={LAND_AREA_UNITS.find(option => option.value === formikValues.landAreaUnit)}
                                                        customSelectedOptionText={
                                                            LAND_AREA_UNITS.find(option => option.value === formikValues.landAreaUnit)?.label || ''
                                                        }
                                                        handleSelectOption={(option: IOptionAnyValue) => {
                                                            const selectedUnit = option.value;
                                                            const currentLandArea = formikValues.landArea;
                                                            let convertedLandArea = currentLandArea;
                                                            if (selectedUnit === 'sqft' && formikValues.landAreaUnit === 'acres') {
                                                                convertedLandArea = currentLandArea * 43560;
                                                            } else if (selectedUnit === 'acres' && formikValues.landAreaUnit === 'sqft') {
                                                                convertedLandArea = currentLandArea / 43560;
                                                            }
                                                            setFieldValue('landArea', Number(convertedLandArea.toFixed(2)));
                                                            setFieldValue('landAreaUnit', selectedUnit);
                                                        }}
                                                        badgeColor=""
                                                        isBadgeToggle
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex my-2 w-75">
                                        <NSLabel className={`d-flex ${FORM_LABEL_CLASS_NAME}`}>
                                            <NSButton
                                                color="secondary"
                                                outline={false}
                                                callback={() => setFieldValue('isFAR', true)}
                                                text="FAR"
                                                className={classNames('px-3', {
                                                    'border border-primary bg-primary-lighten': formikValues.isFAR,
                                                })}
                                            />
                                            <RenderIf
                                                isTrue={proFormaModelId === ProFormaModelIds.APARTMENT || proFormaModelId === ProFormaModelIds.OFFICE}
                                            >
                                                <NSButton
                                                    color="secondary"
                                                    outline={false}
                                                    callback={() => setFieldValue('isFAR', false)}
                                                    text="Gross build. area"
                                                    className={classNames({
                                                        'border border-primary bg-primary-lighten': !formikValues.isFAR,
                                                    })}
                                                />
                                            </RenderIf>
                                        </NSLabel>
                                        <RenderIf isTrue={formikValues.isFAR}>
                                            <div className="d-flex flex-column">
                                                <NSInputNumberStepper
                                                    id="floorAreaRatio"
                                                    name="floorAreaRatio"
                                                    value={Number(numeralFormatter(formikValues.floorAreaRatio, '0.00'))}
                                                    onChange={(count: number) => setFieldValue('floorAreaRatio', Number(count))}
                                                    min={0.1}
                                                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                                                    step={0.1}
                                                    showErrorMsg={touched.floorAreaRatio && !!errors.floorAreaRatio}
                                                    errorMsg={touched.floorAreaRatio ? errors.floorAreaRatio : ''}
                                                    onBlur={() => handleBlur({ target: { name: 'floorAreaRatio' } } as React.FocusEvent<HTMLInputElement>)}
                                                />
                                            </div>
                                        </RenderIf>
                                        <RenderIf isTrue={!formikValues.isFAR}>
                                            <div className="d-flex flex-column w-50">
                                                <NSInput
                                                    id="grossBuildableArea"
                                                    type="number"
                                                    name="grossBuildableArea"
                                                    value={formikValues.grossBuildableArea === 0 ? '' : formikValues.grossBuildableArea}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(
                                                        'grossBuildableArea', Number(e.target.value),
                                                    )}
                                                    placeholder={hasGrossBuildableLots ? 'Calculate buildable area' : 'Enter gross buildable area'}
                                                    inputGroupClassName="p-0"
                                                    disabled={hasGrossBuildableLots}
                                                    appendInputAddon={(
                                                        <NSDropdown
                                                            disabled={hasGrossBuildableLots}
                                                            options={LAND_AREA_UNITS}
                                                            selectedOption={
                                                                LAND_AREA_UNITS.find(option => option.value === formikValues.grossBuildableUnit) || {
                                                                    label: '',
                                                                    value: '',
                                                                }
                                                            }
                                                            // eslint-disable-next-line max-len
                                                            customSelectedOptionText={LAND_AREA_UNITS.find(option => option.value === formikValues.grossBuildableUnit)?.label || ''}
                                                            handleSelectOption={(option: IOptionAnyValue) => {
                                                                const selectedUnit = option.value;
                                                                const currentArea = formikValues.grossBuildableArea;
                                                                let convertedArea = currentArea;
                                                                if (selectedUnit === 'sqft' && formikValues.grossBuildableUnit === 'acres') {
                                                                    convertedArea = currentArea * 43560;
                                                                } else if (selectedUnit === 'acres' && formikValues.grossBuildableUnit === 'sqft') {
                                                                    convertedArea = currentArea / 43560;
                                                                }
                                                                setFieldValue('grossBuildableArea', Number(convertedArea.toFixed(2)));
                                                                setFieldValue('grossBuildableUnit', selectedUnit);
                                                            }}
                                                            badgeColor=""
                                                            isBadgeToggle
                                                            toggleClassName="p-0 m-0"
                                                        />
                                                    )}
                                                    showErrorMsg={touched.grossBuildableArea && !!errors.grossBuildableArea}
                                                    errorMsg={touched.grossBuildableArea ? errors.grossBuildableArea : ''}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </RenderIf>
                                    </div>

                                    <RenderIf isTrue={!formikValues.isFAR}>
                                        <div className="w-75">
                                            <CalculateSizeCard
                                                lots={lots}
                                                onCreateLot={createLot}
                                                onDeleteLot={(id: number) => {
                                                    deleteLot(id);
                                                    if (lots.length === 1) {
                                                        toggleShowLots();
                                                    }
                                                }}
                                                onUpdateLot={updateLot}
                                                totalGrossBuildableArea={totalGrossBuildableArea}
                                                toggleShowLots={toggleShowLots}
                                                showLots={formikValues.calculateArea}
                                            />
                                        </div>
                                    </RenderIf>

                                    <div className="d-flex align-items-center mt-2 w-75">
                                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Buildings</NSLabel>
                                        <div className="d-flex flex-column">
                                            <NSInputNumberStepper
                                                id="buildings"
                                                name="buildings"
                                                value={formikValues.buildings}
                                                onChange={(count: number) => setFieldValue('buildings', count)}
                                                min={1}
                                                step={1}
                                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                                                showErrorMsg={touched.buildings && !!errors.buildings}
                                                errorMsg={touched.buildings ? errors.buildings : ''}
                                                onBlur={() => handleBlur({ target: { name: 'buildings' } } as React.FocusEvent<HTMLInputElement>)}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-2 w-75">
                                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Built gross SF</NSLabel>
                                        <div className="d-flex flex-column w-50">
                                            <NSInput
                                                id="buildableGrossSquareFootage"
                                                name="buildableGrossSquareFootage"
                                                placeholder="Enter built gross SF"
                                                value={formikValues.buildableGrossSquareFootage === 0 ? '' : formikValues.buildableGrossSquareFootage}
                                                appendInputAddon={<div>ft&sup2;</div>}
                                                type="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                showErrorMsg={touched.buildableGrossSquareFootage && !!errors.buildableGrossSquareFootage}
                                                errorMsg={touched.buildableGrossSquareFootage ? errors.buildableGrossSquareFootage : ''}
                                            />
                                        </div>
                                    </div>
                                    <RenderIf isTrue={proFormaModelId === ProFormaModelIds.APARTMENT || proFormaModelId === ProFormaModelIds.OFFICE}>
                                        <div className="d-flex align-items-center mt-2 w-75">
                                            <NSLabel className={FORM_LABEL_CLASS_NAME}>
                                                {formikValues.buildings <= 1 ? 'Stories' : 'Avg. # of stories'}
                                            </NSLabel>
                                            <NSInputNumberStepper
                                                id="stories"
                                                name="stories"
                                                value={formikValues.stories}
                                                onChange={(count: number) => setFieldValue('stories', count)}
                                                min={1}
                                                step={1}
                                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                                                showErrorMsg={touched.stories && !!errors.stories}
                                                errorMsg={touched.stories ? errors.stories : ''}
                                                onBlur={() => handleBlur({ target: { name: 'stories' } } as React.FocusEvent<HTMLInputElement>)}
                                            />
                                        </div>
                                    </RenderIf>
                                    <div className="d-flex align-items-center mt-2 w-75">
                                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Parking</NSLabel>
                                        <div className="w-100">
                                            <NSSelect
                                                isMulti
                                                name="parking"
                                                options={proFormaModelParkingOptions[proFormaModelId]}
                                                value={formikValues.parking}
                                                onChange={options => setFieldValue('parking', options || [])}
                                                className="w-75"
                                            />
                                        </div>
                                    </div>
                                    {proFormaModelId === ProFormaModelIds.APARTMENT ? (
                                        <>
                                            {renderParkingSpaces(
                                                'structured',
                                                'Structured parking spaces',
                                                formikValues.structuredParkingSpaces,
                                                setFieldValue,
                                                errors.structuredParkingSpaces,
                                                touched.structuredParkingSpaces,
                                                1,
                                                formikValues,
                                                handleBlur,
                                            )}
                                            {renderParkingSpaces(
                                                'surface',
                                                'Surface parking spaces',
                                                formikValues.surfaceParkingSpaces,
                                                setFieldValue,
                                                errors.surfaceParkingSpaces,
                                                touched.surfaceParkingSpaces,
                                                1,
                                                formikValues,
                                                handleBlur,
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {renderParkingSpaces(
                                                'car',
                                                'Car parking spaces',
                                                formikValues.structuredParkingSpaces,
                                                setFieldValue,
                                                errors.structuredParkingSpaces,
                                                touched.structuredParkingSpaces,
                                                1,
                                                formikValues,
                                                handleBlur,
                                            )}
                                            {renderParkingSpaces(
                                                'trailer',
                                                'Trailer parking spaces',
                                                formikValues.surfaceParkingSpaces,
                                                setFieldValue,
                                                errors.surfaceParkingSpaces,
                                                touched.surfaceParkingSpaces,
                                                1,
                                                formikValues,
                                                handleBlur,
                                            )}
                                        </>
                                    )}
                                </div>
                                {/* Assumptions Section */}
                                <div className="ProFormaWizardStepOne__separator" />
                                <small>3 of 3</small>
                                <div className="ProFormaWizardStepOne__title">Assumptions</div>
                                <div className="ProFormaWizardStepOne__subtitle">Assumptions about the sale of this investment</div>
                                <div className="w-50">
                                    <div className="d-flex align-items-center mt-2">
                                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal mr-2">Sale month</NSLabel>
                                        <div className="d-flex flex-column w-50">
                                            <NSInput
                                                type="date"
                                                name="saleMonth"
                                                value={formikValues.saleMonth ? formikValues.saleMonth.toISOString().split('T')[0] : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const date = event.target.value ? new Date(event.target.value) : null;
                                                    setFieldValue('saleMonth', date);
                                                }}
                                                inputClassName="ProFormaWizardStepOne__input-width"
                                                onBlur={handleBlur}
                                                showErrorMsg={touched.saleMonth && !!errors.saleMonth}
                                                errorMsg={touched.saleMonth ? errors.saleMonth : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mt-2">
                                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal mr-2">
                                            Current market cap rate
                                        </NSLabel>
                                        <div className="d-flex flex-column">
                                            <NSInputNumberStepper
                                                id="currentMarketCapRate"
                                                name="currentMarketCapRate"
                                                value={Number(numeralFormatter(formikValues.currentMarketCapRate, '0.00'))}
                                                onChange={(count: number) => setFieldValue('currentMarketCapRate', count)}
                                                min={0.1}
                                                step={0.1}
                                                percentage
                                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                                                showErrorMsg={touched.currentMarketCapRate && !!errors.currentMarketCapRate}
                                                errorMsg={touched.currentMarketCapRate ? errors.currentMarketCapRate : ''}
                                                onBlur={() => handleBlur(
                                                    { target: { name: 'currentMarketCapRate' } } as React.FocusEvent<HTMLInputElement>,
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-2">
                                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal mr-2">
                                            Projected cap rate at sale
                                        </NSLabel>
                                        <div className="d-flex flex-column">
                                            <NSInputNumberStepper
                                                id="projectedCapRateAtSale"
                                                name="projectedCapRateAtSale"
                                                value={Number(numeralFormatter(formikValues.projectedCapRateAtSale, '0.00'))}
                                                onChange={(count: number) => setFieldValue('projectedCapRateAtSale', count)}
                                                min={0.1}
                                                step={0.1}
                                                percentage
                                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                                                showErrorMsg={touched.projectedCapRateAtSale && !!errors.projectedCapRateAtSale}
                                                errorMsg={touched.projectedCapRateAtSale ? errors.projectedCapRateAtSale : ''}
                                                onBlur={() => handleBlur(
                                                    { target: { name: 'projectedCapRateAtSale' } } as React.FocusEvent<HTMLInputElement>,
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-2">
                                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal mr-2">
                                            Selling costs
                                        </NSLabel>
                                        <div className="d-flex flex-column">
                                            <NSInputNumberStepper
                                                id="sellingCosts"
                                                name="sellingCosts"
                                                value={Number(numeralFormatter(formikValues.sellingCosts, '0.00'))}
                                                onChange={(count: number) => setFieldValue('sellingCosts', count)}
                                                min={1}
                                                step={1}
                                                percentage
                                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                                                showErrorMsg={touched.sellingCosts && !!errors.sellingCosts}
                                                errorMsg={touched.sellingCosts ? errors.sellingCosts : ''}
                                                onBlur={() => handleBlur({ target: { name: 'sellingCosts' } } as React.FocusEvent<HTMLInputElement>)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ProFormaWizardStepOne;
