/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import './ProFormaWizardStepThree.scss';

import { useAuthContext } from 'contexts/AuthContext';
import { NSCard, NSSwitch } from 'bricks';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { ProFormaModelIds } from 'constants/proForma';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import { INITIAL_EXPENSES } from 'views/ProFormaWizard/constants';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import RenderIf from 'components/RenderIf/RenderIf';
import { IExpense } from 'views/ProFormaWizard/BasicInformation/hooks/useExpenses';
import { useGetProFormaWizardStep } from 'views/ProFormaWizard/hooks/useGetProFormaWizardStep';
import { useUpdateProFormaWizardStep } from 'views/ProFormaWizard/hooks/useUpdateProFormaWizardStep';
import { useOtherIncomeManager } from './hooks/useOtherIncomeManager';
import { useUnitManager } from './hooks/useUnitManager';
import { ITenant, IUnitMix } from './types';
import OperatingInfo, { OperatingInfoValues } from './components/OperatingInfo/OperatingInfo';
import Expenses, { IExpenses } from './components/Expenses/Expenses';
import UnitMixOverviewCard from './components/UnitMixOverviewCard/UnitMixOverviewCard';
import TenantsOverviewCard from './components/TenantsOverviewCard/TenantsOverviewCard';
import OtherIncomeCard from './components/OtherIncomeCard/OtherIncomeCard';
import { getDefaultCreditLoss, getDefaultVacancy } from './helpers';

const ProFormaWizardStepThree = () => {
    const { selectedOrganizationId } = useAuthContext();
    const { registerStepHandler } = useProFormaWizard();
    const { dealId, proFormaId } = useParams<{ dealId: string; proFormaId: string }>();

    const { data: stepData } = useGetProFormaWizardStep({
        orgId: selectedOrganizationId!,
        proFormaId: proFormaId || '',
        stepNumber: 3,
    });

    const { data: deal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const [proFormaModelId] = useState<ProFormaModelIds>(ProFormaModelIds.APARTMENT); // TODO get from API
    const [expensesValues, setExpensesValues] = useState<IExpenses>({
        expenses: 0,
        totalExpenses: 0,
        expensesType: 'sf',
    });

    const initialOperatingInfoValues = useMemo(
        () => ({
            operationStartDate: stepData?.operationStartDate ? new Date(stepData.operationStartDate) : null,
            rentalGrowthPercentage: stepData?.rentalGrowthPercentage ?? 0,
            percentPreLeased: stepData?.percentPreLeased ?? 0,
            leaseUpPace: stepData?.leaseUpPace ?? 0,
            lengthOfLease: stepData?.lengthOfLease ?? 0,
            initialFreeRent: stepData?.initialFreeRent ?? 0,
            stabilizedFreeRent: stepData?.stabilizedFreeRent ?? 0,
            vacancy: stepData?.vacancy ?? getDefaultVacancy(proFormaModelId),
            creditLoss: stepData?.creditLoss ?? getDefaultCreditLoss(proFormaModelId),
            // TODO: Remove these fields from the API
            includePermanentDebt: stepData?.includePermanentDebt ?? false,
            isInterestOnly: stepData?.isInterestOnly ?? false,
            amortizationPercentage: stepData?.amortizationPercentage ?? 0,
            feesAndClosingCostsPercentage: stepData?.feesAndClosingCostsPercentage ?? 0,
        }),
        [stepData, proFormaModelId],
    );

    const [operatingInfoValues, setOperatingInfoValues] = useState(initialOperatingInfoValues);

    useEffect(() => {
        if (stepData) {
            setOperatingInfoValues(initialOperatingInfoValues);
        }
    }, [stepData, initialOperatingInfoValues]);

    const [expensesDetail, setExpensesDetail] = useState<IExpense[]>();

    const [showOtherIncomeToggle, setShowOtherIncomeToggle] = useState(false);

    const {
        unitArray, handleDuplicateOrAdd, handleDelete, handleUpdate,
    } = useUnitManager({
        proFormaModelId,
        initialUnits: stepData?.incomeUnitMixes || [],
    });
    const {
        incomeArray, handleAddOrDuplicateIncome, handleDeleteIncome, handleUpdateIncome,
    } = useOtherIncomeManager({
        initialIncomes: [],
    });

    const totalRentalIncome = unitArray.reduce((sum, unit) => sum + unit.totalRent!!, 0);
    const totalOtherIncome = incomeArray.reduce((sum, unit) => sum + unit.amountPerYear!!, 0);

    const toggleCalculate = () => setShowOtherIncomeToggle(prev => !prev);

    const validate = (currentValues: typeof operatingInfoValues) => {
        if (!currentValues.rentalGrowthPercentage || !currentValues.percentPreLeased) {
            alert('Please fill in all fields.');
            return false;
        }
        return true;
    };

    const updateStep = useUpdateProFormaWizardStep();

    const getData = () => {
        const transformedUnitArray = unitArray.map(unit => ({
            ...unit,
            averageSquareFootage: unit.averageSquareFootage,
            valuePerUnitPerMonth: unit.monthlyRent,
            units: unit.units,
        }));

        const data = {
            ...operatingInfoValues,
            incomeUnitMixes: transformedUnitArray,
            incomeArray,
            expensesValues,
            expensesDetail,
            // Not used but needed to avoid API errors
            includePermanentDebt: operatingInfoValues.includePermanentDebt ?? false,
            isInterestOnly: operatingInfoValues.isInterestOnly ?? false,
            amortizationPercentage: operatingInfoValues.amortizationPercentage ?? 0,
            feesAndClosingCostsPercentage: operatingInfoValues.feesAndClosingCostsPercentage ?? 0,
        };

        updateStep.mutate({
            orgId: selectedOrganizationId!,
            proFormaId: proFormaId || '',
            stepNumber: 3,
            data,
        });

        return data;
    };

    const renderCards = () => unitArray.map(unit => (proFormaModelId === ProFormaModelIds.APARTMENT ? (
        <div className="col-md-4" key={unit.id}>
            <UnitMixOverviewCard
                values={unit as IUnitMix}
                onDuplicate={() => handleDuplicateOrAdd(unit.id)}
                onDelete={() => handleDelete(unit.id)}
                onUpdate={handleUpdate}
            />
        </div>
    ) : (
        <div className="col-md-6" key={unit.id}>
            <TenantsOverviewCard
                values={unit as ITenant}
                onDuplicate={() => handleDuplicateOrAdd(unit.id)}
                onDelete={() => handleDelete(unit.id)}
                onUpdate={handleUpdate}
                count={unitArray.length}
            />
        </div>
    )));

    useEffect(() => {
        registerStepHandler(3, {
            validate: () => validate(operatingInfoValues),
            getData,
        });
    }, [registerStepHandler, operatingInfoValues, getData]);

    if (!deal) {
        return <div>No deal selected</div>;
    }

    const handleInputChange = (key: string, value: number | string) => {
        setExpensesValues(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    const handleDetailChange = (updatedExpenses: IExpense[]) => {
        setExpensesDetail(updatedExpenses);
    };

    const handleOperatingInfoChange = (key: keyof OperatingInfoValues, value: number | Date | null) => {
        setOperatingInfoValues(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    return (
        <div>
            <OperatingInfo onChange={handleOperatingInfoChange} values={operatingInfoValues} />
            <div className="ProFormaWizardStepOne__separator" />
            {/* Income */}
            <div className="ProFormaWizardStepOne__title">Income</div>
            <div className="ProFormaWizardStepOne__subtitle">Overview of this investment’s total income</div>
            <NSCard className="mt-2 p-2" title="Unit Mix">
                <div className="ProFormaWizardStepThree__unitTitle mb-2">{proFormaModelId === ProFormaModelIds.APARTMENT ? 'Unit Mix' : 'Tenants'}</div>
                <div className="row">
                    {renderCards()}
                    <div className={`col-md-${proFormaModelId === ProFormaModelIds.APARTMENT ? '4' : '6'} d-flex flex-column`}>
                        <NSCard
                            onClick={() => handleDuplicateOrAdd()}
                            className={`                               
                                cursor--pointer text-white align-items-center justify-content-center mb-2 flex-grow-1 
                                ${proFormaModelId === ProFormaModelIds.APARTMENT ? 'ProFormaWizardStepThree__addApartmentCard' : 'ProFormaWizardStepThree__addOtherCard'}
                            `}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" className="pb-1" />
                            <div>Add unit type</div>
                        </NSCard>
                    </div>
                </div>
                <NSCard className="NSCard--level-4">
                    <div className="d-flex justify-content-between text-white font-weight-bold p-2">
                        Total annual rental income
                        <div>{formatUSDCurrencyTwoDigits(totalRentalIncome)}</div>
                    </div>
                </NSCard>
            </NSCard>
            {/* Other income */}
            <NSCard className="mt-2 p-2" title="Unit Mix">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="ProFormaWizardStepThree__unitTitle">Other income</div>
                        <div className="ProFormaWizardStepOne__subtitle">Add additional income sources (e.g. RUBS, Parking)</div>
                    </div>
                    <div className="ProFormaWizardStepOne__subtitle align-self-center">
                        <NSSwitch
                            id="calculateToggle"
                            name="calculateToggle"
                            checked={showOtherIncomeToggle}
                            onChange={toggleCalculate}
                            label=""
                            containerClassName="text-dark"
                        />
                    </div>
                </div>

                <RenderIf isTrue={showOtherIncomeToggle}>
                    <div className="mt-2">
                        {incomeArray.map(income => (
                            <OtherIncomeCard
                                key={income.id}
                                values={income}
                                onUpdate={handleUpdateIncome}
                                onDelete={() => handleDeleteIncome(income.id)}
                            />
                        ))}
                        <NSCard
                            onClick={() => handleAddOrDuplicateIncome()}
                            className="ProFormaWizardStepThree__addOtherIncomeCard cursor--pointer text-white align-items-center justify-content-center mb-2 flex-grow-1"
                        >
                            <div className="py-3">
                                <FontAwesomeIcon icon={faPlus} className="pr-2" />
                                Add other income
                            </div>
                        </NSCard>
                    </div>
                    <NSCard className="NSCard--level-4">
                        <div className="d-flex justify-content-between text-white font-weight-bold p-2">
                            Total other income:
                            <div>{formatUSDCurrencyTwoDigits(totalOtherIncome)}</div>
                        </div>
                    </NSCard>
                </RenderIf>
            </NSCard>
            <div className="ProFormaWizardStepOne__separator" />
            <Expenses
                values={expensesValues}
                proFormaModelId={proFormaModelId}
                onChange={handleInputChange}
                onDetailChange={handleDetailChange}
                // TODO: Validate if this attributes are correct.
                dealSF={deal.grossSf}
                dealUnits={deal.residentialUnits}
                initialExpenses={expensesDetail ?? INITIAL_EXPENSES}
            />
        </div>
    );
};

export default ProFormaWizardStepThree;
