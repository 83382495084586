import { ProFormaGenericColumn, ProFormaGenericRow } from '../../ProFormaGenericTable/types';

export const PROPERTY_ROWS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'totalDevelopmentCost',
        property: 'Total development cost',
        amount: '1000',
        column1: '16343',
        column2: '16343',
        column3: '16343',
        column4: '16343',
        isReadOnly: true,
        tooltipMessage: 'Before interest reserve',
    },
    {
        id: 'totalInterestReserve',
        property: 'Total interest reserve',
        amount: '100120',
        column1: '213213',
        column2: '213213',
        column3: '213213',
        column4: '213213',
        isReadOnly: true,
        tooltipMessage: 'For debt during the development period, before lease-up income',
    },
];

export const PROPERTY_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'property', field: 'property', header: 'Property', showTooltip: true,
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency',
    },
    {
        key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: 'MON 20XX', isFixed: false,
    },
];

export const LOAN_ROWS_INTIIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'totalConstructionLoanDraws',
        name: 'Total construction loan draws',
        amount: '1000',
        column1: '16343',
        column2: '16343',
        column3: '16343',
        column4: '16343',
        isReadOnly: true,
    },
    {
        id: 'permanentLoanFunding',
        name: 'Permanent loan funding',
        amount: '100120',
        column1: '213213',
        column2: '213213',
        column3: '213213',
        column4: '213213',
        isReadOnly: true,
    },
];

export const LOAN_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', type: 'text', header: 'Name',
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency',
    },
    {
        key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: 'MON 20XX', isFixed: false,
    },
];

export const PROCEEDS_AND_PAYOFFS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'netSaleProceeds',
        name: 'Net sale proceeds',
        amount: '1000',
        column1: '16343',
        column2: '16343',
        column3: '16343',
        column4: '16343',
        isReadOnly: true,
    },
    {
        id: 'constructionLoanPayoff',
        name: 'Construction loan payoff',
        amount: '100120',
        column1: '213213',
        column2: '213213',
        column3: '213213',
        column4: '213213',
        isReadOnly: true,
        isNegative: true,
    },
    {
        id: 'permanentLoanPayoff',
        name: 'Permanent loan payoff',
        amount: '100120',
        column1: '213213',
        column2: '213213',
        column3: '213213',
        column4: '213213',
        isReadOnly: true,
        isNegative: true,
    },
    {
        id: 'equityProceeds',
        name: 'Equity proceeds from capital event(s)',
        amount: '100120',
        column1: '213213',
        column2: '213213',
        column3: '213213',
        column4: '213213',
        isReadOnly: true,
    },
];

export const PROCEEDS_AND_PAYOFFS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', type: 'text', header: 'Loan',
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency',
    },
    {
        key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: 'MON 20XX', isFixed: false,
    },
];

export const CASH_FLOW_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'netUnlevered',
        name: 'Net unlevered cash flow',
        equityMultiple: '1.80X',
        irr: '7.17',
        netProfit: '1000',
        column1: '16343',
        column2: '16343',
        column3: '16343',
        column4: '16343',
        isReadOnly: true,
    },
    {
        id: 'netLevered',
        name: 'Net levered cash flow',
        equityMultiple: '4.80X',
        irr: '7.17',
        netProfit: '1000',
        column1: '213213',
        column2: '213213',
        column3: '213213',
        column4: '213213',
        isReadOnly: true,
    },
];

export const CASH_FLOW_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', type: 'text', header: '',
    },
    {
        key: 'equityMultiple', field: 'equityMultiple', header: 'Equity multiple', type: 'text', format: 'none',
    },
    {
        key: 'irr', field: 'irr', header: 'IRR', format: 'percentage',
    },
    {
        key: 'netProfit', field: 'netProfit', header: 'Net profit', format: 'currency',
    },
    {
        key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: 'MON 20XX', isFixed: false,
    },
];

export const KEY_INVESTMENT_RATIOS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'yield-on-cost',
        name: 'Yield-on-cost',
        equityMultiple: '1.80X',
        untrended: '7.17',
        trended: '7.17',
        sale: '7.17',
        isReadOnly: true,
    },
    {
        id: 'marketCapRate',
        name: 'Market cap rate',
        untrended: '7.17',
        trended: '7.17',
        sale: '7.17',
        isReadOnly: true,
    },
];

export const KEY_INVESTMENT_RATIOS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', type: 'text', header: '',
    },
    {
        key: 'untrended', field: 'untrended', header: 'Untrended', format: 'percentage',
    },
    {
        key: 'trended', field: 'trended', header: 'Trended', format: 'percentage',
    },
    {
        key: 'sale', field: 'sale', header: 'Sale', format: 'percentage',
    },
];

export const DEVELOPMENT_SPREAD_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', type: 'text', header: '',
    },
    {
        key: 'untrended', field: 'untrended', header: 'Untrended', format: 'none',
    },
    {
        key: 'trended', field: 'trended', header: 'Trended', format: 'none',
    },
    {
        key: 'sale', field: 'sale', header: 'Sale', format: 'none',
    },
];

export const DEVELOPMENT_SPREAD_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'developmentSpread',
        name: 'Development spread',
        untrended: '181.0 bps',
        trended: '181.0 bps',
        sale: '181.0 bps',
        isReadOnly: true,
    },
];
