import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';
import { Deal } from 'views/Deals/types';
import { useUpdateDeal } from 'components/DealForm/hooks/useUpdateDeal';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { formatUSDCurrency, formatDate } from 'ns_libs/formatter';
import DealDetails from '../../../components/DealDetails/DealDetails';
import Timeline from './Timeline/Timeline';
import TargetReturns, { TargetReturnsValues } from './TargetReturns/TargetReturns';

interface IOverviewProps {
    deal: Deal;
}

const Overview = ({ deal }: IOverviewProps) => {
    const { selectedOrganizationId } = useAuthContext();

    const { mutate: updateDeal } = useUpdateDeal();
    const { dealId } = useParams<{ dealId: string }>();

    const detailsArray = useMemo(
        () => [
            { id: 1, label: 'County', value: deal.county },
            { id: 2, label: 'Market', value: deal.market?.name || '' },
            { id: 3, label: 'Submarket', value: deal.subMarket },
            { id: 4, label: 'Creation date', value: formatDate(deal.createdAt) },
            { id: 5, label: 'Deal type', value: deal.dealType?.name },
            { id: 6, label: 'Asset class', value: deal.assetType?.name || '' },
            { id: 7, label: 'Construction type', value: deal.constructionType?.name || '' },
            { id: 8, label: 'Parking type', value: deal.parkingType },
            { id: 9, label: 'Phase', value: deal?.phase?.name },
            { id: 10, label: 'Lead source', value: deal?.leadSource?.name },
            { id: 13, label: 'Leasing strategy', value: deal?.leasingStrategy?.name },
            { id: 14, label: 'Rank', value: deal?.rank?.name },
            { id: 15, label: 'Acquisition price', value: formatUSDCurrency(deal.acquisitionPrice) },
            { id: 16, label: 'IRR', value: deal.irr },
            { id: 17, label: 'Equity multiple', value: deal.equityMultiple },
            { id: 18, label: 'Created by', value: deal.creatorName },
            { id: 19, label: 'Deal owner', value: deal?.dealOwner?.name },
            { id: 20, label: 'Development manager', value: deal?.developmentManager?.name },
            { id: 21, label: 'Investor', value: deal.investors?.[0]?.name },
            { id: 22, label: 'Lender', value: deal.lenders?.[0]?.name },
        ],
        [deal],
    );

    const { refetch } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const handleTargetReturnsSave = (values: TargetReturnsValues) => {
        updateDeal(
            {
                orgId: selectedOrganizationId!,
                dealId: Number(deal.id),
                postData: { targetProfitReturn: values.profit, targetReturnOnCost: values.cost, targetReturnOnEquity: values.equity },
            },
            {
                onSuccess: data => {
                    console.log('Target returns updated successfully:', data);
                    window.alert('Target returns updated successfully!');
                    refetch();
                },
                onError: error => {
                    console.error('Error updating deal:', error);
                    window.alert('Failed to update target returns. Please try again.');
                },
            },
        );
    };

    return (
        <Row className="g-0 h-100">
            <Col md="4" className="d-flex flex-column h-100 border-end">
                <DealDetails deal={deal} detailsArray={detailsArray} />
            </Col>

            <Col md="8" className="d-flex flex-column h-100">
                <div className="flex-shrink-0">
                    <TargetReturns
                        onSave={handleTargetReturnsSave}
                        targetCost={deal.targetReturnOnCost}
                        targetEquity={deal.targetReturnOnEquity}
                        targetProfit={deal.targetProfitReturn}
                    />
                </div>
                <div className="flex-grow-1 mt-3">
                    <Timeline dealId={deal.id} />
                </div>
            </Col>
        </Row>
    );
};

export default Overview;
