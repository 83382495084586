import React, { useContext } from 'react';
import useGetFeatureAccess from 'hooks/useGetFeatureAccess';
import {
    AccessLevels, IFeatureAccess, IFeatureAccessMap, TAccessLevel, TFeatureId, TUserClassId,
} from 'types/auth';
import { config } from 'config';
import { ENVIRONMENTS } from 'services/getEnvironmentalVariables';
import useGetAuthenticatedUser from 'queries/UserMenu/useGetAuthenticatedUser';
import { USER_CLASSES } from 'services/constants';

export interface IAuthContext {
    selectedOrganizationId?: string;
    orgPermissions: IFeatureAccessMap;
    isLoadingAuthData: boolean;
    isFeatureAccessibleToUser: (featureIds: TFeatureId[], requiredAccessLevel: TAccessLevel) => boolean;
}

const defaultAuthContext: IAuthContext = {
    selectedOrganizationId: undefined,
    orgPermissions: {},
    isLoadingAuthData: true,
    isFeatureAccessibleToUser: () => false,
};

export const AuthContext = React.createContext<IAuthContext>(defaultAuthContext);

export const useAuthContext = () => useContext(AuthContext);
export default useAuthContext;

export const featureArrayToObject = (features: IFeatureAccess[] | undefined): IFeatureAccessMap => {
    if (!features) return {};
    return features.reduce((featuresObj, feature) => ({
        ...featuresObj,
        [feature.id]: feature,
    }), {} as IFeatureAccessMap);
};

export const isFeatureAccessible = (
    featureIds: number[],
    requiredAccessLevel: TAccessLevel,
    orgPermissions: IFeatureAccessMap,
    userClass: TUserClassId | undefined,
) => {
    if (userClass === USER_CLASSES.SUPER_ADMIN) return true;
    return featureIds.some(featureId => {
        const accessLevel = orgPermissions?.[featureId]?.accessLevel ?? AccessLevels.NO_ACCESS;
        return accessLevel >= requiredAccessLevel;
    });
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    // TODO use org switcher to get selected organization id
    let selectedOrganizationId = '2e84fc61-a945-41e6-a623-6e6f18be1712';
    if (config.environment === ENVIRONMENTS.STAGING) {
        selectedOrganizationId = 'f1423021-a183-473d-9282-4fd4097031f6';
    }

    const { data: orgPermissionsData, isLoading: isLoadingOrgPermissions } = useGetFeatureAccess(selectedOrganizationId!, {
        enabled: Boolean(selectedOrganizationId),
        staleTime: Infinity,
    });

    const orgPermissions = featureArrayToObject(orgPermissionsData);

    const { data: authenticatedUser, isLoading: isLoadingAuthenticatedUser } = useGetAuthenticatedUser();

    const isFeatureAccessibleToUser = (
        featureIds: TFeatureId[], requiredAccessLevel: TAccessLevel,
    ) => isFeatureAccessible(featureIds, requiredAccessLevel, orgPermissions, authenticatedUser?.user.userClass);

    return (
        <AuthContext.Provider
            value={{
                selectedOrganizationId: selectedOrganizationId || undefined,
                orgPermissions,
                isLoadingAuthData: isLoadingOrgPermissions || isLoadingAuthenticatedUser,
                isFeatureAccessibleToUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
