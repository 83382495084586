import React from 'react';
import './MonthlyAnalysisTable.scss';
import { NSTable } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { formatDate, getMonthNumber } from '../helper';

interface MonthlyAnalysisTableProps {
    data: {
        id: string;
        label: string;
        values: Array<{
            monthId: string;
            value: number;
        }>;
    }[];
    months: { id: string; date: Date | string }[];
}

const MonthlyAnalysisTable: React.FC<MonthlyAnalysisTableProps> = ({ data, months }) => (
    <RenderIf isTrue={data.length !== 0 && months.length !== 0}>
        <div className="MonthlyAnalysisTable__table-container d-flex">
            <div className="MonthlyAnalysisTable__fixed-section">
                <NSTable>
                    <thead>
                        <tr className="MonthlyAnalysisTable__table-header">
                            <th rowSpan={2}>
                                <div
                                    className="MonthlyAnalysisTable__table-header--first-item
                                        d-flex align-items-center justify-content-center text-white"
                                >
                                    Monthly Analysis
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="MonthlyAnalysisTable__table-cell text-white">Leased %</td>
                        </tr>
                        <tr>
                            <td className="MonthlyAnalysisTable__table-cell text-white">Economic Occ. %</td>
                        </tr>
                    </tbody>
                </NSTable>
            </div>
            <div className="MonthlyAnalysisTable__scrollable-section">
                <NSTable>
                    <thead>
                        <tr className="MonthlyAnalysisTable__table-header">
                            {months.map(date => (
                                <th key={date.id} className="px-2 py-1">
                                    <div className="MonthlyAnalysisTable__table-header--text text-muted font-weight-normal text-nowrap text-right">
                                        {getMonthNumber(date.date)}
                                    </div>
                                    <div className="MonthlyAnalysisTable__table-header--text text-white text-right">{formatDate(date.date)}</div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => (
                            <tr key={`${row.id}-${row.label}`}>
                                {row.values.map(value => (
                                    <td key={value.monthId} className="MonthlyAnalysisTable__table-cell text-white text-right">
                                        {value.value.toFixed(1)}
                                        %
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </NSTable>
            </div>
        </div>
    </RenderIf>
);

export default MonthlyAnalysisTable;
