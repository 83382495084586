import React, { useState, useEffect } from 'react';
import { CardHeader, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { NSButton, NSCard } from '../../bricks';
import RenderIf from '../RenderIf/RenderIf';

export interface IExpandableSectionProps {
    number?: number;
    title: string;
    subtitle: string;
    children: any;
    isExpanded?: boolean;
    className?: string;
    animate?: boolean;
    displayButton?: boolean;
    displayButtonText?: string;
    displayButtonCallback?: Function;
    customToggle?: React.ReactNode;
    onCustomToggle?: Function;
}

export const ExpandableSection = ({
    number,
    title,
    subtitle,
    children,
    isExpanded = false,
    className = '',
    animate = false,
    displayButton = false,
    displayButtonText = '',
    displayButtonCallback,
    customToggle,
    onCustomToggle,
}: IExpandableSectionProps) => {
    const [isExpandedState, setExpandedState] = useState(isExpanded);

    const toggleExpanded = () => {
        setExpandedState(!isExpandedState);
        if (onCustomToggle) {
            onCustomToggle();
        }
    };

    useEffect(() => {
        setExpandedState(isExpanded);
    }, [isExpanded]);

    const handleClickDisplayButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (displayButtonCallback) {
            displayButtonCallback();
        }
    };

    return (
        <NSCard className={`${className} shadow-none`}>
            <CardHeader>
                <div
                    className="d-flex align-items-center justify-content-between cursor--pointer text-white ReadOnlyWrapper--enable-pointer-events"
                    onClick={toggleExpanded}
                    onKeyDown={toggleExpanded}
                    role="button"
                    tabIndex={0}
                >
                    <div className="d-flex align-items-center">
                        <RenderIf isTrue={number}>
                            <h4 className="pl-0 pr-3 mb-0 text-muted">{number}</h4>
                        </RenderIf>
                        <div>
                            <h4 className="mb-0">{title}</h4>
                            <h5 className="mb-1 mt-1 text-muted">{subtitle}</h5>
                        </div>
                    </div>
                    <div>
                        <RenderIf isTrue={displayButton}>
                            <NSButton
                                text={displayButtonText}
                                className="NSButton--secondary-lighten mr-2"
                                callback={(event: React.MouseEvent<HTMLButtonElement>) => handleClickDisplayButton(event)}
                            />
                        </RenderIf>
                        <RenderIf isTrue={customToggle}>{customToggle}</RenderIf>
                        <RenderIf isTrue={!customToggle}>
                            <FontAwesomeIcon className="icon" icon={isExpandedState ? faCaretUp : faCaretDown} fixedWidth size="lg" />
                        </RenderIf>
                    </div>
                </div>
            </CardHeader>
            <Collapse isOpen={isExpandedState} enter={animate} exit={animate}>
                {children}
            </Collapse>
        </NSCard>
    );
};

export default ExpandableSection;
