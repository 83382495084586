import React, { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
    faLock, faSort, faSortUp, faSortDown,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import {
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';

import { useAuthContext } from 'contexts/AuthContext';
import { IProForma } from 'queries/ProForma/useGetProFormasByDeal';
import RenderIf from 'components/RenderIf/RenderIf';
import { formatUTCtoLocal } from 'ns_libs/formatter';
import { NSBadge, NSCard, NSModal } from 'bricks';
import { URLS } from 'services/urls';
import { useDuplicateProForma, useHardDeleteProForma, useUpdateProForma } from 'queries/ProForma';
import NSTable from '../../../../bricks/NSTable/NSTable';
import {
    headerCellClassName,
    PRO_FORMA_ELLIPSIS_OPTIONS,
    PRO_FORMA_STATUS_DRAFT,
    PRO_FORMA_STATUS_IN_PROGRESS,
    PRO_FORMA_STATUS_MAPPING,
    PRO_FORMA_STATUS_PROJECT_CREATED,
    PRO_FORMA_SET_AS_ACTIVE,
    PRO_FORMAS_TABLE_HEADERS,
} from '../../constants';

export interface INorthspyreProFormasTableProps {
    proFormas: IProForma[];
}

const NorthspyreProFormasTable = ({ proFormas }: INorthspyreProFormasTableProps) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { selectedOrganizationId } = useAuthContext();
    const { dealId } = useParams<{ dealId: string }>();

    const draftOrInProgressStatus = new Set([PRO_FORMA_STATUS_DRAFT, PRO_FORMA_STATUS_IN_PROGRESS]);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [proFormaToDelete, setProFormaToDelete] = useState<{ [key: string]: number }>({});
    const [sortedBy, setSortedBy] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

    const sortedProFormas = React.useMemo(() => {
        if (!proFormas) return [];
        if (!sortedBy) return proFormas;

        return [...proFormas].sort((a, b) => {
            let aValue = a[sortedBy.key as keyof IProForma] || '';
            let bValue = b[sortedBy.key as keyof IProForma] || '';
            if (typeof aValue === 'object') aValue = a?.name;
            if (typeof bValue === 'object') bValue = b?.name;
            if (aValue < bValue) return sortedBy.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortedBy.direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, [proFormas, sortedBy]);

    const handleSort = (key: string) => {
        setSortedBy(prevConfig => {
            if (prevConfig?.key === key) {
                return { key, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    const getSortIcon = (key: string) => {
        if (sortedBy?.key !== key) return faSort;
        return sortedBy.direction === 'asc' ? faSortUp : faSortDown;
    };

    const { mutate: updateProForma } = useUpdateProForma();
    const handleUpdateProForma = (proFormaId: number, patchData: Object) => {
        updateProForma(
            { dealId: Number(dealId), proFormaId, patchData },
            {
                onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getProFormas'] }),
            },
        );
    };

    const { mutate: hardDeleteProForma } = useHardDeleteProForma();
    const handleHardDeleteProForma = async (proFormaId: number) => hardDeleteProForma({ organizationId: selectedOrganizationId!, proFormaId });

    const { mutate: duplicateProForma } = useDuplicateProForma();
    const handleDuplicateProForma = async (proFormaId: number) => duplicateProForma({ organizationId: selectedOrganizationId!, proFormaId });

    const handleEllipsisOption = (option: string, proFormaId: number, proFormaStatusId: number) => {
        if (option === 'Duplicate') handleDuplicateProForma(proFormaId);
        if (option === 'Set as active') handleUpdateProForma(proFormaId, { isActive: true });
        if (option === 'Delete') {
            setProFormaToDelete({ id: proFormaId, proFormaStatusId });
            setShowDeleteConfirmationModal(true);
        }
    };

    const handleConfirmDelete = () => {
        if (draftOrInProgressStatus.has(proFormaToDelete?.proFormaStatusId)) handleHardDeleteProForma(proFormaToDelete?.id);
        else handleUpdateProForma(proFormaToDelete?.id, { deletedAt: new Date() });
        setShowDeleteConfirmationModal(false);
    };

    const getProFormaEllipsisOptions = (status: number, isActive: boolean) => {
        if (!isActive && !draftOrInProgressStatus.has(status)) return [...PRO_FORMA_ELLIPSIS_OPTIONS[status], PRO_FORMA_SET_AS_ACTIVE];
        return PRO_FORMA_ELLIPSIS_OPTIONS[status];
    };

    const redirectToProForma = (proFormaId: number) => {
        const path = generatePath(URLS.PRO_FORMA.TABLE, { dealId, proFormaId });
        navigate(path);
    };

    return (
        <>
            <NSTable className="NSTable--no-footer">
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        {PRO_FORMAS_TABLE_HEADERS.map(header => (
                            <th key={header.key} className="NSTable__thead__tr__th" onClick={() => handleSort(header.key)}>
                                <div className={headerCellClassName}>
                                    <span>{header.name}</span>
                                    <FontAwesomeIcon className="cursor--pointer" icon={getSortIcon(header.key)} />
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="NSTable__tbody">
                    {sortedProFormas.map(proForma => (
                        <tr key={proForma.id} className="NSTable__tbody__tr text-dark">
                            <td>
                                <RenderIf isTrue={proForma.proFormaStatusId === PRO_FORMA_STATUS_PROJECT_CREATED}>
                                    <FontAwesomeIcon icon={faLock} className="mr-2" />
                                </RenderIf>
                                <span role="presentation" className="text-primary cursor--pointer" onClick={() => redirectToProForma(proForma.id)}>
                                    {proForma.name}
                                </span>
                                <RenderIf isTrue={proForma.isActive}>
                                    <NSBadge className="ml-2" color="primary-lighten">
                                        Active
                                    </NSBadge>
                                </RenderIf>
                            </td>
                            <td>{proForma.boeScenarioId ? proForma.boeScenario?.name : 'n/a'}</td>
                            <td>
                                {formatUTCtoLocal(proForma.updatedAt, 'M/d/yy h:mm a')}
                                <span>{proForma.updatedBy?.name && ` by ${proForma.updatedBy.name}`}</span>
                            </td>
                            <td>{formatUTCtoLocal(proForma.createdAt, 'M/d/yy')}</td>
                            <td>{proForma.createdBy?.name || 'n/a'}</td>
                            <td className="d-flex justify-content-between align-items-center">
                                <NSBadge color={PRO_FORMA_STATUS_MAPPING[proForma.proFormaStatusId].color}>
                                    {PRO_FORMA_STATUS_MAPPING[proForma.proFormaStatusId].name}
                                </NSBadge>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="div">
                                        <FontAwesomeIcon icon={faEllipsisV} className="cursor--pointer" role="button" />
                                    </DropdownToggle>
                                    <DropdownMenu container="body" className="p-0 m-0 bg-transparent z-index-100">
                                        <NSCard className="NSCard--level-5 shadow-none">
                                            {getProFormaEllipsisOptions(proForma.proFormaStatusId, proForma.isActive).map(option => (
                                                <DropdownItem
                                                    key={`${option}-${proForma.id}`}
                                                    onClick={() => handleEllipsisOption(option, proForma.id, proForma.proFormaStatusId)}
                                                    className="text-dark"
                                                >
                                                    {option}
                                                </DropdownItem>
                                            ))}
                                        </NSCard>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </NSTable>
            <NSModal
                isOpen={showDeleteConfirmationModal}
                toggle={() => setShowDeleteConfirmationModal(false)}
                modalHeaderText="Delete pro forma"
                modalHeaderClassName="text-white bg-danger"
                modalFooterButtonText="Delete"
                modalFooterButtonColor="danger"
                modalFooterCancelButtonText="Cancel"
                modalFooterButtonFunction={handleConfirmDelete}
            >
                <div className="mb-1">
                    <div className="text-white">Are you sure you want to delete this pro forma?</div>
                </div>
            </NSModal>
        </>
    );
};

export default NorthspyreProFormasTable;
