import React from 'react';
import { NSCard } from 'bricks';
import { formatBOEValues, generateScenarioInformation } from '../helper';
import { BOEScenario } from '../types';
import './ScenarioInformation.scss';

export interface IScenarioInformationProps {
    boeScenario: BOEScenario;
}

const ScenarioInformation = ({ boeScenario }: IScenarioInformationProps) => {
    const scenarioInformation = generateScenarioInformation(boeScenario);

    return (
        <div>
            <span className="text-muted">SCENARIO INFORMATION</span>
            {scenarioInformation.map(row => (
                <div key={row[0]?.title[0]} className="d-flex flex-row py-1 ScenarioInformation">
                    {row.map(section => (
                        <NSCard key={section.title[0]} className="NSCard--level-4 py-2 px-3 flex-grow-1">
                            {section.title && (
                                <div>
                                    <div className="d-flex flex-row ScenarioInformation__mainInfo">
                                        {section.title.map((title, idx) => (
                                            <div key={`${title}-title`}>
                                                <span className="text-muted">{title}</span>
                                                <h3 className="text-white bold mt-0 mb-2">
                                                    {formatBOEValues(Number(section.value[idx]), section.formatter[idx])}
                                                    {section.placeholder && <span className="text-muted h4 pl-1">{section.placeholder}</span>}
                                                </h3>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {section.rows.map(row => (
                                <div key={row[0]?.label} className="d-flex flex-row py-2 ScenarioInformation__subItem">
                                    {row.map(
                                        item => item.value !== null && (
                                            <NSCard key={item.label} className="NSCard--level-5 p-2 flex-grow-1">
                                                <div className="text-start">
                                                    <span className="text-muted">{item.label}</span>
                                                    <div className="text-white bold">
                                                        {item.placeholder
                                                            ? `${formatBOEValues(Number(item.value), item.formatter)} ${item.placeholder}`
                                                            : formatBOEValues(Number(item.value), item.formatter)}
                                                    </div>
                                                </div>
                                            </NSCard>
                                        ),
                                    )}
                                </div>
                            ))}
                        </NSCard>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ScenarioInformation;
