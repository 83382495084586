import React, {
    createContext, useContext, useState, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface StepHandler {
    validate: () => boolean | Promise<boolean>;
    getData: () => any;
}

interface ProFormaWizardContextType {
    currentStep: number;
    stepHandlers: Record<number, StepHandler>;
    stepData: Record<number, any>;
    registerStepHandler: (step: number, handler: StepHandler) => void;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    getStepData: (step: number) => any;
    setStepData: (step: number, data: any) => void;
    isCurrentStepValid: boolean;
    setStepValidationStatus: (isValid: boolean) => void;
}

interface ProFormaWizardProviderProps {
    children: React.ReactNode;
}

const ProFormaWizardContext = createContext<ProFormaWizardContextType | null>(null);

export const ProFormaWizardProvider: React.FC<ProFormaWizardProviderProps> = ({ children }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [stepData, setStepDataState] = useState<Record<number, any>>({});
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    const stepHandlers = useRef<Record<number, StepHandler>>({});
    const navigate = useNavigate();

    const totalSteps = 3;

    const setStepData = (step: number, data: any) => {
        setStepDataState(prev => ({ ...prev, [step]: data }));
    };

    const getStepData = (step: number) => stepData[step] || null;

    const registerStepHandler = (step: number, handler: StepHandler) => {
        stepHandlers.current[step] = handler;
    };

    const handleNextStep = async () => {
        const handler = stepHandlers.current[currentStep];

        // Validate current step
        if (handler?.validate && !(await handler.validate())) {
            console.error(`Validation failed for step ${currentStep}`);
            return;
        }

        try {
            if (handler?.getData) {
                handler.getData(); // This will trigger the update mutation in the step component
            }

            if (currentStep === totalSteps) {
                navigate('/');
            } else {
                setCurrentStep(prev => prev + 1);
            }
        } catch (error) {
            console.error(`Error processing step ${currentStep}:`, error);
        }
    };

    const handlePrevStep = () => {
        if (currentStep === 1) {
            navigate('/'); // Replace `/` with the desired exit route
        } else {
            setCurrentStep(prev => prev - 1);
        }
    };

    const setStepValidationStatus = (isValid: boolean) => {
        setIsCurrentStepValid(isValid);
    };

    return (
        <ProFormaWizardContext.Provider
            value={{
                currentStep,
                stepHandlers: stepHandlers.current,
                stepData,
                registerStepHandler,
                handleNextStep,
                handlePrevStep,
                setStepData,
                getStepData,
                isCurrentStepValid,
                setStepValidationStatus,
            }}
        >
            {children}
        </ProFormaWizardContext.Provider>
    );
};

export const useProFormaWizard = (): ProFormaWizardContextType => {
    const context = useContext(ProFormaWizardContext);
    if (!context) {
        throw new Error('useProFormaWizard must be used within a ProFormaWizardProvider');
    }
    return context;
};
