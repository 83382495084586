import { useMutation } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IFormValues {
    landArea: number;
    landAreaUnitTypeId: number;
    floorAreaRatio: number;
    proFormaId: number;
}

interface IProFormaValues {
    name?: string;
    description?: string;
    dealName?: string;
    address?: string;
    city?: string;
    county?: string;
    state?: string;
    zipCode?: string;
    startDate?: string;
    saleDate?: string;
    landArea?: number;
    landAreaUnitTypeId?: string;
    floorAreaRatio?: number;
    grossBuildableArea?: number;
    buildings?: number;
    averageStories?: number;
    builtGrossSquareFootage?: number;
    structuredParkingSpaces?: number;
    surfaceParkingSpaces?: number;
    isActive?: boolean;
    deletedAt?: string;
}

interface IUpdateVariables {
    patchData: IProFormaValues;
    dealId: number;
    proFormaId: number;
}

const updateProForma = async ({ patchData, dealId, proFormaId }: IUpdateVariables): Promise<IFormValues> => {
    const response = await axiosInstance.patch(`${API_ENDPOINTS.PRO_FORMA(dealId, proFormaId)}`, patchData);
    return response.data;
};

const useUpdateProForma = () => useMutation({
    mutationFn: updateProForma,
});

export default useUpdateProForma;
