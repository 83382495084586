import React, { useCallback, useMemo } from 'react';
import './Sources.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { formatUSDCurrency } from 'ns_libs/formatter';
import { NSButton, NSCard } from 'bricks';
import Source from './Source/Source';
import { ISourcesProps } from './Source/types';
import { sourceTypeOptions } from './Source/constants';
import {
    addNewSource, createTraunchOptions, removeSourceById, updateSourceField,
} from './helpers';

const Sources = ({
    totalUses, totalSources, sources, onChange,
}: ISourcesProps) => {
    const traunchOptions = useMemo(() => createTraunchOptions(sources.length), [sources]);

    const handleChangeSource = useCallback(
        (id: string, field: string, value: any) => {
            const updatedSources = updateSourceField(sources, id, field, value, totalUses);
            onChange(updatedSources);
        },
        [sources, totalUses, onChange],
    );

    const handleRemoveSource = useCallback(
        (id: string) => {
            const updatedSources = removeSourceById(sources, id);
            onChange(updatedSources);
        },
        [sources, onChange],
    );

    const handleAddSource = useCallback(() => {
        const updatedSources = addNewSource(sources);
        onChange(updatedSources);
    }, [sources, onChange]);

    return (
        <div className="Sources">
            <div className="d-flex align-items-center my-2">
                <div>
                    <div>2 of 2</div>
                    <div className="Sources__title text-white my-0 font-weight-bold">Sources</div>
                    <div className="Sources__subtitle">Add any information about this investment's sources of funds</div>
                </div>
            </div>

            {sources.map(source => (
                <Source
                    key={source.id}
                    source={source}
                    onChange={handleChangeSource}
                    onRemove={handleRemoveSource}
                    traunchOptions={traunchOptions}
                    typeOptions={sourceTypeOptions}
                />
            ))}

            <NSButton className="d-flex align-items-center my-2 Sources__add_source" callback={handleAddSource}>
                <FontAwesomeIcon icon={faPlus} />
                <p>Add source</p>
            </NSButton>

            <NSCard className="Sources__total d-flex flex-row align-items-center my-2 Sources__total_row">
                <span className="font-weight-bold text-white">Total sources:</span>
                <span className="font-weight-bold text-white">{formatUSDCurrency(totalSources)}</span>
            </NSCard>
        </div>
    );
};

export default Sources;
