import React, { useState } from 'react';
import ProFormaGenericTable from '../../ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericRow } from '../../ProFormaGenericTable/types';
import { useScrollableSync } from '../../../hooks/useScrollableSync';
import './Returns.scss';
import {
    CASH_FLOW_COLUMNS,
    CASH_FLOW_INITIAL_STATE,
    DEVELOPMENT_SPREAD_COLUMNS,
    DEVELOPMENT_SPREAD_INITIAL_STATE,
    KEY_INVESTMENT_RATIOS_COLUMNS,
    KEY_INVESTMENT_RATIOS_INITIAL_STATE,
    LOAN_COLUMNS,
    LOAN_ROWS_INTIIAL_STATE,
    PROCEEDS_AND_PAYOFFS_COLUMNS,
    PROCEEDS_AND_PAYOFFS_INITIAL_STATE,
    PROPERTY_COLUMNS,
    PROPERTY_ROWS_INITIAL_STATE,
} from './constants';

const Returns = () => {
    const [propertyRows, setPropertyRows] = useState<ProFormaGenericRow[]>(PROPERTY_ROWS_INITIAL_STATE);
    const [loanRows, setLoanRows] = useState<ProFormaGenericRow[]>(LOAN_ROWS_INTIIAL_STATE);
    const [proceedsAndPayoffsRows, setProceedsAndPayoffsRows] = useState<ProFormaGenericRow[]>(PROCEEDS_AND_PAYOFFS_INITIAL_STATE);
    const [cashFlowRows, setCashFlowRows] = useState<ProFormaGenericRow[]>(CASH_FLOW_INITIAL_STATE);
    const [keyInvestmentRatios, setKeyInvestmentRatios] = useState<ProFormaGenericRow[]>(KEY_INVESTMENT_RATIOS_INITIAL_STATE);
    const [developmentRows, setDevelopmentRows] = useState<ProFormaGenericRow[]>(DEVELOPMENT_SPREAD_INITIAL_STATE);

    const { handleScroll, handleScrollableRef } = useScrollableSync();

    return (
        <div className="Returns">
            <ProFormaGenericTable
                columns={PROPERTY_COLUMNS}
                rows={propertyRows}
                setRows={setPropertyRows}
                hideAddButton
                hideTotalFooter
                onScrollableRef={handleScrollableRef(0)}
                onScroll={handleScroll}
            />
            <div className="pt-1">
                <ProFormaGenericTable
                    columns={LOAN_COLUMNS}
                    rows={loanRows}
                    setRows={setLoanRows}
                    hideAddButton
                    hideTotalFooter
                    hideHeader
                    onScrollableRef={handleScrollableRef(1)}
                    onScroll={handleScroll}
                />
            </div>
            <div className="pt-1">
                <ProFormaGenericTable
                    columns={PROCEEDS_AND_PAYOFFS_COLUMNS}
                    rows={proceedsAndPayoffsRows}
                    setRows={setProceedsAndPayoffsRows}
                    hideAddButton
                    hideTotalFooter
                    hideHeader
                    firstColumnWidth="460px"
                    onScrollableRef={handleScrollableRef(2)}
                    onScroll={handleScroll}
                />
            </div>
            <div className="pt-1">
                <ProFormaGenericTable
                    columns={CASH_FLOW_COLUMNS}
                    rows={cashFlowRows}
                    setRows={setCashFlowRows}
                    hideAddButton
                    hideTotalFooter
                    onScrollableRef={handleScrollableRef(3)}
                    onScroll={handleScroll}
                />
                <div className="Returns__spacer" />
            </div>
            <div className="pt-1">
                <ProFormaGenericTable
                    columns={KEY_INVESTMENT_RATIOS_COLUMNS}
                    rows={keyInvestmentRatios}
                    setRows={setKeyInvestmentRatios}
                    hideAddButton
                    hideTotalFooter
                    onScrollableRef={handleScrollableRef(4)}
                    onScroll={handleScroll}
                />
                <ProFormaGenericTable
                    columns={DEVELOPMENT_SPREAD_COLUMNS}
                    rows={developmentRows}
                    setRows={setDevelopmentRows}
                    hideAddButton
                    hideTotalFooter
                    hideHeader
                    onScrollableRef={handleScrollableRef(5)}
                    onScroll={handleScroll}
                />
            </div>
        </div>
    );
};

export default Returns;
