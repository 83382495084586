import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IZipCodeMSA } from 'types/market';

const getMarketByZip = async (zipCode: string): Promise<IZipCodeMSA> => {
    const response = await axiosInstance.get<IZipCodeMSA>(API_ENDPOINTS.MSA_BY_ZIP_CODE(zipCode));
    return response.data;
};

export const useGetMarketByZip = (zipCode: string, options?: Omit<UseQueryOptions<IZipCodeMSA>, 'queryKey' | 'queryFn'>) => useQuery<IZipCodeMSA, Error>({
    queryKey: [QUERY_KEYS.MSAS, zipCode],
    queryFn: () => getMarketByZip(zipCode),
    staleTime: Infinity,
    ...(options ?? {}),
});
