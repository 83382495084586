/* eslint-disable no-unused-vars */
import * as Yup from 'yup';

export enum ASSET_TYPE {
    RENTAL_APARTMENT = 'Rental Apartment',
    BUILT_FOR_RENT_SFH = 'Single Family Home / Built for Rent SFH',
    MIXED_USE_APARTMENT_OVER_RETAIL = 'Mixed Use - Residential over retail',
    SENIOR_LIVING_FACILITY = 'Healthcare',
    INDUSTRIAL = 'Industrial',
    DATA_CENTER = 'Data Center',
    RETAIL = 'Retail',
    OFFICE = 'Office',
}

export const ASSET_TYPE_MAPPING: Record<string, 'apartments' | 'office' | 'retail' | 'industrial' | 'data_center'> = {
    [ASSET_TYPE.RENTAL_APARTMENT]: 'apartments',
    [ASSET_TYPE.BUILT_FOR_RENT_SFH]: 'apartments',
    [ASSET_TYPE.MIXED_USE_APARTMENT_OVER_RETAIL]: 'apartments',
    [ASSET_TYPE.SENIOR_LIVING_FACILITY]: 'apartments',
    [ASSET_TYPE.INDUSTRIAL]: 'industrial',
    [ASSET_TYPE.DATA_CENTER]: 'data_center',
    [ASSET_TYPE.RETAIL]: 'retail',
    [ASSET_TYPE.OFFICE]: 'office',
};

export enum COST_BASIS_TYPES {
    PER_SF = 'sf',
    PER_UNIT = 'unit',
}

export const COST_BASIS_OPTIONS = [
    {
        label: 'per unit',
        value: COST_BASIS_TYPES.PER_UNIT,
    },
    {
        label: 'per SF',
        value: COST_BASIS_TYPES.PER_SF,
    },
];

export enum UNIT_OF_TIME_TYPES {
    YEARLY = 'yearly',
    MONTHLY = 'monthly',
}

export const UNIT_OF_TIME_OPTIONS = [
    {
        label: 'Monthly',
        value: UNIT_OF_TIME_TYPES.MONTHLY,
    },
    {
        // Backend = Yearly ; Frontend = Annually
        label: 'Annually',
        value: UNIT_OF_TIME_TYPES.YEARLY,
    },
];

export enum LOAN_CALCULATION_TYPES {
    LTV = 'ltv',
    LTC = 'ltc',
    DY = 'dy',
}

export const LOAN_CALCULATION_OPTIONS = [
    {
        label: 'LTV',
        value: LOAN_CALCULATION_TYPES.LTV,
    },
    {
        label: 'LTC',
        value: LOAN_CALCULATION_TYPES.LTC,
    },
    {
        label: 'Debt Yield',
        value: LOAN_CALCULATION_TYPES.DY,
    },
];

export const validationSchema = Yup.object().shape({
    grossSquareFootage: Yup.number().required('Required'),
    efficiency: Yup.number().min(0).max(100).required('Required'),
    rentPrice: Yup.number().required('Required'),
    units: Yup.number().required('Required'),
    expenses: Yup.number().required('Required'),
    exitCapRate: Yup.number().required('Required'),
    acquisitionCosts: Yup.number().required('Required'),
    hardCosts: Yup.number().required('Required'),
    softCosts: Yup.number().required('Required'),
    developerFee: Yup.number().required('Required'),
    apr: Yup.number().required('Required'),
    avgLoanBalance: Yup.number().required('Required'),
    loanTerm: Yup.number().required('Required'),
    contructionFinancing: Yup.number().required('Required'),
    marketCapRate: Yup.number().nullable(),
});
