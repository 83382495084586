import { useState } from 'react';
import { IOtherIncome } from '../types';

interface UseOtherIncomeManagerProps {
    initialIncomes?: IOtherIncome[];
}

export const useOtherIncomeManager = ({ initialIncomes = [] }: UseOtherIncomeManagerProps) => {
    const DEFAULT_INCOMES: IOtherIncome[] = [
        {
            id: Date.now(), name: 'RUBS (Ratio Utility Billing System)', annualGrowth: 5, unitPerMonth: 50, amountPerYear: 600,
        },
        {
            id: Date.now() + 1, name: 'Parking', annualGrowth: 3, unitPerMonth: 100, amountPerYear: 1200,
        },
    ];

    const [incomeArray, setIncomeArray] = useState<IOtherIncome[]>(initialIncomes.length > 0 ? initialIncomes : DEFAULT_INCOMES);

    const handleAddOrDuplicateIncome = (incomeId?: number) => {
        setIncomeArray(prevArray => {
            if (!incomeId) {
                return [...prevArray, { id: Date.now(), name: '' }];
            }

            const incomeToDuplicate = prevArray.find(income => income.id === incomeId);
            if (!incomeToDuplicate) return prevArray;

            const duplicatedIncome = {
                ...incomeToDuplicate,
                id: Date.now(),
            };
            return [...prevArray, duplicatedIncome];
        });
    };

    const handleDeleteIncome = (incomeId: number) => {
        setIncomeArray(prevArray => prevArray.filter(income => income.id !== incomeId));
    };

    const handleUpdateIncome = (updatedIncome: IOtherIncome) => {
        setIncomeArray(prevArray => prevArray.map(income => (income.id === updatedIncome.id ? { ...income, ...updatedIncome } : income)));
    };

    return {
        incomeArray,
        handleAddOrDuplicateIncome,
        handleDeleteIncome,
        handleUpdateIncome,
    };
};
