import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard, NSInput, NSLabel } from 'bricks';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import './OperatingInformation.scss';
import MonthlyAnalysisTable from './MonthlyAnalysisTable/MonthlyAnalysisTable';

const OperatingInformation = () => {
    const [startDatePicker, setStartDatePicker] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [rentalGrowth, setRentalGrowth] = useState<number | ''>('');
    const [preLeased, setPreLeased] = useState<number | ''>('');
    const [leaseUpPace, setLeaseUpPace] = useState<number | ''>('');
    const [lengthOfLease, setLengthOfLease] = useState<number>(12);
    const [initialFreeRent, setInitialFreeRent] = useState<number | ''>('');
    const [stabilizedFreeRent, setStabilizedFreeRent] = useState<number | ''>('');
    const [isRetail, setIsRetail] = useState(false);

    // TODO: Remove this mocked data later.
    const stabilizedMonth = 33;
    const months = [
        { id: String(new Date(2025, 0, 6)), date: new Date(2025, 0, 6) },
        { id: String(new Date(2025, 1, 3)), date: new Date(2025, 1, 3) },
        { id: String(new Date(2025, 2, 3)), date: new Date(2025, 2, 3) },
        { id: String(new Date(2025, 3, 7)), date: new Date(2025, 3, 7) },
        { id: String(new Date(2025, 4, 5)), date: new Date(2025, 4, 5) },
        { id: String(new Date(2025, 5, 2)), date: new Date(2025, 5, 2) },
        { id: String(new Date(2026, 0, 5)), date: new Date(2026, 0, 5) },
        { id: String(new Date(2026, 1, 2)), date: new Date(2026, 1, 2) },
        { id: String(new Date(2026, 2, 2)), date: new Date(2026, 2, 2) },
        { id: String(new Date(2026, 3, 6)), date: new Date(2026, 3, 6) },
        { id: String(new Date(2026, 4, 4)), date: new Date(2026, 4, 4) },
        { id: String(new Date(2026, 5, 1)), date: new Date(2026, 5, 1) },
    ];

    const data = [
        {
            id: 'leased-percentage',
            label: 'Leased %',
            values: [
                { monthId: 'leased-Jan', value: 0 },
                { monthId: 'leased-Feb', value: 0 },
                { monthId: 'leased-Mar', value: 0 },
                { monthId: 'leased-Apr', value: 0 },
                { monthId: 'leased-May', value: 0 },
                { monthId: 'leased-Jun', value: 0 },
                { monthId: 'leased-Jul', value: 0 },
                { monthId: 'leased-Aug', value: 0 },
                { monthId: 'leased-Sep', value: 0 },
                { monthId: 'leased-Oct', value: 0 },
                { monthId: 'leased-Nov', value: 0 },
                { monthId: 'leased-Dec', value: 0 },
            ],
        },
        {
            id: 'economic-occupancy',
            label: 'Economic Occ. %',
            values: [
                { monthId: 'economic-Jan', value: 0 },
                { monthId: 'economic-Feb', value: 0 },
                { monthId: 'economic-Mar', value: 0 },
                { monthId: 'economic-Apr', value: 0 },
                { monthId: 'economic-May', value: 0 },
                { monthId: 'economic-Jun', value: 0 },
                { monthId: 'economic-Jul', value: 0 },
                { monthId: 'economic-Aug', value: 0 },
                { monthId: 'economic-Sep', value: 0 },
                { monthId: 'economic-Oct', value: 0 },
                { monthId: 'economic-Nov', value: 0 },
                { monthId: 'economic-Dec', value: 0 },
            ],
        },
    ];

    const formatDate = (date: Date) => date.toLocaleDateString();
    const labelClassName = 'pl-0 col-4 font-weight-normal text-dark align-self-center';
    const labelClassNameNoWrap = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';

    return (
        <div className="d-flex justify-content-end pb-3">
            <div className="OperatingInformation__inputs-container w-50 pl-3">
                {/* Operation Start */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Operation start</NSLabel>
                    <NSInput
                        id="startDate"
                        name="startDate"
                        placeholder="Select date..."
                        inputClassName="pt-2 OperatingInformation__input-width"
                        appendInputAddon={(
                            <div>
                                <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setStartDatePicker(true)} className="cursor-pointer" />
                                {startDatePicker && (
                                    <div className="ProFormaWizardStepOne__datepicker">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date: Date | null) => {
                                                if (date) {
                                                    setStartDate(date);
                                                }
                                                setStartDatePicker(false);
                                            }}
                                            onClickOutside={() => setStartDatePicker(false)}
                                            inline
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        type="text"
                        value={startDate ? formatDate(startDate) : ''}
                        readOnly
                    />
                </div>

                {/* Rental Growth */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Rental growth</NSLabel>
                    <NSInputNumberStepper
                        percentage
                        name="rentalGrowth"
                        value={rentalGrowth || 0}
                        onChange={(value: number) => setRentalGrowth(value)}
                        min={0}
                        step={0.1}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>

                {/* % Pre-leased */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>% pre-leased</NSLabel>
                    <NSInputNumberStepper
                        percentage
                        name="preLeased"
                        value={preLeased || 0}
                        onChange={(value: number) => setPreLeased(value)}
                        min={0}
                        step={1}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>

                {/* Lease-up Pace */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassName}>Lease-up pace (units/month)</NSLabel>
                    <NSInputNumberStepper
                        name="leaseUpPace"
                        value={leaseUpPace || 0}
                        onChange={(value: number) => setLeaseUpPace(value)}
                        min={0}
                        step={1}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>

                {/* Length of Lease */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Length of lease</NSLabel>
                    <NSInputNumberStepper
                        name="lengthOfLease"
                        value={lengthOfLease}
                        onChange={(value: number) => setLengthOfLease(value)}
                        min={1}
                        step={1}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>

                {/* Initial Free Rent */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Initial free rent</NSLabel>
                    <NSInputNumberStepper
                        name="initialFreeRent"
                        value={initialFreeRent || 0}
                        onChange={(value: number) => setInitialFreeRent(value)}
                        min={0}
                        step={1}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>

                {/* Stabilized Free Rent */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassName}>Stabilized free rent</NSLabel>
                    <NSInputNumberStepper
                        name="stabilizedFreeRent"
                        value={stabilizedFreeRent || 0}
                        onChange={(value: number) => setStabilizedFreeRent(value)}
                        min={0}
                        step={1}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>

                {/* Retail Toggle */}
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassName}>Retail</NSLabel>
                    <div className="d-flex OperatingInformation__input-width OperatingInformation__toggle-buttons">
                        <Button
                            data-testid="no-retail-button"
                            className="NSButton OperatingInformation__toggle-buttons--left-button"
                            onClick={() => setIsRetail(false)}
                            color={!isRetail ? 'primary' : 'secondary'}
                        >
                            No retail
                        </Button>
                        <Button
                            data-testid="retail-button"
                            className="NSButton OperatingInformation__toggle-buttons--right-button"
                            onClick={() => setIsRetail(true)}
                            color={isRetail ? 'primary' : 'secondary'}
                        >
                            Retail
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <MonthlyAnalysisTable data={data} months={months} />
                <NSCard className="NSCard--level-4 mr-3 mt-2">
                    <div className="OperatingInformation_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                        <div className="text-muted">1st stabilized month</div>
                        <div className="text-white">
                            {`Month ${stabilizedMonth}`}
                        </div>
                    </div>
                </NSCard>
            </div>
        </div>
    );
};

export default OperatingInformation;
