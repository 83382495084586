import React, { useEffect, useState } from 'react';
import RenderIf from '../../../../components/RenderIf/RenderIf';
import { useDealsOverview } from '../../context/DealsOverviewProvider';
import './ProjectStages.scss';

interface IStage {
    id: number;
    name: string;
    subprojects_count: number;
}

// TODO get from api?
const mockedStages: IStage[] = [
    { id: 1, name: 'Opportunity', subprojects_count: 5 },
    { id: 2, name: 'Due Diligence', subprojects_count: 3 },
    { id: 3, name: 'Pre-Development', subprojects_count: 8 },
    { id: 4, name: 'Construction', subprojects_count: 2 },
    { id: 5, name: 'Stabilization', subprojects_count: 1 },
    { id: 6, name: 'Archived', subprojects_count: 1 },
];

const ProjectStages = () => {
    const {
        handleClickStage, projectCountsByStageId, setProjectCountsByStageId, selectedStages,
    } = useDealsOverview();

    const [data, setData] = useState<IStage[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setData(mockedStages);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const counts: Record<number, number> = {};
            data.forEach(stage => {
                counts[stage.id] = stage.subprojects_count;
            });
            setProjectCountsByStageId(counts);
        }
    }, [data, setProjectCountsByStageId]);

    return (
        <RenderIf isTrue={!isLoading} isLoading={isLoading}>
            <div className="ProjectStages position-relative mt-3">
                {data.map(stage => (
                    <div
                        className="ProjectStages__steps cursor--pointer d-flex align-items-center position-relative"
                        key={stage.id}
                        onClick={() => handleClickStage(stage.id)}
                        role="presentation"
                    >
                        <div className={`ProjectStages__steps__circle ${selectedStages?.[stage.id] ? 'active' : ''}`}>
                            {projectCountsByStageId?.[stage.id] || 0}
                        </div>
                        <div className="ProjectStages__steps__stage">
                            {selectedStages?.[stage.id] ? <span className="ProjectStages__steps__stage__name">{stage.name}</span> : stage.name}
                        </div>
                    </div>
                ))}
            </div>
        </RenderIf>
    );
};

export default ProjectStages;
