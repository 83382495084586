import React from 'react';
import { useMapCoordinates } from 'hooks/useMapCoordinates';
import Map from 'components/Map/Map';
import ExecutiveSummaryCard from '../ExecutiveSummaryCard/ExecutiveSummaryCard';
import { SummaryDetails } from '../types';

interface IExecutiveSummaryDetailProps {
    details: SummaryDetails;
}

const ExecutiveSummaryDetail = ({ details }: IExecutiveSummaryDetailProps) => {
    const { lat, lng } = useMapCoordinates(details);

    const detailSections = [
        {
            id: 'details',
            title: 'Details',
            items: [
                { id: 'address', label: 'Address', value: details.fullAddress },
                { id: 'units', label: 'Units', value: details.units },
                { id: 'netRentableArea', label: 'Net Rentable Area', value: `${details.netRentableArea} SF` },
                { id: 'landArea', label: 'Land area', value: `${details.landArea} acres` },
                { id: 'buildings', label: 'Building(s)', value: details.buildings },
                { id: 'stories', label: 'Stories', value: details.stories },
                { id: 'parkingSpaces', label: 'Parking spaces', value: details.parkingSpaces },
                { id: 'parkingRatios', label: 'Parking ratios', value: `${details.parkingRatios} / unit` },
            ],
        },
        {
            id: 'description',
            title: 'Description',
            items: [
                {
                    id: 'description',
                    value: details.description,
                },
            ],
        },
    ];

    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <ExecutiveSummaryCard containerClassName="w-50 mr-1" sections={detailSections} />

            <div className="ml-1 w-50 d-flex position-relative">
                <Map lat={lat} lng={lng} />
            </div>
        </div>
    );
};

export default ExecutiveSummaryDetail;
