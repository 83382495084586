import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import './CreateDeal.scss';

import { useAuthContext } from 'contexts/AuthContext';
import { NSButton } from 'bricks';
import PageTitle from 'components/PageTitle/PageTitle';
import Map from 'components/Map/Map';
import DealForm from 'components/DealForm/DealForm';
import { IFormValues } from 'components/DealForm/types';
import { useGetDealById } from '../hooks/useGetDealById';

const CreateDeal = () => {
    const { selectedOrganizationId } = useAuthContext();
    const { dealId } = useParams<{ dealId: string }>();

    const { data: fetchedDeal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const [mapCoordinates, setMapCoordinates] = useState({
        center: fetchedDeal?.latitude && fetchedDeal?.longitude
            ? { lat: fetchedDeal.latitude, lng: fetchedDeal.longitude }
            : { lat: 39.8283, lng: -98.5795 }, // Center of the US as default
    });

    const formRef = useRef<FormikProps<IFormValues>>(null);
    const navigate = useNavigate();

    const isEditMode = dealId !== 'new' && !Number.isNaN(Number(dealId));

    useEffect(() => {
        if (fetchedDeal?.latitude && fetchedDeal?.longitude) {
            setMapCoordinates({
                center: {
                    lat: fetchedDeal.latitude,
                    lng: fetchedDeal.longitude,
                },
            });
        }
    }, [fetchedDeal]);

    const handleFinishClick = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const initialValues = fetchedDeal
        ? {
            ...fetchedDeal,
            latitude: fetchedDeal.latitude,
            longitude: fetchedDeal.longitude,
            coordinatesSwitch: !!(fetchedDeal.latitude && fetchedDeal.longitude && !fetchedDeal.address),
            assetTypeId: fetchedDeal.assetType?.correlationId,
            constructionTypeId: fetchedDeal.constructionType?.correlationId,
            marketId: fetchedDeal.market?.correlationId,
            dealTypeId: fetchedDeal.dealType?.id,
        }
        : undefined;

    return (
        <div>
            <PageTitle
                title={isEditMode ? 'Edit deal details' : 'Create deal'}
                subtitle={isEditMode ? 'Modify existing information about this deal.' : 'Fill out the information below to populate this deal.'}
            >
                <NSButton color="secondary" callback={handleGoBack} className="mr-2" text="Exit" />
                <NSButton color="primary" callback={handleFinishClick} text={isEditMode ? 'Save' : 'Finish'} data-testid="finish-button" />
            </PageTitle>
            <div className="d-flex mt-2">
                <DealForm ref={formRef} setMapCoordinates={setMapCoordinates} initialValues={initialValues} />

                <div className="w-50 position-relative">
                    <div style={{ height: '100%', width: '100%' }}>
                        <Map lng={mapCoordinates.center.lng} lat={mapCoordinates.center.lat} zoom={10} hideMarker={!isEditMode} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateDeal;
