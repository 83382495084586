import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { BOEScenario, IBackOfEnvelopeQueryParams } from '../types';

const getBackOfEnvelope = async ({
    orgId,
    dealId,
    scenarioId,
}: IBackOfEnvelopeQueryParams & { dealId?: string; scenarioId?: number }): Promise<BOEScenario> => {
    let url = API_ENDPOINTS.BACK_OF_ENVELOPE(orgId);

    if (scenarioId) {
        url = `${url}/${scenarioId}`;
    }

    const params: Record<string, string> = {};
    if (!scenarioId && dealId) {
        params.deal_id = dealId;
    }

    const response = await axiosInstance.get(url, { params });
    return response.data;
};

export const useGetBackOfEnvelope = ({
    orgId,
    dealId,
    scenarioId,
    isForm,
}: IBackOfEnvelopeQueryParams & { dealId?: string; scenarioId?: number; isForm?: boolean }) => useQuery({
    queryKey: ['back-of-envelope', orgId, dealId, scenarioId, isForm],
    queryFn: () => getBackOfEnvelope({ orgId, dealId, scenarioId }),
    staleTime: 5000,
    refetchOnMount: true,
});
