import React from 'react';
import {
    Card, CardBody, CardTitle, Col, Row,
} from 'reactstrap';
import { NSButton } from 'bricks';
import { formatDate, formatUSDCurrency } from 'ns_libs/formatter';
import { Link } from 'react-router-dom';
import { Deal } from '../../types';

interface IDataPanelProps {
    detail: Deal;
}
const DataPanel = ({ detail }: IDataPanelProps) => (
    <Card className="MapView__Card h-100 mb-0 border-0 shadow-none">
        <CardBody>
            <CardTitle className="border-bottom pb-2 mb-3">
                <Row>
                    <Col xs="9">
                        <h3 className="mb-1 text-dark">{detail.dealName}</h3>
                        <p className="text-muted small mb-0">{`${detail.address} ${detail.city} ${detail.state} ${detail.zipCode}`}</p>
                    </Col>
                    <Col xs="3" className="d-flex justify-content-end align-items-center">
                        <Link to={detail?.id ? `/deal/${detail.id}` : '#'} className="text-decoration-none text-white">
                            <NSButton disabled={!detail || !detail.id} className="btn-sm" color="secondary">
                                View deal
                            </NSButton>
                        </Link>
                    </Col>
                </Row>
            </CardTitle>
            <Row className="MapView__Card__Details">
                <Col xs="6">
                    <p className="text-uppercase font-weight-bold text-primary small mb-2">Details</p>
                    <p>
                        <span className="text-muted">Market:</span>
                        <br />
                        <span className="text-dark">{detail.market?.name}</span>
                    </p>
                    <p>
                        <span className="text-muted">Deal type:</span>
                        <br />
                        <span className="text-dark">{detail.dealType?.name}</span>
                    </p>
                    <p>
                        <span className="text-muted">Asset class:</span>
                        <br />
                        <span className="text-dark">{detail.assetType?.name}</span>
                    </p>
                    <p>
                        <span className="text-muted">Construction type:</span>
                        <br />
                        <span className="text-dark">{detail.constructionType?.name}</span>
                    </p>
                    <p>
                        <span className="text-muted">Date created:</span>
                        <br />
                        <span className="text-dark">{formatDate(detail.createdAt)}</span>
                    </p>
                </Col>
                <Col xs="6">
                    <p>
                        <span className="text-muted">County:</span>
                        <br />
                        <span className="text-dark">{detail.county}</span>
                    </p>
                    <p>
                        <span className="text-muted">Deal owner:</span>
                        <br />
                        <span className="text-dark">{detail.dealOwner?.name}</span>
                    </p>
                    <p>
                        <span className="text-muted">Acquisition price:</span>
                        <br />
                        <span className="text-dark">{formatUSDCurrency(detail.acquisitionPrice)}</span>
                    </p>
                    <p>
                        <span className="text-muted">Phase:</span>
                        <br />
                        <span className="text-dark">{detail.phase?.name}</span>
                    </p>
                    <p>
                        <span className="text-muted">Lead source:</span>
                        <br />
                        <span className="text-dark">{detail.leadSource?.name}</span>
                    </p>
                </Col>
            </Row>
        </CardBody>
    </Card>
);

DataPanel.propTypes = {};

export default DataPanel;
