import React from 'react';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import ProFormaForecastTable from './ProFormaForecastTable/ProFormaForecastTable';
import ProFormaSaleProceeds from './ProFormaSaleProceeds/ProFormaSaleProceeds';
import ProFormaAssumptions from './ProFormaAssumptions/ProFormaAssumptions';
import ProFormaValuation from './ProFormaValuation/ProFormaValuation';

const ProFormaDisposition = () => (
    <>
        <ExpandableSection
            number={1}
            title="Assumptions"
            subtitle="Assumptions about the sale of this investment"
            className="NSCard--level-2"
            isExpanded
            animate
        >
            <ProFormaAssumptions />
        </ExpandableSection>
        <ExpandableSection
            number={2}
            title="Forecast"
            subtitle="Estimate projections for this project on a trended and untranded basis"
            className="mt-3 NSCard--level-2"
            isExpanded
            animate
        >
            <ProFormaForecastTable />
        </ExpandableSection>
        <ExpandableSection
            number={3}
            title="Sale Proceeds"
            subtitle="Review the overview of proceeds at sale"
            className="mt-3 NSCard--level-2"
            isExpanded
            animate
        >
            <ProFormaSaleProceeds />
        </ExpandableSection>
        <ExpandableSection
            number={4}
            title="Valuation"
            subtitle="Review the valuation of this prospected project"
            className="mt-3 NSCard--level-2"
            isExpanded
            animate
        >
            <ProFormaValuation />
        </ExpandableSection>
    </>
);

export default ProFormaDisposition;
