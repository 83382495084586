import { AxiosError } from 'axios';
import { displayAlert } from 'bricks/utils';

export const standardHandlerActionError = (error: AxiosError<any, any>, customMessage: string) => {
    console.error(error);
    let errorMessage = customMessage;
    if (error && typeof error?.response?.data.detail === 'string') {
        errorMessage += `: ${error.response.data.detail}`;
    } else if (error?.response?.data.message) {
        errorMessage += `: ${error?.response.data.message}`;
    }
    // make sure no string html errors get to the toast alert
    if (error && typeof error?.response?.data === 'string' && !error?.response?.data.includes('DOCTYPE html')) {
        errorMessage += `: ${error?.response.data}`;
    }
    if (error && typeof error?.response?.data?.Error === 'string') {
        errorMessage += `: ${error?.response?.data?.Error}`;
    }
    // TODO: figure out styling for the error toast https://app.clickup.com/t/86b3v3mv7
    displayAlert('Warning', errorMessage, 'warning', null, false);
};
