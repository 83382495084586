import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { ProFormaModelIds } from 'constants/proForma';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import { useParams } from 'react-router-dom';
import { numeralFormatter } from 'ns_libs/formatter';
import { useAuthContext } from 'contexts/AuthContext';
import Uses, { IUses } from './Uses/Uses';
import { ISource } from './Sources/Source/types';
import { sourcesInitialState } from './Sources/Source/constants';
import Sources from './Sources/Sources';
import { useGetProFormaWizardStep } from '../../hooks/useGetProFormaWizardStep';
import { useUpdateProFormaWizardStep } from '../../hooks/useUpdateProFormaWizardStep';

export type StepTwoValues = IUses & {
    sources: ISource[];
};

const ProFormaWizardStepTwo = () => {
    const { registerStepHandler } = useProFormaWizard();
    const { proFormaId } = useParams<{ dealId: string; proFormaId: string }>();
    const { selectedOrganizationId } = useAuthContext();

    const { data: stepData, isLoading } = useGetProFormaWizardStep({
        orgId: selectedOrganizationId!,
        proFormaId: proFormaId || '',
        stepNumber: 2,
    });

    // TODO: Remove this when this data comes from the API.
    const dealUnits = 50;
    const dealSF = 10;

    const updateStep = useUpdateProFormaWizardStep();

    // First, let's move sources into useMemo like we did with initialValues
    const initialSources = useMemo(() => stepData?.sources || sourcesInitialState, [stepData]);

    const [sources, setSources] = useState<ISource[]>(initialSources);

    // Move initial values to useMemo
    const initialValues = useMemo(
        () => ({
            acquisitionCosts: Number(numeralFormatter(stepData?.acquisitionCosts ?? 0, '0.00')),
            hardCosts: Number(numeralFormatter(stepData?.hardCosts ?? 0, '0.00')),
            totalHardCosts: Number(numeralFormatter((stepData?.hardCosts ?? 0) * dealSF, '0.00')),
            hardCostType: stepData?.hardCostType ?? 'sf',
            softCosts: Number(numeralFormatter(stepData?.softCosts ?? 0, '0.00')),
            totalSoftCosts: Number(numeralFormatter((stepData?.softCosts ?? 0) * dealSF, '0.00')),
            softCostType: stepData?.softCostType ?? 'sf',
            developerFee: Number(numeralFormatter(stepData?.developerFee ?? 0, '0.00')),
            interestReserve: Number(numeralFormatter(stepData?.interestReserve ?? 0, '0.00')),
        }),
        [stepData, dealSF],
    );

    const [usesValues, setUsesValues] = useState<IUses>(initialValues);

    useEffect(() => {
        if (stepData) {
            setSources(initialSources);
            setUsesValues(initialValues);
        }
    }, [stepData, initialValues, initialSources]);

    // Asset type is hardcoded here – TODO: retrieve from API if needed.
    const [proFormaModelId] = useState<ProFormaModelIds>(ProFormaModelIds.APARTMENT);

    const validate = useCallback(() => true, []);

    const getData = useCallback(() => {
        const data = {
            sources,
            ...usesValues,
        };

        updateStep.mutate({
            orgId: selectedOrganizationId!,
            proFormaId: proFormaId || '',
            stepNumber: 2,
            data,
        });

        return data;
    }, [sources, usesValues, proFormaId, updateStep]);

    useEffect(() => {
        registerStepHandler(2, { validate, getData });
    }, [registerStepHandler, getData, validate]);

    const handleInputChange = (key: string, value: number | string) => {
        setUsesValues(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    const totalUses = usesValues.acquisitionCosts + usesValues.totalHardCosts
        + usesValues.totalSoftCosts + usesValues.interestReserve + usesValues.developerFee;
    const totalSources = useMemo(() => sources.reduce((acc, curr) => acc + Number(curr.amount || 0), 0), [sources]);

    if (isLoading || !stepData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Uses
                values={usesValues}
                onChange={handleInputChange}
                dealSF={dealSF}
                dealUnits={dealUnits}
                proFormaModelId={proFormaModelId}
                totalSources={totalSources}
            />
            <Sources sources={sources} onChange={setSources} totalUses={totalUses} totalSources={totalSources} />
        </div>
    );
};

export default ProFormaWizardStepTwo;
