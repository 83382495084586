import { ISource } from './types';

export const sourcesInitialState: ISource[] = [
    {
        id: '1',
        name: 'Equity',
        amount: 0,
        traunch: 1,
        type: 'equity',
        calculate: false,
        sourceTypeId: '1',
    },
    {
        id: '2',
        name: 'Debt Construction',
        amount: 0,
        traunch: 2,
        type: 'debt_private',
        calculate: false,
        sourceTypeId: '2',
    },
];

export const sourceTypeOptions = [
    { label: 'Bonds', value: 'bonds' },
    { label: 'Company operation budgets', value: 'company_operation_budgets' },
    { label: 'Debt (private)', value: 'debt_private' },
    { label: 'Debt (public - local)', value: 'debt_public_local' },
    { label: 'Debt (public - national/federal)', value: 'debt_public_national_federal' },
    { label: 'Debt (public - state/province)', value: 'debt_public_state_province' },
    { label: 'Equity', value: 'equity' },
    { label: 'Equity - GP', value: 'equity_gp' },
    { label: 'Equity - LP', value: 'equity_lp' },
    { label: 'Existing cash reserves', value: 'existing_cash_reserves' },
    { label: 'Grant (private)', value: 'grant_private' },
    { label: 'Grant (public - local)', value: 'grant_public_local' },
    { label: 'Grant (public - national/federal)', value: 'grant_public_national_federal' },
    { label: 'Grant (public - state/province)', value: 'grant_public_state_province' },
    { label: 'Other', value: 'other' },
    { label: 'Philanthropy', value: 'philanthropy' },
];
