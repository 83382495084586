import React, { useState } from 'react';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import {
    PROCEEDS_COLUMNS,
    NET_SALE_PROCEEDS_COLUMNS,
    EQUITY_PROCEEDS_COLUMNS,
    PROCEEDS_ROWS_INITIAL_STATE,
    NET_SALE_ROWS_INITIAL_STATE,
} from './constants';

const ProFormaSaleProceeds = () => {
    const [proceedsRows, setProceedsRows] = useState<ProFormaGenericRow[]>(PROCEEDS_ROWS_INITIAL_STATE);
    const [netSaleRows, setNetSaleRows] = useState<ProFormaGenericRow[]>(NET_SALE_ROWS_INITIAL_STATE);

    return (
        <div className="d-flex flex-column p-3">
            <ProFormaGenericTable columns={PROCEEDS_COLUMNS} rows={proceedsRows} setRows={setProceedsRows} hideAddButton hideTotalFooter />
            <ProFormaGenericTable columns={NET_SALE_PROCEEDS_COLUMNS} rows={netSaleRows} setRows={setNetSaleRows} hideTotalFooter hideAddButton />
            <ProFormaGenericTable columns={EQUITY_PROCEEDS_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
        </div>
    );
};

export default ProFormaSaleProceeds;
