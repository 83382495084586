import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { IProFormaData } from 'views/ProFormaTable/types';

interface ICreateProFormaProps {
    dealId: number;
}

const createProForma = async ({ dealId }: ICreateProFormaProps): Promise<IProFormaData> => {
    const response = await axiosInstance.post(`${API_ENDPOINTS.PRO_FORMA(dealId)}`);
    return response.data;
};

const useCreateProForma = () => useMutation({
    mutationFn: createProForma,
});

export default useCreateProForma;
