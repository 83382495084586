/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './TargetReturns.scss';
import { NSButton } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import TargetReturnCard from './TargetReturnCard/TargetReturnCard';
import { TARGET_RETURNS_CONFIG } from './constants';

export interface TargetReturnsValues {
    cost: number | null;
    equity: number | null;
    profit: number | null;
}

interface TargetReturnsProps {
    targetCost: number | null;
    targetEquity: number | null;
    targetProfit: number | null;
    onSave: (values: TargetReturnsValues) => void;
}

const TargetReturns: React.FC<TargetReturnsProps> = ({
    targetCost, targetEquity, targetProfit, onSave,
}) => {
    const [values, setValues] = useState<TargetReturnsValues>({
        cost: targetCost,
        equity: targetEquity,
        profit: targetProfit,
    });

    const [editingValues, setEditingValues] = useState<TargetReturnsValues>({
        cost: targetCost,
        equity: targetEquity,
        profit: targetProfit,
    });

    const handleInputChange = (field: keyof TargetReturnsValues, value: number) => {
        setEditingValues(prev => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        setValues(editingValues);
        setIsEditing(false);
        onSave(editingValues);
    };

    const handleCancel = () => {
        setEditingValues(values);
        setIsEditing(false);
    };

    const [isEditing, setIsEditing] = useState(false);

    return (
        <div className="container TargetReturns">
            <div className="d-flex justify-content-between align-items-center">
                <h3>Target Returns</h3>
                {!isEditing && (
                    <h5 onClick={() => setIsEditing(true)} className="text-primary cursor--pointer">
                        Edit target returns
                    </h5>
                )}
            </div>
            <div className="row gx-3">
                {TARGET_RETURNS_CONFIG.map(config => (
                    <div key={config.field} className="col">
                        <TargetReturnCard
                            isEditing={isEditing}
                            title={config.title}
                            field={config.field as keyof TargetReturnsValues}
                            value={
                                isEditing ? editingValues[config.field as keyof TargetReturnsValues] : values[config.field as keyof TargetReturnsValues]
                            }
                            min={config.min}
                            max={config.max}
                            unit={config.unit}
                            handleInputChange={handleInputChange}
                        />
                    </div>
                ))}
            </div>
            <RenderIf isTrue={isEditing}>
                <div className="d-flex justify-content-end mt-3">
                    <NSButton text="Cancel" callback={handleCancel} className="bg-none text-dark" />
                    <NSButton text="Save target returns" className="btn btn-primary ms-2" callback={handleSave} />
                </div>
            </RenderIf>
        </div>
    );
};

export default TargetReturns;
