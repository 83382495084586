/* eslint-disable no-unused-vars */
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { IOptionAnyValue } from 'bricks/types';
import { SortDirection } from 'types/sort';
import { IFilter } from './ReportTypes/PipelineReport/ConfigurationSidebar/Filters/types';
import { AttributeKeysMap } from './ReportTypes/PipelineReport/ConfigurationSidebar/constants';

export enum ReportType {
    Aggregate = 'Aggregate Report',
    Comparison = 'Comparison Report',
    Pipeline = 'Pipeline Report',
}

export enum ReportTypeId {
    Pipeline = 1,
    Comparison = 2,
    Aggregate = 3,
}

export const ReportTypeIdToReportTypeMap: Record<number, ReportType> = {
    1: ReportType.Pipeline,
    2: ReportType.Comparison,
    3: ReportType.Aggregate,
};

// build url params as ReportType to kebab-case strings
export const ReportTypeToUrlParamMap: Record<ReportType, string> = Object.fromEntries(
    Object.values(ReportType).map(value => [value as ReportType, value.toLowerCase().replace(/\s+/g, '-')]),
) as Record<ReportType, string>;

// Reverse the map from kebab-case strings to ReportType
export const ReportTypeUrlParamToTypeMap: Record<string, ReportType> = Object.fromEntries(
    Object.entries(ReportTypeToUrlParamMap).map(([reportType, urlParam]) => [urlParam, reportType as ReportType]),
) as Record<string, ReportType>;

export const ReportTypeIdToNameMap: Record<number, string> = {
    1: 'Pipeline',
    2: 'Comparison',
    3: 'Aggregate',
};

export interface IReportType {
    type: ReportType;
    name: string;
    description: string;
    icon: IconDefinition;
    disabledTooltip: JSX.Element;
}

export interface IDeal {
    id: number;
    dealName: string;
    dealType: string;
    project: string;
    projectType: string;
    defaultProFormaId: number;
}

export interface IDealSelection {
    deal: IDeal;
    proFormaId: number;
    proForma?: IOptionAnyValue; // deprecated
}

export type AttributeKey = keyof typeof AttributeKeysMap;

export interface IReportsQueryParams {
    pageSize: number;
    currentPage: number;
    orgId: string;
    configured_filters?: {
        deal_ids?: number[];
        report_types?: number[];
    };
    sorting?: Record<string, SortDirection>;
}

export interface IPipelineReportQueryParams {
    pageSize: number;
    currentPage: number;
    orgId: string;
    selectedColumns: Array<AttributeKey>;
    configuredFilters?: Array<IFilter>;
}

export interface IAggregateReportQueryParams {
    orgId: string;
    proFormaIds: number[];
    cadence: string;
}

export interface IReportsResponse {
    items: Array<IReport>;
    itemCount: number;
}

export interface IPipelineReportDealsResponse {
    items: Array<IPipelineReportDeals>;
    itemCount: number;
}

export interface ProFormaReturnsPeriodData {
    amount: number;
    periods: Record<string, number>;
}

export interface CashFlowPeriodData extends ProFormaReturnsPeriodData {
    equityMultiple: number;
    irr: number;
    netProfit: number;
}

export interface AggregateReportKeyStats {
    leveredEmx: number;
    leveredIrr: number;
    leveredProfitReturn: number;
}

export interface MetricDetails {
    untrended: number;
    trended: number;
    sale: number;
    isHighlighted: boolean | null;
}

export interface CostMetrics {
    yieldOnCost: MetricDetails;
    marketCapRate: MetricDetails;
    developmentSpread: MetricDetails;
}

export interface TimelineData {
    start_date: string;
    operation_start_date: string | null;
    sale_month: string | null;
    deal_name: string;
    first_stabilized_month: string;
    development_start_date: string;
    development_end_date: string;
}

export interface IAggregateReportResponse {
    keyStats: AggregateReportKeyStats;
    proFormaReturns: Record<string, ProFormaReturnsPeriodData>;
    cashFlowData: Record<string, CashFlowPeriodData>;
    costMetrics: CostMetrics;
    timelineData: TimelineData;
}

export interface IPipelineReportDeals {
    acquisitionPrice?: number;
    address?: string;
    assetClass?: number;
    equityPartner?: string;
    city?: string;
    constructionType?: number;
    county?: string;
    dateCreated?: string;
    dealCreator?: string;
    dealId?: number;
    dealName?: string;
    dealType?: string;
    units?: string;
    description?: string;
    equityMultiple?: number;
    irr?: number;
    leadSource?: string;
    leasingStrategy?: string;
    dealOwner?: string;
    rank?: string;
    state?: string;
    subprojectStageId?: number;
    targetProfitReturn?: number;
    targetReturnOnCost?: number;
    targetReturnOnEquity?: number;
    zipCode?: string;
}
export interface IReport {
    id: number;
    name: string;
    description: string;
    type: number;
    createdAt: string;
    metadata: any;
    deals: Array<string>;
}

export interface IReportDeal {
    dealId: number;
    proFormaId: number;
    dealName?: string;
    proFormaName?: string;
    dealType?: string;
}

interface IReportMetadata {
    sorting: Record<AttributeKey, SortDirection>;
    selectedColumns: Array<AttributeKey>;
    configuredFilters: Array<IFilter>;
}

export interface IReportDetails {
    id: number;
    name: string;
    description: string;
    type: number;
    createdAt: string;
    metadata: IReportMetadata;
    deals: Array<IReportDeal>;
}

export interface ICreateReportPostData {
    name: string;
    description: string;
    type: ReportTypeId;
    metadata?: IReportMetadata;
    deals?: Array<IReportDeal>;
}

export interface IUpdateReportPostData extends ICreateReportPostData {
    report_id: number;
}

export interface IReportName {
    name: string;
}

export interface IInvestmentMetricsData {
    roi?: number;
    emx?: number;
    irr?: number;
    isHighlighted?: boolean;
}

export interface IProjectionMetricsData {
    untrended?: number;
    trended?: number;
    sale?: number;
    isHighlighted?: boolean;
}

export interface ICostMetricsData {
    usagePercent?: number;
    costPerBuildSF?: number;
    costPerUnit?: number;
    amount?: number;
    isHighlighted?: boolean;
}

export interface IDealData {
    id: number;
    name?: string;
    address?: string;
    city?: string;
    phase?: string;
    targetProfitReturn?: number;
    dateCreated?: string;
    surveyor?: string;
    description?: string;
    state?: string;
    dealType?: string;
    assetClass?: string;
    constructionType?: string;
    units?: string;
    progress?: number;
    leadSource?: string;
    leasingStrategy?: string;
    rank?: string;
    acquisitionPrice?: number;
    targetReturnOnCost?: number;
    targetReturnOnEquity?: number;
    irr?: string;
    equityMultiple?: string;
    dealCreator?: string;
    dealOwner?: string;
    developmentManager?: string;
    equityPartner?: string;
    lender?: string;
    legalCounsel?: string;
    architect?: string;
    engineer?: string;
    environmentalConsultant?: string;
    zipCode?: string;
    market?: string;
    submarket?: string;
    county?: string;
}

export interface IComparisonReportDetails {
    buildings: number;
    landArea: number;
    netRentableSf: number;
    parkingRatio: number;
    parkingSpaces: number;
    height: number;
    units: number;
}

export interface IComparisonReportPartnershipReturns {
    limitedPartner: IInvestmentMetricsData;
    generalPartner: IInvestmentMetricsData;
}

export interface IComparisonReportPropertyReturns {
    unlevered: IInvestmentMetricsData;
    levered: IInvestmentMetricsData;
}

export interface IComparisonReportDevelopmentReturns {
    yieldOnCost: IProjectionMetricsData;
    marketCapRate: IProjectionMetricsData;
    developmentSpread: IProjectionMetricsData;
}

export interface IComparisonReportUses {
    costPerBuiltSf: number;
    costPerUnit: number;
    amount: number;
    isHighlighted?: boolean;
}

export interface IComparisonReportUsesDetails {
    acquisitionCosts: IComparisonReportUses;
    hardCosts: IComparisonReportUses;
    softCosts: IComparisonReportUses;
    carryCosts: IComparisonReportUses;
    totalUses: IComparisonReportUses;
}

export interface IComparisonReportIncome {
    effectiveGrossRevenue: IProjectionMetricsData;
    operatingExpenses: IProjectionMetricsData;
    netOperatingIncome: IProjectionMetricsData;
    capitalExpenditures: IProjectionMetricsData;
    cashFlowFromOperations: IProjectionMetricsData;
    capRate: IProjectionMetricsData;
    valuation: IProjectionMetricsData;
    percentOfTotalProjectCost: IProjectionMetricsData;
    valuationPerUnit: IProjectionMetricsData;
}

export interface IComparisonReportData {
    dealId: number;
    dealName: string;
    dealType: number;
    proFormaId: number;
    proFormaName: string;
    details: IComparisonReportDetails;
    partnershipReturns: IComparisonReportPartnershipReturns;
    propertyReturns: IComparisonReportPropertyReturns;
    developmentReturns: IComparisonReportDevelopmentReturns;
    uses: IComparisonReportUsesDetails;
    income: IComparisonReportIncome;
}

export interface IDealOption extends IOptionAnyValue {
    defaultProFormaId: number;
}
