import React, { useState } from 'react';
import { NSSwitch } from 'bricks';
import { ExpandableSection } from 'components/ExpandableSection/ExpandableSection';
import OperatingInformation from './OperatingInformation/OperatingInformation';
import ProFormaIncomeTable from './ProFormaIncomeTable/ProFormaIncomeTable';
import ProFormaExpenses from './ProFormaExpenses/ProFormaExpenses';
import ProFormaPermanentDebt from './ProFormaPermanentDebt/ProFormaPermanentDebt';
import './ProFormaOperations.scss';

const ProFormaOperations = () => {
    const [includePermanentDebt, setIncludePermanentDebt] = useState(false);

    const handleTogglePermanentDebt = () => {
        setIncludePermanentDebt(prev => !prev);
    };

    return (
        <>
            <ExpandableSection
                number={1}
                title="Operating Information"
                subtitle="Information about this investment's incoming revenue"
                className="NSCard--level-2"
                isExpanded
                animate
            >
                <OperatingInformation />
            </ExpandableSection>
            <ExpandableSection
                number={2}
                title="Income"
                subtitle="Overview of this investment's total income"
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <ProFormaIncomeTable />
            </ExpandableSection>
            <ExpandableSection
                number={3}
                title="Expenses"
                subtitle="Overview of this investment's total expenses"
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <ProFormaExpenses />
            </ExpandableSection>
            <ExpandableSection
                number={4}
                title="Permanent Debt"
                subtitle="Include permanent debt in this Pro Forma"
                className="mt-3 NSCard--level-2"
                isExpanded={includePermanentDebt}
                animate
                customToggle={(
                    <NSSwitch
                        id="include-permanent-debt"
                        name="include-permanent-debt"
                        containerClassName="ProFormaOperations__permanent-debt-toggle"
                        checked={includePermanentDebt}
                        onChange={e => e.preventDefault()}
                    />
                )}
                onCustomToggle={handleTogglePermanentDebt}
            >
                <ProFormaPermanentDebt />
            </ExpandableSection>
        </>
    );
};

export default ProFormaOperations;
