import { NSSelect } from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IOptionAnyValue } from 'bricks/types';
import classNames from 'classnames';
import { formatUTCtoLocal, isValidDate, isValidDateString } from 'ns_libs/formatter';
import React, { useEffect, useState } from 'react';
import { generateDateRange } from 'views/ProFormaTable/helpers';
import { PlaceholderProps, components } from 'react-select';

export interface IPeriodsSelectProps {
    proFormaDateRange: string[];
    availableDateRange: string[];
    className?: string;
    selectedDate?: string | Date | null;
    setSelectedDate: (date: IOptionAnyValue) => void;
}

// all local date formatting is done assuming dates are passed in UTC
const PeriodsSelect = ({
    className, proFormaDateRange, availableDateRange, selectedDate, setSelectedDate,
}: IPeriodsSelectProps) => {
    const [formattedDate, setFormattedDate] = useState<string | null>(null);
    const defaultDateOptions = proFormaDateRange && generateDateRange(proFormaDateRange);
    const availableDateOptions = availableDateRange && generateDateRange(availableDateRange);

    const isValidDateOrString = isValidDate(selectedDate) || isValidDateString(selectedDate);

    const CustomPlaceholder: React.FC<PlaceholderProps> = props => (
        <components.Placeholder {...props}>
            <div className="text-primary text-nowrap font-italic">+ Add date</div>
        </components.Placeholder>
    );

    useEffect(() => {
        if (isValidDateOrString && selectedDate) {
            setFormattedDate(formatUTCtoLocal(selectedDate as string, 'MMM yyyy'));
        }
    }, [selectedDate]);

    return (
        <NSSelect
            name="periodsSelect"
            components={{
                Placeholder: CustomPlaceholder,
            }}
            className={className}
            options={availableDateOptions}
            value={availableDateOptions.find(option => option.label === formattedDate)}
            onChange={setSelectedDate}
            isClearable={false}
            isSearchable={false}
            formatOptionLabel={(option: { label: string; value: string }, context: { context: string }) => {
                const index = defaultDateOptions.findIndex(opt => opt.label === option.label);

                if (['menu', 'value'].includes(context.context)) {
                    return (
                        <div className={classNames('d-flex flex-column', { 'py-0': context.context === 'value' })}>
                            <small>
                                {`Period ${index + 1}`}
                            </small>
                            <div className={classNames({ 'text-primary': context.context === 'value' })}>{option.label}</div>
                        </div>
                    );
                }

                return option.label;
            }}
            styles={{
                ...customStyles,
                control: provided => ({
                    ...provided,
                    color: '#aab8c5',
                    backgroundColor: '#30343e',
                    borderRadius: 4,
                    cursor: 'pointer',
                    border: 'none',
                    width: '120px',
                    height: '42px',
                    '&:hover': {
                        border: 'none',
                        backgroundColor: '#464e5b',
                    },
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? '#F1F1F1' : '#30343e',
                    '&:hover': {
                        color: '#F1F1F1',
                        backgroundColor: '#464e5b',
                    },
                }),
            }}
        />
    );
};

export default PeriodsSelect;
