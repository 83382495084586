import { useState } from 'react';
import { TUnit } from '../types';

interface UseUnitManagerProps {
    initialUnits?: TUnit[];
    proFormaModelId: number;
}

export const useUnitManager = ({ initialUnits = [] }: UseUnitManagerProps) => {
    const [unitArray, setUnitArray] = useState<TUnit[]>(() => {
        if (initialUnits.length > 0) {
            return initialUnits.map(unit => ({
                ...unit,
                id: unit.id || Date.now(),
                name: unit.name || 'Default name',
            }));
        }
        return [{ id: Date.now(), name: 'Default name' }];
    });

    const handleDuplicateOrAdd = (unitId?: number) => {
        setUnitArray(prevArray => {
            if (!unitId) {
                return [...prevArray, { id: Date.now(), name: 'Default name' }];
            }

            const unitToDuplicate = prevArray.find(unit => unit.id === unitId);
            if (!unitToDuplicate) return prevArray;

            const duplicatedUnit = {
                ...unitToDuplicate,
                id: Date.now(),
            };
            return [...prevArray, duplicatedUnit];
        });
    };

    const handleDelete = (unitId: number) => {
        setUnitArray(prevArray => prevArray.filter(unit => unit.id !== unitId));
    };

    const handleUpdate = (updatedUnit: TUnit) => {
        setUnitArray(prevArray => prevArray.map(unit => (unit.id === updatedUnit.id ? { ...unit, ...updatedUnit } : unit)));
    };

    return {
        unitArray,
        handleDuplicateOrAdd,
        handleDelete,
        handleUpdate,
    };
};
