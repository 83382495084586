import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { NSTable, NSSelect } from 'bricks';
import { faCircleInfo, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import { formatValue } from 'views/ProFormaTable/helpers';
import { Tooltip } from 'reactstrap';
import { ProFormaGenericRow, ProFormaGenericColumn, ProFormaGenericTableProps } from './types';
import DeleteRowConfirmationModal from '../DeleteRowConfirmationModal/DeleteRowConfirmationModal';
import './ProFormaGenericTable.scss';

const ProFormaGenericTable: React.FC<ProFormaGenericTableProps> = ({
    columns,
    rows,
    setRows,
    defaultRow: customDefaultRow,
    addButtonLabel = 'Add new row',
    hideTotalFooter = false,
    hideAddButton = false,
    totalText = 'Total',
    hideHeader = false,
    firstColumnWidth,
    onScrollableRef,
    onScroll,
}) => {
    const [activeInput, setActiveInput] = useState<string | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [rowToDelete, setRowToDelete] = useState<string | null>(null);
    const [doNotShowDeleteConfirmation, setDoNotShowDeleteConfirmation] = useState(
        () => localStorage.getItem('proFormaTableDeleteConfirmation') === 'true',
    );
    const [openTooltips, setOpenTooltips] = useState<Record<string, boolean>>({});
    const toggleTooltip = (tooltipId: string) => {
        setOpenTooltips(prev => ({ ...prev, [tooltipId]: !prev[tooltipId] }));
    };

    const totals = useMemo(() => rows.reduce(
        (acc, row) => {
            const newTotals = { ...acc };
            columns.forEach(column => {
                if (column.key !== 'id') {
                    const value = parseFloat(row[column.key] as string) || 0;
                    newTotals[column.key] = (newTotals[column.key] || 0) + value;
                }
            });
            return newTotals;
        },
            {} as Record<string, number>,
    ), [rows, columns]);

    const handleSetId = (id: string | undefined) => {
        setActiveInput(id);
    };

    const defaultRow: ProFormaGenericRow = {
        id: `row-${Date.now()}`,
        ...customDefaultRow,
    };

    const handleAddRow = () => {
        setRows([...rows, { ...defaultRow, id: `row-${Date.now()}` }]);
    };

    const handleCellChange = (rowId: string, key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setRows(rows.map(row => (row.id === rowId ? { ...row, [key]: e.target.value } : row)));
    };

    const handleDeleteRow = (rowId: string) => {
        setRows(rows.filter(row => row.id !== rowId));
    };

    const handleDeleteClick = (rowId: string) => {
        if (localStorage.getItem('proFormaTableDeleteConfirmation') === 'true') {
            handleDeleteRow(rowId);
        } else {
            setRowToDelete(rowId);
            setShowDeleteModal(true);
        }
    };

    const handleConfirmDelete = () => {
        if (rowToDelete) {
            handleDeleteRow(rowToDelete);
            setShowDeleteModal(false);
            setRowToDelete(null);
        }
    };

    const handleSetDoNotShowDeleteConfirmation = (value: boolean) => {
        setDoNotShowDeleteConfirmation(value);
        localStorage.setItem('proFormaTableDeleteConfirmation', value.toString());
    };

    const fixedColumns = columns.filter(col => col.isFixed !== false);
    const scrollableColumns = columns.filter(col => col.isFixed === false);

    const isFirstColumn = (column: ProFormaGenericColumn) => column.key === columns[0].key;

    const isCellReadOnly = (row: ProFormaGenericRow, column: ProFormaGenericColumn) => {
        if (row.isReadOnly) return true;

        if (typeof column.isReadOnly === 'function') {
            return column.isReadOnly(row);
        }
        return column.isReadOnly;
    };

    const renderHeaderCell = (column: ProFormaGenericColumn) => {
        if (column.headerDropdown) {
            return (
                <div className="d-flex align-items-center">
                    <NSSelect
                        name={`header-${column.key}-select`}
                        options={column.headerDropdown.options}
                        value={column.headerDropdown.value}
                        onChange={column.headerDropdown.onChange}
                        placeholder={column.headerDropdown.placeholder}
                        isClearable={false}
                        menuPosition="fixed"
                        small
                    />
                    {column.headerDropdown.label && <div className="ml-1">{column.headerDropdown.label}</div>}
                </div>
            );
        }
        return column.header;
    };

    const getHeaderBorderClass = (isFirst: boolean, hideAddButton: boolean) => {
        if (!isFirst) return 'text-right';
        return hideAddButton ? '' : 'NSTable__thead__tr__th--right-border';
    };

    const getBodyTableBorderClass = (isFirst: boolean, hideAddButton: boolean) => {
        if (!isFirst) return 'text-right';
        return hideAddButton ? 'NSTable__tbody__tr' : 'NSTable__tbody__tr__td--right-border';
    };

    return (
        <div className="ProFormaGenericTable">
            <div className="d-flex" style={{ '--first-column-width': firstColumnWidth } as React.CSSProperties}>
                {fixedColumns.length > 0 && (
                    <div className="ProFormaGenericTable__fixed-section">
                        <NSTable className="ProFormaGenericTable__table">
                            {!hideHeader && (
                                <thead className="NSTable__thead">
                                    <tr className="NSTable__thead__tr">
                                        {fixedColumns.map(column => (
                                            <th
                                                key={column.key}
                                                className={`NSTable__thead__tr__th 
                                                ${getHeaderBorderClass(isFirstColumn(column), hideAddButton)}
                                                ${isFirstColumn(column) && column.dropdown ? 'has-dropdown' : ''}`}
                                            >
                                                <div
                                                    className={`d-flex align-items-center 
                                                        ${isFirstColumn(column) ? 'justify-content-between' : 'justify-content-end'}`}
                                                >
                                                    {renderHeaderCell(column)}
                                                    {isFirstColumn(column) && !hideAddButton && (
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                            className="ml-2 text-muted cursor--pointer"
                                                            onClick={handleAddRow}
                                                            title={addButtonLabel}
                                                        />
                                                    )}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            )}
                            {rows.length === 0 && !hideAddButton ? (
                                <tbody className="NSTable__tbody">
                                    <tr className="NSTable__tbody__tr">
                                        <td colSpan={fixedColumns.length} className="text-center text-muted py-4">
                                            <div className="text-white">Your added sources will appear here.</div>
                                            <div className="small">
                                                Click the
                                                {' '}
                                                <span className="font-weight-bold text-white">[+]</span>
                                                {' '}
                                                symbol in the
                                                {' '}
                                                <span className="font-weight-bold text-white">{columns[0].header}</span>
                                                {' '}
                                                header to add your first source.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className="NSTable__tbody">
                                    {rows.map(row => (
                                        <tr key={row.id} className="NSTable__tbody__tr">
                                            {fixedColumns.map(column => (
                                                <td
                                                    key={column.key}
                                                    className={`NSTable__tbody__tr__td
                                                        ${getBodyTableBorderClass(isFirstColumn(column), hideAddButton)}
                                                        ${activeInput === `${row.id}-${column.key}` ? 'pr-0' : ''}`}
                                                >
                                                    <RenderIf isTrue={!column.isHidden}>
                                                        {isCellReadOnly(row, column) ? (
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div
                                                                    className={`${!isFirstColumn(column) ? 'text-right w-100' : ''} 
                                                                    ${row.isNegative && !isFirstColumn(column) ? 'text-muted' : 'text-white'}`}
                                                                >
                                                                    {row.label && column.showLabel && <span className="text-muted mr-2">{row.label}</span>}
                                                                    {formatValue(
                                                                        row[column.key] as string,
                                                                        row.isNegative,
                                                                        true,
                                                                        column.format,
                                                                        column.isHidden,
                                                                    )}
                                                                    {column.showTooltip && row.tooltipMessage && (
                                                                        <>
                                                                            <FontAwesomeIcon
                                                                                className="px-1 text-white"
                                                                                id={`${row.id}-${column.key}-tooltip`}
                                                                                icon={faCircleInfo}
                                                                            />
                                                                            <Tooltip
                                                                                target={`${row.id}-${column.key}-tooltip`}
                                                                                isOpen={openTooltips[`${row.id}-${column.key}-tooltip`] || false}
                                                                                toggle={() => toggleTooltip(`${row.id}-${column.key}-tooltip`)}
                                                                                placement="top"
                                                                            >
                                                                                <span className="font-weight-bold">{row[column.key] as string}</span>
                                                                                <div>{row.tooltipMessage}</div>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {column.dropdown && column.dropdown.shouldShowDropdown?.(row) && (
                                                                    <div className="d-flex align-items-center">
                                                                        <NSSelect
                                                                            name={`${row.id}-${column.key}-select`}
                                                                            options={column.dropdown.options}
                                                                            value={column.dropdown.getSelectedOption(row)}
                                                                            onChange={column.dropdown.handleSelectOption(row.id)}
                                                                            placeholder={column.dropdown.label}
                                                                            isClearable={false}
                                                                            menuPosition="fixed"
                                                                        />
                                                                    </div>
                                                                )}
                                                                {column.customCell && column.customCell.shouldShowCustomCell?.(row) && (
                                                                    <div>
                                                                        {column.customCell.renderCell({
                                                                            row,
                                                                            column,
                                                                            value: row[column.key],
                                                                            onChange: handleCellChange(row.id, column.key),
                                                                            activeInput,
                                                                            handleSetId,
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className={`d-flex ${!isFirstColumn(column) ? 'justify-content-end' : ''}`}>
                                                                {row.label && column.showLabel && <span className="text-muted mr-2">{row.label}</span>}
                                                                <NSCellInput
                                                                    isRightAligned={!isFirstColumn(column)}
                                                                    cellId={`${row.id}-${column.key}`}
                                                                    setCellId={handleSetId}
                                                                    value={row[column.key]}
                                                                    onChange={handleCellChange(row.id, column.key)}
                                                                    onBlur={() => {}}
                                                                    placeholder="Enter value"
                                                                    customClassName={!isFirstColumn(column) ? 'text-right' : ''}
                                                                />
                                                                {isFirstColumn(column) && !row.isReadOnly && (
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="delete-icon"
                                                                        onClick={() => handleDeleteClick(row.id)}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </RenderIf>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {!hideTotalFooter && (
                                <tfoot className="NSTable__tfoot">
                                    <tr className="NSTable__tfoot__tr">
                                        {fixedColumns.map(column => (
                                            <td
                                                key={column.key}
                                                className={`NSTable__tfoot__tr__td 
                                                    ${isFirstColumn(column) ? 'NSTable__tfoot__tr__td--right-border text-left' : 'text-right'}`}
                                            >
                                                <div className="ProFormaGenericTable__total-cell">
                                                    {isFirstColumn(column)
                                                        ? totalText
                                                        : formatValue((totals[column.key] || 0).toString(), false, true, column.format, column.isHidden)}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tfoot>
                            )}
                        </NSTable>
                    </div>
                )}

                {/* Scrollable Columns */}
                {scrollableColumns.length > 0 && (
                    <div className="ProFormaGenericTable__scrollable-section" ref={onScrollableRef} onScroll={onScroll}>
                        <NSTable className="ProFormaGenericTable__table">
                            {!hideHeader && (
                                <thead className="NSTable__thead">
                                    <tr className="NSTable__thead__tr">
                                        {scrollableColumns.map(column => (
                                            <th key={column.key} className="NSTable__thead__tr__th text-right">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <span>{column.header}</span>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            )}
                            {rows.length === 0 && !hideAddButton ? (
                                <tbody className="NSTable__tbody">
                                    <tr className="NSTable__tbody__tr">
                                        <td colSpan={scrollableColumns.length} className="text-center text-muted py-4">
                                            <div>&nbsp;</div>
                                            <div className="small">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className="NSTable__tbody">
                                    {rows.map(row => (
                                        <tr key={row.id} className="NSTable__tbody__tr">
                                            {scrollableColumns.map(column => (
                                                <td
                                                    key={column.key}
                                                    className={`NSTable__tbody__tr__td text-right 
                                                        ${activeInput === `${row.id}-${column.key}` ? 'pr-0' : ''}`}
                                                >
                                                    <div className={`${row.isNegative ? 'text-muted' : 'text-white'}`}>
                                                        {formatValue(row[column.key] as string, row.isNegative, true, column.format, column.isHidden)}
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {!hideTotalFooter && (
                                <tfoot className="NSTable__tfoot">
                                    <tr className="NSTable__tfoot__tr">
                                        {scrollableColumns.map(column => (
                                            <td key={column.key} className="NSTable__tfoot__tr__td text-right">
                                                <div>{formatValue((totals[column.key] || 0).toString(), false, true, column.format, column.isHidden)}</div>
                                            </td>
                                        ))}
                                    </tr>
                                </tfoot>
                            )}
                        </NSTable>
                    </div>
                )}
            </div>
            <DeleteRowConfirmationModal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(false)}
                handleDelete={handleConfirmDelete}
                doNotShowAgain={doNotShowDeleteConfirmation}
                setDoNotShowAgain={handleSetDoNotShowDeleteConfirmation}
            />
        </div>
    );
};

export default ProFormaGenericTable;
