import {
    NSInput, NSLabel, NSModal, NSSelect,
} from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import useGetSourceTypes from 'queries/ProFormaSource/useGetSourceTypes';
import { BONDS_SOURCE_TYPE_UUID } from 'views/ProFormaTable/constants';
import React, { useState } from 'react';

export interface IAddSourceModalProps {
    toggle: () => void;
    isOpen: boolean;
    isLoading: boolean;
    handleClickAddSource: (sourceTypeId: string, sourceName: string) => void;
}
const AddSourceModal = ({
    toggle, isOpen, isLoading, handleClickAddSource,
}: IAddSourceModalProps) => {
    const [type, setType] = useState<IOptionAnyValue | null>();
    const [name, setName] = useState<string>('');

    const handleCreate = () => type && handleClickAddSource(type.value, name);

    const { data: sourceTypes } = useGetSourceTypes();
    const sourceTypesWithoutBonds = sourceTypes?.filter(sourceType => sourceType.correlationId !== BONDS_SOURCE_TYPE_UUID);
    const sourceTypeOptions = sourceTypesWithoutBonds?.map(sourceType => ({
        label: sourceType.name,
        value: sourceType.correlationId,
    }));

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText="Add source"
            modalHeaderClassName="text-dark bg-primary"
            modalFooterButtonText="Confirm"
            modalFooterButtonFunction={handleCreate}
            modalFooterButtonIsLoading={isLoading}
            modalFooterIsButtonFunctionDisabled={isLoading || !type || !name}
        >
            <NSLabel required>Source type</NSLabel>
            <NSSelect name="type" options={sourceTypeOptions} value={type} onChange={option => setType(option)} isClearable={false} />

            <NSLabel required className="mt-2 text-dark font-weight-normal">
                Name
            </NSLabel>
            <NSInput
                type="text"
                name="name"
                placeholder="Enter name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
        </NSModal>
    );
};

export default AddSourceModal;
