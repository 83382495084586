import React, { useMemo } from 'react';
import {
    generatePath, Link, useLocation, useParams,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons';
import { URLS } from 'services/urls';
import { useSlideout } from 'contexts/BackOfEnvelopeContext';
import classNames from 'classnames';
import NSLogo from '../../images/Northspyre-Logo.png';
import UserMenu from './components/UserMenu/UserMenu';
import './NSNavbar.scss';
import CannyChangelogWidget from './CannyChangelogWidget/CannyChangelogWidget';
import { useOrganizationAccess } from '../../hooks/useOrganizationAccess';

export const DisplayedLogo: { [key: string]: string } = {
    // TODO Add other environment logos
    production: NSLogo,
};

const MainNav = ({ pathname }: { pathname: string }) => {
    const { hasCoreAccess } = useOrganizationAccess();
    const projectUrl = URLS.PROJECTS_APP.PROJECTS;

    return (
        <div className="NSNavbar__link-list d-flex">
            <Link to="/deals" className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${pathname === '/deals' ? 'active' : ''}`}>
                Deals
            </Link>
            {hasCoreAccess && (
                <a href={projectUrl} className="NSNavbar__link-list__link d-flex align-items-center px-2">
                    Projects
                </a>
            )}
            <Link to="/" className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${pathname === '/tiff' ? 'active' : ''}`}>
                Analytics
            </Link>
            <Link to="/" className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${pathname === '/utilities' ? 'active' : ''}`}>
                Utilities
            </Link>
        </div>
    );
};

const DealDashboardNav = ({ pathname, dealId }: { pathname: string; dealId: string }) => (
    <div className="NSNavbar__link-list d-flex">
        <Link
            to={generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId }),
            })}
        >
            Deal Dashboard
        </Link>
        <Link
            to={generatePath(URLS.PRO_FORMA.HOME, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.PRO_FORMA.HOME, { dealId }),
            })}
        >
            Pro Forma
        </Link>
        <Link
            to={URLS.REPORTS.HOME}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === URLS.REPORTS.HOME,
            })}
        >
            Documents
        </Link>
        <Link
            to={generatePath(URLS.REPORT_BUILDER, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.REPORT_BUILDER, { dealId }),
            })}
        >
            Report Builder
        </Link>
    </div>
);

const NSNavbar = () => {
    const location = useLocation();
    const { dealId } = useParams<{ dealId: string }>();
    const { toggleSlideout } = useSlideout();
    const { pathname } = location;
    const isDealPage = pathname.startsWith('/deal/') && Boolean(dealId);

    const navigation = useMemo(() => {
        if (isDealPage) {
            return <DealDashboardNav pathname={pathname} dealId={dealId!} />;
        }
        return <MainNav pathname={pathname} />;
    }, [pathname, dealId, isDealPage]);

    const logoLinkDestination = isDealPage ? generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId }) : URLS.DEALS.HOME;

    return (
        <nav className="NSNavbar d-flex justify-content-between px-3 ">
            <div className="d-flex align-items-center">
                <div className="mr-2">
                    <Link to={logoLinkDestination} data-testid="home_url">
                        <img src={DisplayedLogo.production} style={{ height: '40px' }} alt="Logo" id="nav-bar-logo" />
                    </Link>
                </div>

                {navigation}
            </div>
            <div className="NSNavbar__right d-flex align-items-center">
                <div className="NSNavbar__right__icon-list d-flex">
                    <FontAwesomeIcon
                        icon={faCalculator}
                        className="NSNavbar__right__icon-list__icon cursor--pointer"
                        aria-label="calculator"
                        onClick={toggleSlideout}
                    />
                    <CannyChangelogWidget />
                </div>
                <UserMenu />
            </div>
        </nav>
    );
};

export default NSNavbar;
