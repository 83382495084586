import classNames from 'classnames';
import React from 'react';

export const GroupRow = ({ row, data }: { row: any; data: any[] }) => (
    <tr className="NSTable__tbody__tr" key={row.label}>
        <td>{row.label}</td>
        {data.map(_data => (
            <td
                className={`${classNames({
                    'bg-success-lighten': row.isHighlighted ? row.isHighlighted(_data) : false,
                })} ${_data.cellClasses ? _data.cellClasses[row.fieldName ?? ''] : ''}`}
                key={`${row.label}-${_data.id}`}
            >
                {row.formatter(_data)}
            </td>
        ))}
    </tr>
);

export default GroupRow;
