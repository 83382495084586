import React from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { formatBOEValues, FormatterType, getDisplayMode } from '../../helper';
import './SlideOutReturnCard.scss';

export interface IScenarioReturnsProps {
    title: string;
    returnValue: number;
    targetValue: number | null;
    formatter: FormatterType;
}

const SlideOutReturnCard = ({
    title, returnValue, targetValue, formatter,
}: IScenarioReturnsProps) => {
    const displayMode = getDisplayMode(returnValue, targetValue);
    return (
        <NSCard
            key={title}
            className={classNames('py-2 px-2 flex-grow-1 SlideOutReturnCard__card', {
                'SlideOutReturnCard__card--success': displayMode === 'success',
                'SlideOutReturnCard__card--error': displayMode === 'danger',
            })}
        >
            <div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <h5 className="text-white mt-0 mb-0">{title}</h5>
                    <h5 className="text-white font-weight-bold mt-0 mb-0">{formatBOEValues(returnValue !== null ? returnValue : 0, formatter)}</h5>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center pt-1">
                    <div>
                        <FontAwesomeIcon icon={faBullseyeArrow} className="pr-1" />
                        <span>Target:</span>
                    </div>
                    <span>{formatBOEValues(targetValue !== null ? targetValue : 0, formatter)}</span>
                </div>
            </div>
        </NSCard>
    );
};

export default SlideOutReturnCard;
