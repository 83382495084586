import React, { useCallback, useState } from 'react';
import {
    NSButton, NSCard, NSDropdown, NSInputNumberStepper,
} from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { IOptionAnyValue } from 'bricks/types';
import classNames from 'classnames';
import ProFormaExitValuationCard from '../ProFormaExitValuationCard/ProFormaExitValuationCard';
import SensitivityTable from '../SensitivityTable/SensitivityTable';
import { SensitivityTableValues, SensitivityValue, TableState } from '../SensitivityTable/types';
import './Sensitivity.scss';
import DeleteSensitivityConfirmationModal from './DeleteSensitivityConfirmationModal/DeleteSensitivityConfirmationModal';

const SENSITIVITY_OPTIONS = [
    {
        value: 'gross_rent_vacancy',
        label: 'Gross rent vs Vacancy rate',
        verticalLabel: 'Gross rent',
        horizontalLabel: 'Vacancy rate',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'exit_cap_gross_rent',
        label: 'Exit cap rate vs Gross rent',
        verticalLabel: 'Exit cap rate',
        horizontalLabel: 'Gross rent',
        increments: { vertical: 0.1, horizontal: 1 },
    },
    {
        value: 'lease_up_gross_rent',
        label: 'Lease-up pace vs Gross rent',
        verticalLabel: 'Lease-up pace',
        horizontalLabel: 'Gross rent',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'opex_gross_rent',
        label: 'Operating expenses vs Gross rent',
        verticalLabel: 'Operating expenses',
        horizontalLabel: 'Gross rent',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'exit_cap_costs',
        label: 'Exit cap rate vs Hard + soft costs',
        verticalLabel: 'Hard + soft costs',
        horizontalLabel: 'Exit cap rate',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'interest_ltc',
        label: 'Interest rate vs [LTC]',
        verticalLabel: 'Interest rate',
        horizontalLabel: 'LTC',
        increments: { vertical: 0.1, horizontal: 1 },
    },
];

const mockData: SensitivityTableValues = {
    values: [
        // Row 1
        {
            exitValuation: 123456789, leveredIRR: 12.1, leveredEMx: 1.2, status: 'best',
        },
        {
            exitValuation: 123456789, leveredIRR: 12.3, leveredEMx: 1.3, status: 'best',
        },
        {
            exitValuation: 123456789, leveredIRR: 12.5, leveredEMx: 1.4, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 12.7, leveredEMx: 1.5, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 12.9, leveredEMx: 1.6, status: 'good',
        },

        // Row 2
        {
            exitValuation: 123456789, leveredIRR: 13.1, leveredEMx: 1.7, status: 'best',
        },
        {
            exitValuation: 123456789, leveredIRR: 13.3, leveredEMx: 1.8, status: 'best',
        },
        {
            exitValuation: 123456789, leveredIRR: 13.5, leveredEMx: 1.9, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 13.7, leveredEMx: 2.0, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 13.9, leveredEMx: 2.1, status: 'good',
        },

        // Row 3
        {
            exitValuation: 123456789, leveredIRR: 14.1, leveredEMx: 2.2, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 14.3, leveredEMx: 2.3, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 14.5, leveredEMx: 2.4, status: 'good',
        },
        {
            exitValuation: 244456789, leveredIRR: 16.0, leveredEMx: 3.0, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 14.9, leveredEMx: 2.6, status: 'good',
        },

        // Row 4
        {
            exitValuation: 123456789, leveredIRR: 15.9, leveredEMx: 3.1, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 15.1, leveredEMx: 2.7, status: 'good',
        },
        {
            exitValuation: 244456789, leveredIRR: 16.3, leveredEMx: 3.1, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 15.5, leveredEMx: 2.9, status: 'warning',
        },
        {
            exitValuation: 123456789, leveredIRR: 15.7, leveredEMx: 3.0, status: 'warning',
        },

        // Row 5
        {
            exitValuation: 123456789, leveredIRR: 16.9, leveredEMx: 3.6, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 16.1, leveredEMx: 3.2, status: 'good',
        },
        {
            exitValuation: 123456789, leveredIRR: 16.3, leveredEMx: 3.3, status: 'warning',
        },
        {
            exitValuation: 123456789, leveredIRR: 16.5, leveredEMx: 3.4, status: 'warning',
        },
        {
            exitValuation: 123456789, leveredIRR: 16.7, leveredEMx: 3.5, status: 'danger',
        },
    ],
    verticalLabel: 'Hard + soft costs',
    horizontalLabel: 'Exit cap rate',
};

const DEFAULT_VERTICAL_LABEL_VALUES = [120, 110, 100, 90, 80];
const DEFAULT_HORIZONTAL_LABEL_VALUES = [2, 3, 4, 5, 6];

const DEFAULT_VERTICAL_INCREMENT = 1;
const DEFAULT_HORIZONTAL_INCREMENT = 1;

const getLabels = (baseLabels: number[], offset: number, increment: number): number[] => {
    const baseMiddle = baseLabels[2];
    const baseSpacing = baseLabels[2] - baseLabels[1];
    const newSpacing = baseSpacing + (offset * increment);
    return baseLabels.map((_, i) => baseMiddle + ((i - 2) * newSpacing));
};

const Sensitivity = () => {
    const [tables, setTables] = useState<TableState[]>([
        {
            id: 'main',
            type: 'main',
            tableLabel: 'Exit cap rate vs Hard + soft costs',
            sensitivityData: null,
            verticalLabel: mockData.verticalLabel,
            horizontalLabel: mockData.horizontalLabel,
            offsets: { vertical: 0, horizontal: 0 },
            increments: { vertical: DEFAULT_VERTICAL_INCREMENT, horizontal: DEFAULT_HORIZONTAL_INCREMENT },
        },
    ]);

    const updateOffsets = (table: TableState, direction: 'vertical' | 'horizontal', change: number): TableState => ({
        ...table,
        offsets: {
            ...table.offsets,
            [direction]: table.offsets[direction] + change,
        },
    });

    const handleLabelChange = (tableId: string, direction: 'vertical' | 'horizontal', change: number) => {
        setTables(prevTables => prevTables.map(table => (table.id === tableId ? updateOffsets(table, direction, change) : table)));
    };

    const handleSelectSensitivity = (option: IOptionAnyValue) => {
        if (option.value !== 'default') {
            const selectedOption = SENSITIVITY_OPTIONS.find(opt => opt.value === option.value);
            setTables(prev => [
                ...prev,
                {
                    id: `table-${Date.now()}`,
                    tableLabel: option.label,
                    type: option.value as string,
                    sensitivityData: null,
                    verticalLabel: selectedOption?.verticalLabel || '',
                    horizontalLabel: selectedOption?.horizontalLabel || '',
                    offsets: { vertical: 0, horizontal: 0 },
                    increments: selectedOption?.increments || { vertical: 1, horizontal: 1 },
                },
            ]);
        }
    };

    const handleCellClick = (tableId: string) => (cellData: SensitivityValue | null) => {
        setTables(prev => prev.map(table => (table.id === tableId ? { ...table, sensitivityData: cellData } : table)));
    };

    const handleDeleteSensitivityConfirm = useCallback(
        (tableId: string) => () => {
            setTableToDelete(tableId);
            setIsDeleteModalOpen(true);
        },
        [],
    );

    const [irr, setIrr] = useState<number>(0);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [tableToDelete, setTableToDelete] = useState('');

    const renderSensitivityTable = (tableId: string) => {
        const table = tables.find(t => t.id === tableId);
        if (!table) return null;
        const cardVisible = Boolean(table.sensitivityData);

        // Use table-specific offsets and increments when computing labels.
        const verticalLabels = getLabels(DEFAULT_VERTICAL_LABEL_VALUES, table.offsets.vertical, table.increments.vertical);
        const horizontalLabels = getLabels(DEFAULT_HORIZONTAL_LABEL_VALUES, table.offsets.horizontal, table.increments.horizontal);

        // Calculate the base spacing.
        const verticalBaseSpacing = DEFAULT_VERTICAL_LABEL_VALUES[2] - DEFAULT_VERTICAL_LABEL_VALUES[1];
        const horizontalBaseSpacing = DEFAULT_HORIZONTAL_LABEL_VALUES[2] - DEFAULT_HORIZONTAL_LABEL_VALUES[1];

        const currentVerticalSpacing = verticalBaseSpacing + (table.offsets.vertical * table.increments.vertical);
        const currentHorizontalSpacing = horizontalBaseSpacing + (table.offsets.horizontal * table.increments.horizontal);

        // Disable the decrement button if the absolute spacing is equal to or less than the increment value.
        const disableVerticalDecrement = Math.abs(currentVerticalSpacing) <= table.increments.vertical;
        const disableHorizontalDecrement = Math.abs(currentHorizontalSpacing) <= table.increments.horizontal;

        return (
            <div>
                <div className={classNames('Sensitivity__table pr-2', { 'Sensitivity__table--shrinked': cardVisible })}>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <div className="Sensitivity__table--title text-white mb-0">{table.tableLabel}</div>
                            <div className="text-muted">Click on a cell to see the impact on returns.</div>
                        </div>
                        <RenderIf isTrue={!cardVisible}>
                            <DeleteSensitivityButton tableId={tableId} />
                        </RenderIf>
                    </div>
                    <div className="Sensitivity--separator w-100 pt-2 mb-2" />
                    <div>
                        <SensitivityTable
                            verticalLabel={table.verticalLabel || ''}
                            horizontalLabel={table.horizontalLabel || ''}
                            verticalDataLabels={verticalLabels.map((label, i) => ({
                                id: `vertical-${i}`,
                                label,
                            }))}
                            horizontalDataLabels={horizontalLabels.map((label, i) => ({
                                id: `horizontal-${i}`,
                                label,
                            }))}
                            data={mockData.values}
                            onVerticalIncrement={() => handleLabelChange(tableId, 'vertical', -1)}
                            onVerticalDecrement={() => handleLabelChange(tableId, 'vertical', 1)}
                            onHorizontalIncrement={() => handleLabelChange(tableId, 'horizontal', 1)}
                            onHorizontalDecrement={() => handleLabelChange(tableId, 'horizontal', -1)}
                            onCellClick={handleCellClick(tableId)}
                            disableVerticalDecrement={disableVerticalDecrement}
                            disableHorizontalDecrement={disableHorizontalDecrement}
                            showDecimal={table.increments.vertical === 0.1 || table.increments.horizontal === 0.1}
                        />
                    </div>
                </div>

                <div
                    className={classNames('Sensitivity__valuation-card-container m-2', {
                        'Sensitivity__valuation-card-container--hidden': !cardVisible,
                    })}
                >
                    <ProFormaExitValuationCard
                        exitValuation={table.sensitivityData?.exitValuation || 0}
                        leveredIRR={table.sensitivityData?.leveredIRR || 0}
                        leveredEMx={table.sensitivityData?.leveredEMx || 0}
                        status={table.sensitivityData?.status}
                        id={tableId}
                    />
                </div>
            </div>
        );
    };

    const DeleteSensitivityButton = ({ tableId }: { tableId: string }) => (
        <NSButton className="m-2" outline color="danger" callback={handleDeleteSensitivityConfirm(tableId)}>
            Remove sensitivity
        </NSButton>
    );

    const handleDeleteSensitivity = () => {
        // TODO: Replace local state removal with backend deletion once integration is complete.
        setTables(prev => prev.filter(table => table.id !== tableToDelete));
        setTableToDelete('');
        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <NSCard className="NSCard--level-3 d-flex flex-row justify-content-between align-items-center mb-2 p-2">
                <div>
                    <h4 className="text-white font-weight-bold mb-0 mt-0">Sensitivity analysis</h4>
                    <div>Set your Target Levered IRR to help generate analysis charts</div>
                </div>
                <div className="d-flex align-items-center">
                    <span className="text-white font-weight-bold mr-2">Target Levered IRR:</span>
                    <NSInputNumberStepper
                        percentage
                        name="IRR"
                        value={irr}
                        step={0.1}
                        onChange={(value: number) => setIrr(value)}
                        inputGroupClassName="Sensitivity--input-width"
                    />
                </div>
            </NSCard>
            <NSCard className="p-2">{renderSensitivityTable('main')}</NSCard>

            {tables
                .filter(table => table.id !== 'main')
                .map(table => (
                    <NSCard key={table.id} className="mb-2 p-2">
                        {renderSensitivityTable(table.id)}
                    </NSCard>
                ))}

            <NSCard>
                <div className="Sensitivity__add-sensitivity">
                    <div className="Sensitivity__add-sensitivity__content">
                        <div className="Sensitivity__add-sensitivity__title pb-1">Add sensitivity</div>
                        <div className="Sensitivity__add-sensitivity__dropdown">
                            <NSDropdown
                                options={SENSITIVITY_OPTIONS}
                                selectedOption={{ label: 'Select sensitivity', value: 'default' }}
                                handleSelectOption={handleSelectSensitivity}
                                isFullWidth
                            />
                        </div>
                    </div>
                </div>
            </NSCard>

            <DeleteSensitivityConfirmationModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                handleDelete={handleDeleteSensitivity}
            />
        </>
    );
};

export default Sensitivity;
