export interface SensitivityValue {
    id?: string;
    exitValuation: number;
    leveredIRR: number;
    leveredEMx: number;
    status?: 'best' | 'good' | 'danger' | 'warning';
}

export interface SensitivityLabel {
    id: string;
    label: number;
}

export interface SensitivityTableValues {
    horizontalLabel: string;
    verticalLabel: string;
    values: SensitivityValue[];
}

export interface TableState {
    id: string;
    type: string;
    tableLabel: string;
    sensitivityData: SensitivityValue | null;
    verticalLabel: string;
    horizontalLabel: string;
    offsets: { vertical: number; horizontal: number };
    increments: { vertical: number; horizontal: number };
}

export const DEFAULT_GRID_SIZE = 5;
