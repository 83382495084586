import { SummaryDetails, SummaryQuickStats } from './types';

export const EXECUTIVE_SUMMARY_DETAILS: SummaryDetails = {
    address: '4th Street Highrise',
    fullAddress: '157 Chambers St, New York, NY 10007',
    units: 300,
    netRentableArea: 255000,
    landArea: 4.5,
    buildings: 1,
    stories: 4,
    parkingSpaces: 355,
    parkingRatios: 1.18,
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    longitude: -98.5795,
    latitude: 39.8283,
    city: 'Montevideo',
    state: 'Montevideo',
    zipCode: '11600',
};

export const EXECUTIVE_SUMMARY_STATS: SummaryQuickStats = {
    growthRate: {
        rentalIncome: 3.0,
        expenses: 3.0,
    },
    effectiveRent: {
        perSF: 2.1,
        perUnit: 1788,
    },
    generalVacancyCreditLoss: 6.0,
    operatingExpenseRatio: 39.55,
    breakevenOccupancy: 81.12,
    dispositionNetProceeds: 73838359,
    loanPayoff: -30693015,
    equityProceedsFromSale: 43145344,
    cashOnCashReturn: 1.54,
};
