import React, {
    createContext, useContext, useEffect, useState, useRef,
} from 'react';
import { io } from 'socket.io-client';
import {
    IProFormaSocketRoomData,
    IProFormaGeneralInfo,
    IUpdateProFormaKeyValues,
    ICreateBudgetClassificationPostBody,
    ICreateProFormaSourceRequestData,
    IBudgetClassificationPatchData,
    IGrossBuildableAreaLotsData,
    IGrossBuildableAreaLot,
    IUsesSummary,
    ISourcesSummary,
} from 'views/ProFormaTable/types';
import { standardHandlerActionError } from 'ns_libs/utils';
import { useAuth0TokenContext } from '../../../../../contexts/Auth0TokenContext';
import { config } from '../../../../../config';

const joinRoom = (socket: any, roomId: string) => new Promise((resolve, reject) => {
    socket.emit('join_room', roomId, (response: any) => {
        if (response && response.error) {
            console.error('Error joining room:', response.error);
            reject(response.error);
        } else {
            console.log('Successfully joined room:', roomId);
            resolve(response);
        }
    });
});

const getProForma = (socket: any, roomId: string) => new Promise((resolve, reject) => {
    socket.emit('get_pro_forma', { room: roomId });

    socket.on('pro_forma:fetched', (data: IProFormaSocketRoomData) => {
        console.log('Pro forma data:', data);
        resolve(data);
    });
}) as Promise<IProFormaSocketRoomData>;

const defaultValues: IProFormaSocketContext = {
    proForma: {
        id: 0,
        proFormaStatusId: 0,
        proFormaModelId: 0,
        dealName: '',
        dealId: 0,
        boeScenarioId: 0,
        name: '',
        description: '',
        address: '',
        city: '',
        county: '',
        state: '',
        zipCode: '',
        startDate: '',
        saleDate: '',
        landArea: null,
        landAreaUnitTypeId: 6,
        floorAreaRatio: null,
        grossBuildableArea: null,
        buildings: 1,
        averageStories: 1,
        builtGrossSquareFootage: null,
        structuredParkingSpaces: null,
        surfaceParkingSpaces: null,
        isActive: false,
        createdAt: '',
        updatedAt: '',
        subprojectId: null,
    },
    handleUpdateProForma: () => {},
    handleCreateDefaultBudgetClassifications: () => {},
    isDefaultBudgetClassificationsLoading: false,
    handleCreateBudgetClassification: () => {},
    isBudgetClassificationLoading: false,
    handleUpdateBudgetClassifications: () => {},
    isBudgetClassificationUpdatingLoading: false,
    handleDeleteBudgetClassification: () => {},
    isBudgetClassificationDeletionLoading: false,
    handledDeleteSource: () => {},
    isSourceDeletionLoading: false,
    handleCreateProFormaSource: () => {},
    buildableLots: { totalGrossBuildableArea: 0, grossBuildableAreaLots: [] },
    uses: {} as IUsesSummary,
    sources: {} as ISourcesSummary,
    handleCreateBuildableLot: () => {},
    handleUpdateBuildableLot: () => {},
    handleDeleteBuildableLot: () => {},
};

const ProFormaSocketContext = createContext<IProFormaSocketContext>(defaultValues);

interface IProFormaSocketProviderProps {
    organizationId: string;
    dealId: number;
    proFormaId: number;
    children: React.ReactNode;
}

const ProFormaSocketProvider = ({
    organizationId, dealId, proFormaId, children,
}: IProFormaSocketProviderProps) => {
    const roomId = `${organizationId}/deal/${dealId}/pro_forma/${proFormaId}`;
    const socketRef = useRef<any>(null);

    const [proForma, setProForma] = useState<IProFormaGeneralInfo>();
    const [uses, setUses] = useState<IUsesSummary>({} as IUsesSummary);
    const [sources, setSources] = useState<ISourcesSummary>({} as ISourcesSummary);
    const [buildableLots, setBuildableLots] = useState<IGrossBuildableAreaLotsData>({ totalGrossBuildableArea: 0, grossBuildableAreaLots: [] });
    const [isDefaultBudgetClassificationsLoading, setIsDefaultBudgetClassificationsLoading] = useState(false);
    const [isBudgetClassificationLoading, setIsBudgetClassificationLoading] = useState(false);
    const [isSourceDeletionLoading, setIsSourceDeletionLoading] = useState(false);
    const [isBudgetClassificationUpdatingLoading, setIsBudgetClassificationUpdatingLoading] = useState(false);
    const [isBudgetClassificationDeletionLoading, setIsBudgetClassificationDeletionLoading] = useState(false);

    const { isAuthenticated, accessToken } = useAuth0TokenContext();

    const checkSocketConnection = () => {
        if (!socketRef.current) {
            console.error('WebSocket connection is not established');
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            const socket = io(config.webSocketBaseUrl, {
                transports: ['websocket'],
                path: '/api/socket.io',
                auth: {
                    token: accessToken,
                },
                rejectUnauthorized: config.rejectUnauthorizedWebsocket,
            });

            socket.on('connect', async () => {
                console.log('Socket ID:', socket.id);
                console.log('Joining room:', roomId);

                try {
                    console.log('Getting pro forma data: ', roomId);
                    await joinRoom(socket, roomId);
                    const data: IProFormaSocketRoomData = await getProForma(socket, roomId);
                    setProForma(data.generalInfo);
                    setUses(data.usesSummary);
                    setSources(data.sourcesSummary);
                    setBuildableLots(data.buildableLots);
                } catch (error) {
                    console.error('Error during connection workflow:', error);
                }
            });

            socket.on('pro_forma:updated', (data: IProFormaSocketRoomData) => {
                console.log('The pro forma was updated:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
                setBuildableLots(data.buildableLots);
                // TODO: data will be the entire updated pro forma as saved in redis, set all state variables
                // setOperatingInfo(data.operatingInfo);
                // setIncome(data.income);
                // setExpenses(data.expenses);
                // setOperatingCashFlow(data.operatingCashFlow);
                // setPermanentDebt(data.permanentDebt);
            });

            socket.on('pro_forma:pro_forma_source:created', (data: IProFormaSocketRoomData) => {
                console.log('Pro forma source created:', data);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
                // TODO: data will be the entire updated pro forma as saved in redis, set all state variables
                // setOperatingCashFlow(data.operatingCashFlow);
                // setPermanentDebt(data.permanentDebt);
            });

            socket.on('pro_forma:default_budget_classifications:created', (data: IProFormaSocketRoomData) => {
                console.log('Default budget classifications were created:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
            });

            socket.on('pro_forma:budget_classification:created', (data: IProFormaSocketRoomData) => {
                console.log('Budget classification was created:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
            });

            socket.on('pro_forma:budget_classification:updated', (data: IProFormaSocketRoomData) => {
                console.log('Budget classifications were updated:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
            });

            socket.on('pro_forma:budget_classification:deleted', (data: IProFormaSocketRoomData) => {
                console.log('Budget classification was deleted:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
            });

            socket.on('pro_forma:source:deleted', (data: IProFormaSocketRoomData) => {
                console.log('Source was deleted:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
            });

            socket.on('pro_forma:gross_buildable_lot:created', (data: IProFormaSocketRoomData) => {
                console.log('Groos buildable lot was created:', data);
                setProForma(data.generalInfo);
                setBuildableLots(data.buildableLots);
            });

            socket.on('pro_forma:gross_buildable_lot:updated', (data: IProFormaSocketRoomData) => {
                console.log('Groos buildable lot was updated:', data);
                setProForma(data.generalInfo);
                setBuildableLots(data.buildableLots);
            });

            socket.on('pro_forma:gross_buildable_lot:deleted', (data: IProFormaSocketRoomData) => {
                console.log('Groos buildable lot was deleted:', data);
                setProForma(data.generalInfo);
                setBuildableLots(data.buildableLots);
            });

            socket.on('connect_error', error => {
                console.error('WebSocket connection error:', error);
            });

            socketRef.current = socket;

            return () => {
                console.log('Cleaning up WebSocket connection');
                if (socket) {
                    socket.disconnect();
                    socketRef.current = null;
                }
            };
        }

        return () => {};
    }, [isAuthenticated, accessToken, config.webSocketBaseUrl, proFormaId]);

    const handleUpdateProForma = (keyValues: IUpdateProFormaKeyValues[], successCallback?: () => void) => {
        if (!checkSocketConnection()) return;

        const data = keyValues.reduce(
            (acc, { key, value }) => {
                acc[key] = value;
                return acc;
            },
            {} as Record<string, string | number | null>,
        );

        socketRef.current.emit('update_pro_forma', {
            room: roomId,
            pro_forma_id: proFormaId,
            data,
        });

        socketRef.current.once('pro_forma:updated', (data: IProFormaSocketRoomData) => {
            setProForma(data.generalInfo);
            setUses(data.usesSummary);
            setSources(data.sourcesSummary);

            if (successCallback) successCallback();
        });
    };

    const handleCreateProFormaSource = (data: ICreateProFormaSourceRequestData, callback?: (isSuccess?: boolean) => void) => {
        if (!checkSocketConnection()) return;

        socketRef.current.emit('create_pro_forma_source', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            data,
        });

        socketRef.current.once('pro_forma:pro_forma_source:created', (data: IProFormaSocketRoomData) => {
            setUses(data.usesSummary);
            setSources(data.sourcesSummary);

            if (callback) callback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            standardHandlerActionError(error, 'Error creating a source - please try again.');

            if (callback) callback(false);
        });
    };

    const handleCreateDefaultBudgetClassifications = (startUsesFromScratch: () => void) => {
        if (!checkSocketConnection()) return;

        setIsDefaultBudgetClassificationsLoading(true);
        socketRef.current.emit('create_default_budget_classifications', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
        });

        socketRef.current.once('pro_forma:default_budget_classifications:created', (data: IProFormaSocketRoomData) => {
            console.log('Default budget classifications were created:', data);
            setIsDefaultBudgetClassificationsLoading(false);
            startUsesFromScratch();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsDefaultBudgetClassificationsLoading(false);
            standardHandlerActionError(error, 'Error creating default budget classifications - please try again.');
        });
    };

    const handleCreateBudgetClassification = (postBody: ICreateBudgetClassificationPostBody, expandCategory: () => void) => {
        if (!checkSocketConnection()) return;

        setIsBudgetClassificationLoading(true);
        socketRef.current.emit('create_budget_classification', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            new_budget_classification: postBody,
        });

        socketRef.current.once('pro_forma:budget_classification:created', (data: IProFormaSocketRoomData) => {
            console.log('Budget classification was created:', data);
            setIsBudgetClassificationLoading(false);
            expandCategory();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsBudgetClassificationLoading(false);
            standardHandlerActionError(error, 'Error creating budget classification - please try again.');
        });
    };

    const handleUpdateBudgetClassifications = (patchBody: IBudgetClassificationPatchData[], successCallback?: () => void) => {
        if (!checkSocketConnection()) return;

        setIsBudgetClassificationUpdatingLoading(true);
        socketRef.current.emit('update_budget_classifications', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            update_budget_classifications: {
                budget_classifications: patchBody,
            },
        });

        socketRef.current.once('pro_forma:budget_classification:updated', (data: IProFormaSocketRoomData) => {
            console.log('Budget classifications were updated:', data);
            setIsBudgetClassificationUpdatingLoading(false);
            if (successCallback) successCallback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsBudgetClassificationUpdatingLoading(false);
            standardHandlerActionError(error, 'Error updating budget classifications - please try again.');
        });
    };

    const handleDeleteBudgetClassification = (budgetClassificationIds: number[], deleteAll: boolean = false, successCallback: () => void) => {
        if (!checkSocketConnection()) return;

        setIsBudgetClassificationDeletionLoading(true);
        socketRef.current.emit('delete_budget_classification', {
            room: roomId,
            pro_forma_id: proFormaId,
            budget_classification_ids: budgetClassificationIds,
            delete_all: deleteAll,
        });

        socketRef.current.once('pro_forma:budget_classification:deleted', (data: IProFormaSocketRoomData) => {
            console.log('Budget classification was deleted:', data);
            setIsBudgetClassificationDeletionLoading(false);
            if (successCallback) successCallback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsBudgetClassificationDeletionLoading(false);
            standardHandlerActionError(error, 'Error deleting budget classification - please try again.');
        });
    };

    const handledDeleteSource = (proFormaSourceId: number) => {
        if (!checkSocketConnection()) return;

        setIsSourceDeletionLoading(true);
        socketRef.current.emit('delete_source', {
            room: roomId,
            pro_forma_source_id: proFormaSourceId,
        });

        socketRef.current.once('pro_forma:source:deleted', (data: IProFormaSocketRoomData) => {
            console.log('Budget classification was created:', data);
            setIsSourceDeletionLoading(false);
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsSourceDeletionLoading(false);
            standardHandlerActionError(error, 'Error deleting a source - please try again.');
        });
    };

    const handleCreateBuildableLot = () => {
        console.log('Creating gross buildable lot');
        if (!checkSocketConnection()) return;

        socketRef.current.emit('create_gross_buildable_lot', {
            room: roomId,
            pro_forma_id: proFormaId,
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            standardHandlerActionError(error, 'Error creating gross buildable lot - please try again.');
        });
    };

    const handleUpdateBuildableLot = (lotId: number, dataKey: keyof IGrossBuildableAreaLot, value: string | number) => {
        console.log('Updating gross buildable lot');
        if (!checkSocketConnection()) return;

        socketRef.current.emit('update_gross_buildable_lot', {
            room: roomId,
            pro_forma_id: proFormaId,
            lot_id: lotId,
            data: {
                [dataKey]: value,
            },
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            standardHandlerActionError(error, 'Error updating gross buildable lot - please try again.');
        });
    };

    const handleDeleteBuildableLot = (lotId: number) => {
        console.log('Deleting gross buildable lot');
        if (!checkSocketConnection()) return;

        socketRef.current.emit('delete_gross_buildable_lot', {
            room: roomId,
            pro_forma_id: proFormaId,
            lot_id: lotId,
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            standardHandlerActionError(error, 'Error deleting gross buildable lot - please try again.');
        });
    };

    return (
        <ProFormaSocketContext.Provider
            value={{
                proForma: proForma || ({} as IProFormaGeneralInfo),
                handleUpdateProForma,
                handleCreateDefaultBudgetClassifications,
                isDefaultBudgetClassificationsLoading,
                handleCreateBudgetClassification,
                isBudgetClassificationLoading,
                handleUpdateBudgetClassifications,
                isBudgetClassificationUpdatingLoading,
                handleDeleteBudgetClassification,
                isBudgetClassificationDeletionLoading,
                handledDeleteSource,
                isSourceDeletionLoading,
                handleCreateProFormaSource,
                handleCreateBuildableLot,
                handleUpdateBuildableLot,
                handleDeleteBuildableLot,
                buildableLots,
                uses,
                sources,
            }}
        >
            {children}
        </ProFormaSocketContext.Provider>
    );
};

const useProFormaSocketContext = () => useContext(ProFormaSocketContext);

export {
    ProFormaSocketContext, ProFormaSocketProvider, useProFormaSocketContext, defaultValues,
};

export interface IProFormaSocketContext {
    proForma: IProFormaGeneralInfo;
    handleUpdateProForma: (keyValues: IUpdateProFormaKeyValues[], successCallback?: () => void) => void;
    handledDeleteSource: (proFormaSourceId: number) => void;
    isSourceDeletionLoading: boolean;
    handleCreateDefaultBudgetClassifications: (startUsesFromScratch: () => void) => void;
    isDefaultBudgetClassificationsLoading: boolean;
    handleCreateBudgetClassification: (postBody: ICreateBudgetClassificationPostBody, expandCategory: () => void) => void;
    isBudgetClassificationLoading: boolean;
    handleUpdateBudgetClassifications: (patchBody: IBudgetClassificationPatchData[], successCallback?: () => void) => void;
    isBudgetClassificationUpdatingLoading: boolean;
    handleDeleteBudgetClassification: (budgetClassificationIds: number[], deleteAll: boolean, successCallback: () => void) => void;
    isBudgetClassificationDeletionLoading: boolean;
    handleCreateProFormaSource: (data: ICreateProFormaSourceRequestData, callback?: (isSuccess?: boolean) => void) => void;
    uses: IUsesSummary;
    sources: ISourcesSummary;
    handleCreateBuildableLot?: () => void;
    handleUpdateBuildableLot?: (lotId: number, dataKey: keyof IGrossBuildableAreaLot, value: string | number) => void;
    handleDeleteBuildableLot?: (lotId: number) => void;
    buildableLots: IGrossBuildableAreaLotsData;
}
