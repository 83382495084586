import { NSButton, NSCard } from 'bricks';
import React, { useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOptionAnyValue } from 'bricks/types';
import { useParams } from 'react-router-dom';
import EmptyState from './EmptyState';
import { IReportBuilderReport, ReportTypes } from '../constants';
import SaveTemplateModal from './SaveTemplateModal/SaveTemplateModal';
import BOEScenarioComparisonSaveCard from './BOEScenarioComparisonSaveCard/BOEScenarioComparisonSaveCard';
import BOEScenarioComparisonDetailCard from './BOEScenarioComparisonDetailCard/BOEScenarioComparisonDetailCard';
import GenerateReportModal from './GenerateReportModal/GenerateReportModal';
import DealOverviewSaveCard from './DealOverviewSaveCard/DealOverviewSaveCard';
import DealOverviewDetailCard from './DealOverviewDetailCard/DealOverviewDetailCard';
import DealBOESaveCard from './DealBOESaveCard/DealBOESaveCard';
import DealBOEDetailCard from './DealBOEDetailCard/DealBOEDetailCard';
import SavedReportDetailCard from './SavedReportDetailCard/SavedReportDetailCard';
import ProFormaExecutiveSummarySaveCard from './ProFormaExecutiveSummarySaveCard/ProFormaExecutiveSummarySaveCard';
import ProFormaExecutiveSummaryDetailCard from './ProFormaExecutiveSummaryDetailCard/ProFormaExecutiveSummaryDetailCard';

export interface INewCombinedReportSectionProps {
    selectedTemplate: IOptionAnyValue | undefined;
    newReports: Array<IReportBuilderReport>;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
    orgId: string;
}
const NewCombinedReportSection = ({
    selectedTemplate, newReports, setNewReports, orgId,
}: INewCombinedReportSectionProps) => {
    const { dealId: dealIdString } = useParams<{ dealId: string; proFormaId: string }>();
    const dealId = Number(dealIdString);

    const today = new Date();
    const todayDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState<boolean>(false);
    const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState<boolean>(false);

    const toggleSaveTemplateModal = () => {
        setIsSaveTemplateModalOpen(!isSaveTemplateModalOpen);
    };

    const toggleGenerateReportModal = () => {
        setIsGenerateReportModalOpen(!isGenerateReportModalOpen);
    };

    return (
        <NSCard className="NSCard--level-1 p-3 h-100" style={{ minHeight: '250px' }}>
            <div className=" d-flex justify-content-between">
                <div>
                    <h5 className="text-white m-0 p-0">New combined report</h5>
                    <p className="m-0 p-0">
                        {`Created ${todayDate}`}
                    </p>
                </div>
                <div>
                    <NSButton dataTestId="options-btn" color="secondary" className="mr-1" disabled>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </NSButton>
                    <NSButton
                        dataTestId="save-as-template-btn"
                        color="secondary"
                        className="mr-1"
                        callback={() => toggleSaveTemplateModal()}
                        disabled={newReports.length === 0}
                    >
                        Save as template
                    </NSButton>
                    <NSButton
                        dataTestId="generate-report-btn"
                        color="primary"
                        callback={() => toggleGenerateReportModal()}
                        disabled={newReports.length === 0}
                    >
                        Generate report
                    </NSButton>
                </div>
            </div>
            {newReports.map(report => {
                if (report.reportType === ReportTypes.BACK_OF_ENVELOPE_SCENARIO_COMPARISON) {
                    if (report.isEditing) {
                        return (
                            <BOEScenarioComparisonSaveCard
                                key={report.reportType}
                                report={report}
                                setNewReports={setNewReports}
                                orgId={orgId}
                                dealId={dealId}
                            />
                        );
                    }
                    return <BOEScenarioComparisonDetailCard key={report.reportType} report={report} setNewReports={setNewReports} />;
                }
                if (report.reportType === ReportTypes.DEAL_OVERVIEW) {
                    if (report.isEditing) {
                        return <DealOverviewSaveCard key={report.reportType} report={report} setNewReports={setNewReports} />;
                    }
                    return <DealOverviewDetailCard key={report.reportType} report={report} setNewReports={setNewReports} />;
                }
                if (report.reportType === ReportTypes.DEAL_BACK_OF_EVENLOPE) {
                    if (report.isEditing) {
                        return <DealBOESaveCard key={report.reportType} report={report} setNewReports={setNewReports} orgId={orgId} dealId={dealId} />;
                    }
                    return <DealBOEDetailCard key={report.reportType} report={report} setNewReports={setNewReports} />;
                }
                if (report.reportType === ReportTypes.PRO_FORMA_EXECUTIVE_SUMMARY) {
                    if (report.isEditing) {
                        return <ProFormaExecutiveSummarySaveCard key={report.reportType} report={report} setNewReports={setNewReports} dealId={dealId} />;
                    }
                    return <ProFormaExecutiveSummaryDetailCard key={report.reportType} report={report} setNewReports={setNewReports} />;
                }
                if (report.reportType === ReportTypes.SAVED_REPORT) {
                    return <SavedReportDetailCard key={report.reportType} report={report} setNewReports={setNewReports} />;
                }
                return <div key={report.reportType}>{report.reportType}</div>;
            })}
            {!selectedTemplate && newReports.length === 0 && <EmptyState />}
            {isSaveTemplateModalOpen && (
                <SaveTemplateModal orgId={orgId} newReports={newReports} isOpen={isSaveTemplateModalOpen} toggle={toggleSaveTemplateModal} />
            )}
            {isGenerateReportModalOpen && (
                <GenerateReportModal orgId={orgId} newReports={newReports} isOpen={isGenerateReportModalOpen} toggle={toggleGenerateReportModal} />
            )}
        </NSCard>
    );
};

export default NewCombinedReportSection;
