import React, { useState } from 'react';
import { NSDropdown } from 'bricks';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import useGetSourceTypes from 'queries/ProFormaSource/useGetSourceTypes';
import { DEBT_SOURCE_TYPES, PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS } from 'views/ProFormaTable/constants';
import { DevelopmentUnitOfMeasureTypes, IProFormaSourceTableRow } from 'views/ProFormaTable/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useProFormaSocketContext } from '../../../socketContext/ProFormaSocketProvider';

export interface ISourceProps {
    source: IProFormaSourceTableRow;
    totalSourcesAmount: number;
    unitType: DevelopmentUnitOfMeasureTypes;
}

const Source = ({ source, totalSourcesAmount, unitType }: ISourceProps) => {
    const { handledDeleteSource, isSourceDeletionLoading } = useProFormaSocketContext();
    const [isHovered, setIsHovered] = useState(false);

    const { data: sourceTypes } = useGetSourceTypes();
    const sourceType = sourceTypes?.find(sourceType => sourceType.correlationId === source.sourceTypeId);
    const isDebtSourceType = DEBT_SOURCE_TYPES.has(source.sourceTypeId);

    const traunchOptions: IOptionAnyValue[] = Array.from({ length: totalSourcesAmount }, (_, index) => ({
        label: `${index + 1}`,
        value: index + 1,
    }));

    const sourceUnitAmount = source[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] !== null ? source[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] : null;

    return (
        <tr className="NSTable__tbody__tr" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={4}>
                <div className="ml-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            {sourceType?.name}
                            <RenderIf isTrue={isDebtSourceType}>
                                <NSDropdown
                                    options={PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS}
                                    selectedOption={PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS[0]}
                                    handleSelectOption={() => {}}
                                    isBadgeToggle
                                    badgeColor="none"
                                    containerClassName="text-dark"
                                />
                            </RenderIf>
                        </div>
                        <RenderIf isTrue={isHovered}>
                            <RenderIf isTrue={isSourceDeletionLoading}>
                                <span className="spinner-border spinner-border-sm text-muted ml-1" style={{ height: '15px', width: '15px' }} />
                            </RenderIf>
                            <RenderIf isTrue={!isSourceDeletionLoading}>
                                <FontAwesomeIcon icon={faTrash} onClick={() => handledDeleteSource(source.id)} className="text-muted cursor--pointer" />
                            </RenderIf>
                        </RenderIf>
                    </div>
                    <NSCellInput
                        value={source.name}
                        onChange={() => {}}
                        onBlur={() => {}}
                        placeholder="Enter source name"
                        customClassName="mt-2"
                        onEnter={() => {}}
                    />
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={2}>
                <RenderIf isTrue={isDebtSourceType}>
                    <div className="d-flex justify-content-end text-primary">
                        {source.debtYield !== null ? percentFormatter(source.debtYield * 100) : '—'}
                    </div>
                </RenderIf>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={1}>
                <div className="d-flex justify-content-end">
                    <NSDropdown
                        options={traunchOptions}
                        selectedOption={traunchOptions?.find(traunch => traunch.value === source.traunch)}
                        handleSelectOption={() => {}}
                        isBadgeToggle
                        badgeColor="none"
                        containerClassName="text-primary"
                    />
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={1}>
                <div className="d-flex justify-content-end text-dark">{percentFormatter((source.sourcesPercentage || 0) * 100)}</div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={2}>
                <div className="d-flex justify-content-end text-dark">
                    {sourceUnitAmount !== null ? numeralFormatterCurrency(Number(sourceUnitAmount)) : '—'}
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={2}>
                <div className="d-flex justify-content-end text-primary">{!source.amount ? '—' : numeralFormatterCurrency(source.amount)}</div>
            </td>
        </tr>
    );
};

export default Source;
