/* eslint-disable no-unused-vars */

import { IAmountUnitTypesTotalsData, IUnitTypeOption, DevelopmentUnitOfMeasureTypes } from 'views/ProFormaTable/types';

export enum UNIT_OF_MEASURE_TYPES {
    GROSS_SF = 'gross_square_footage',
    FINISHED_SF = 'finished_square_footage',
    UNDERGROUND_SF = 'underground_square_footage',
    RENTABLE_SF = 'rentable_square_footage',
    RESIDENTIAL_UNIT = 'residential_unit',
    ACRE = 'acre',
    LOT = 'lot',
    MEGAWATT = 'megawatt',
    BED = 'bed',
}

export enum UnitTypeIds {
    GROSS_SF = 1,
    FINISHED_SF = 2,
    UNDERGROUND_SF = 3,
    RENTABLE_SF = 4,
    RESIDENTIAL_UNIT = 5,
    ACRE = 6,
    LOT = 7,
    MEGAWATT = 8,
    BED = 9,
}

export const UNIT_TYPE_NAMES: {
    [key in UNIT_OF_MEASURE_TYPES]: {
        id: UnitTypeIds;
        value: UNIT_OF_MEASURE_TYPES;
        abbreviated: string;
        shortened: string;
        fullNamePlural: string;
        dropdownItemName: string;
        pluralColumnHeader: string;
        camelCasedKey: string;
        heapTag: string;
    };
} = {
    [UNIT_OF_MEASURE_TYPES.GROSS_SF]: {
        id: UnitTypeIds.GROSS_SF,
        value: UNIT_OF_MEASURE_TYPES.GROSS_SF,
        abbreviated: 'G. SF',
        shortened: 'Built GSF',
        fullNamePlural: 'Built Gross Square Feet',
        dropdownItemName: 'Built Gross SF',
        pluralColumnHeader: 'G. SF',
        camelCasedKey: 'grossSquareFootage',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.RENTABLE_SF]: {
        id: UnitTypeIds.RENTABLE_SF,
        value: UNIT_OF_MEASURE_TYPES.RENTABLE_SF,
        abbreviated: 'R. SF',
        shortened: 'Rentable SF',
        fullNamePlural: 'Rentable Square Feet',
        dropdownItemName: 'Rentable SF',
        pluralColumnHeader: 'R. SF',
        camelCasedKey: 'rentableSquareFootage',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.FINISHED_SF]: {
        id: UnitTypeIds.FINISHED_SF,
        value: UNIT_OF_MEASURE_TYPES.FINISHED_SF,
        shortened: 'Finish SF',
        abbreviated: 'F. SF',
        fullNamePlural: 'Finished Square Feet',
        dropdownItemName: 'Finished SF',
        pluralColumnHeader: 'F. SF',
        camelCasedKey: 'finishedSquareFootage',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.UNDERGROUND_SF]: {
        id: UnitTypeIds.UNDERGROUND_SF,
        value: UNIT_OF_MEASURE_TYPES.UNDERGROUND_SF,
        shortened: 'Underground SF',
        abbreviated: 'U. SF',
        fullNamePlural: 'Underground Square Feet',
        dropdownItemName: 'Underground SF',
        pluralColumnHeader: 'U. SF',
        camelCasedKey: 'undergroundSquareFootage',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.RESIDENTIAL_UNIT]: {
        id: UnitTypeIds.RESIDENTIAL_UNIT,
        value: UNIT_OF_MEASURE_TYPES.RESIDENTIAL_UNIT,
        abbreviated: 'UNIT',
        shortened: 'Residential Unit',
        fullNamePlural: 'Residential Units',
        dropdownItemName: 'Residential Units',
        pluralColumnHeader: 'Units',
        camelCasedKey: 'residentialUnit',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.ACRE]: {
        id: UnitTypeIds.ACRE,
        value: UNIT_OF_MEASURE_TYPES.ACRE,
        abbreviated: 'ACRE',
        shortened: 'Acre',
        fullNamePlural: 'Acres',
        dropdownItemName: 'Acres',
        pluralColumnHeader: 'Acres',
        camelCasedKey: 'acre',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.LOT]: {
        id: UnitTypeIds.LOT,
        value: UNIT_OF_MEASURE_TYPES.LOT,
        abbreviated: 'LOT',
        shortened: 'Lot',
        fullNamePlural: 'Lots',
        dropdownItemName: 'Lots',
        pluralColumnHeader: 'Lots',
        camelCasedKey: 'lot',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.MEGAWATT]: {
        id: UnitTypeIds.MEGAWATT,
        value: UNIT_OF_MEASURE_TYPES.MEGAWATT,
        abbreviated: 'MW',
        shortened: 'Megawatt',
        fullNamePlural: 'Megawatts',
        dropdownItemName: 'Megawatts',
        pluralColumnHeader: 'Megawatts',
        camelCasedKey: 'megawatt',
        heapTag: '',
    },
    [UNIT_OF_MEASURE_TYPES.BED]: {
        id: UnitTypeIds.BED,
        value: UNIT_OF_MEASURE_TYPES.BED,
        shortened: 'Bed',
        abbreviated: 'BED',
        fullNamePlural: 'Beds',
        dropdownItemName: 'Beds',
        pluralColumnHeader: 'Beds',
        camelCasedKey: 'bed',
        heapTag: '',
    },
};

export const PRO_FORMA_APARTMENT_UNIT_TYPES = [
    UNIT_OF_MEASURE_TYPES.GROSS_SF,
    UNIT_OF_MEASURE_TYPES.RENTABLE_SF,
    UNIT_OF_MEASURE_TYPES.RESIDENTIAL_UNIT,
    UNIT_OF_MEASURE_TYPES.ACRE,
    UNIT_OF_MEASURE_TYPES.LOT,
    UNIT_OF_MEASURE_TYPES.BED,
];

export const PRO_FORMA_INDUSTRIAL_UNIT_TYPES = [
    UNIT_OF_MEASURE_TYPES.GROSS_SF,
    UNIT_OF_MEASURE_TYPES.RENTABLE_SF,
    UNIT_OF_MEASURE_TYPES.ACRE,
    UNIT_OF_MEASURE_TYPES.LOT,
    UNIT_OF_MEASURE_TYPES.MEGAWATT,
];

export const PRO_FORMA_RETAIL_AND_OFFICE_UNIT_TYPES = [
    UNIT_OF_MEASURE_TYPES.GROSS_SF,
    UNIT_OF_MEASURE_TYPES.RENTABLE_SF,
    UNIT_OF_MEASURE_TYPES.ACRE,
    UNIT_OF_MEASURE_TYPES.LOT,
];

export const PRO_FORMA_UNIT_TO_FIELD_VALUES: Record<DevelopmentUnitOfMeasureTypes, keyof IAmountUnitTypesTotalsData> = {
    [UNIT_OF_MEASURE_TYPES.GROSS_SF]: 'costPerGrossSquareFootage',
    [UNIT_OF_MEASURE_TYPES.RENTABLE_SF]: 'costPerRentableSquareFootage',
    [UNIT_OF_MEASURE_TYPES.LOT]: 'costPerLot',
    [UNIT_OF_MEASURE_TYPES.RESIDENTIAL_UNIT]: 'costPerResidentialUnit',
    [UNIT_OF_MEASURE_TYPES.ACRE]: 'costPerAcre',
    [UNIT_OF_MEASURE_TYPES.MEGAWATT]: 'costPerMegawatt',
    [UNIT_OF_MEASURE_TYPES.BED]: 'costPerBed',
};

export const PRO_FORMA_SIZE_UNIT_OPTIONS = [
    { value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.ACRE].id, label: 'acres' },
    { value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.GROSS_SF].id, label: 'ft²' },
];

export enum COST_BASIS {
    PER_SF = 'perSF',
    PER_UNIT = 'perUnit',
}

export const COST_BASIS_NAMES: {
    [key in COST_BASIS]: {
        value: COST_BASIS;
        abbreviated: string;
        shortened: string;
        fullNamePlural: string;
        dropdownItemName: string;
    };
} = {
    [COST_BASIS.PER_SF]: {
        value: COST_BASIS.PER_SF,
        abbreviated: 'SF',
        shortened: 'Per SF',
        fullNamePlural: 'Square Feet',
        dropdownItemName: 'Per SF',
    },
    [COST_BASIS.PER_UNIT]: {
        value: COST_BASIS.PER_UNIT,
        abbreviated: 'Unit',
        shortened: 'Per Unit',
        fullNamePlural: 'Units',
        dropdownItemName: 'Per Unit',
    },
};

export const SQUARE_FEET_PER_ACRE = 43560;
