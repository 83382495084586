export const getValuationColumnHeaderText = (key: string) => {
    switch (key) {
        case 'untrended':
            return { title: 'Untrended', subtitle: '(Today)' };
        case 'trended':
            return { title: 'Trended', subtitle: '(Period 33 - 44)' };
        case 'sale':
            return { title: 'Sale', subtitle: '(Period 45 - 56)' };
        default:
            return { title: '', subtitle: '' };
    }
};

export const getStabilizationColumnHeaderText = (key: string) => {
    switch (key) {
        case 'untrended':
            return { title: '$35,413,231', subtitlePrefix: '$ / SF: ', subtitle: '$307.90' };
        case 'trended':
            return { title: '$35,413,231', subtitlePrefix: '$ / SF: ', subtitle: '$307.90' };
        case 'sale':
            return { title: '$35,413,231', subtitlePrefix: '$ / SF: ', subtitle: '$307.90' };
        default:
            return { title: '', subtitlePrefix: '', subtitle: '' };
    }
};
